
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openPanels: [],
    }
  },
  computed: {
    guides() {
      return this.$store.getters[`auxiliary/pendo/getGuides`]
    },
  },
  methods: {
    startGuide(guide) {
      if (!guide) return

      // Deselect group if it is one of these guides
      if (['QO8gTHMlxK19iO2VfTGc4VhHAOo', 'cAEMYtAMxpi5FcNVPbnZ32ZQJqg'].includes(guide.id)) {
        this.$store.commit('modules/entityGroups/selectEntityGroup', null)
      }

      // Start guide
      this.$store.dispatch(`auxiliary/pendo/startGuide`, { guideId: guide.id, navigate: true })
      this.$emit('input', false)
    },
  },
  watch: {
    value(isOpen) {
      if (isOpen === true) {
        // Remove pendo query string on dialog being opened so that a guide can be repeated
        const query = Object.assign({}, $nuxt.$route.query)
        if (query.pendo) {
          delete query.pendo
          this.$router.replace({ query })
        }
      }
    },
  },
}
