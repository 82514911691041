let localContext

export const fees = {
  /**
   * Gets the dollars value or percentage of account balance for a holding object
   * Account balance is for if the holding itself is a percentage of account balance
   * @param {Object} holding
   * @param {Number} accountBalance
   * @returns Number value
   */
  getHoldingValue(holding, accountBalance = null) {
    switch (holding?.holdingValue?.type) {
      case 'MONEY':
        return holding.holdingValue.value
      case 'PERCENT':
        if (accountBalance) {
          return Number(accountBalance) * (Number(holding.holdingValue.value) / 100)
        }
      case 'UNITS':
        return Number(holding.holdingValue.value) * Number(holding.unitPrice)
    }
    return 0
  },

  /**
   * Gets the percentage a holding represents of all holdings
   * Account balance is for if the holding itself is a percentage of account balance
   * @param {Object} holding
   * @param {Array<Object>} allHoldings
   * @param {Number} accountBalance
   * @returns Percentage to 2 decimals
   */
  getHoldingPercent(holding, allHoldings, accountBalance = null) {
    const value = fees.getHoldingValue(holding, accountBalance)
    const totalHoldings = fees.sumHoldings(allHoldings, accountBalance)
    if (!totalHoldings || !value) return 0

    const percentage = (value / totalHoldings) * 100
    return percentage
  },

  /**
   * Sums an array of holding objects
   * Account balance is for if the holding itself is a percentage of account balance
   * @param {Array<Object>} holdings
   * @param {Number} accountBalance
   * @returns Value of summed holdings as a number
   */
  sumHoldings(holdings, accountBalance = null, chargeableAssets = null) {
    if (!holdings) return 0
    if (Array.isArray(chargeableAssets)) holdings = holdings.filter((el) => chargeableAssets.find((asset) => asset === el.type)) // Only sum chargeable assets
    return _.sumBy(holdings, (el) => Number(fees.getHoldingValue(el, accountBalance)))
  },

  /**
   * Clamp a number between the min and max value
   * @param {Number} value
   * @param {Number} min
   * @param {Number} max
   * @returns Number
   */
  clamp(value, min, max) {
    if (min && Number(value) < Number(min)) return min
    if (max && Number(value) > Number(max)) return max
    return value
  },
}

export default (context, inject) => {
  localContext = context
  inject('fees', fees)
}
