import { _ } from 'core-js'
import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('documentBuilder', null)

const ElanDocumentType = {
  TEMPLATE_ELAN_DOCUMENT: 'TEMPLATE_ELAN_DOCUMENT',
  ELAN_DOCUMENT: 'ELAN_DOCUMENT',
}

const DocumentSectionType = {
  TEMPLATE_DOCUMENT_SECTION: 'TEMPLATE_DOCUMENT_SECTION',
  DOCUMENT_SECTION: 'DOCUMENT_SECTION',
  FINALISED_DOCUMENT_SECTION: 'FINALISED_DOCUMENT_SECTION',
}

export const state = () => ({
  ...crud.state,
  publishProgress: null,
  sections: [],
  sectionTemplates: [],
  elanDocuments: [],
  elanDocumentTemplates: [],
  errorLog: [],
})

export const getters = {
  ...crud.getters,
}

export const mutations = {
  ...crud.mutations,
  addError(state, message) {
    state.errorLog.push({ message, time: this.$moment() })
  },
  updatePublishProgress(state, step) {
    state.publishProgress = step
  },
  updateSectionSnippets(state, { sectionId, snippetIds }) {
    if (!sectionId || !snippetIds) return
    let found = state.sectionTemplates.find((el) => el.id === sectionId)
    if (found) _.set(found, 'snippets', snippetIds)
  },
  setSections(state, sections) {
    state.sections = sections
  },
  setSectionTemplates(state, sections) {
    state.sectionTemplates = sections
  },
  setDocumentMappings(state, { elanDocument, mappings }) {
    if (!elanDocument || !mappings) return
    // Set elanDocument in state
    // const docs = _.cloneDeep(state.elanDocuments)
    // const index = _.findIndex(docs, ['id', elanDocument.id])
    // _.set(docs, `[${index}].mappings`, mappings)

    // state.elanDocuments = docs
    elanDocument.mappings = mappings
  },
  setDocumentSections(state, { elanDocument, sections }) {
    if (!elanDocument || !sections) return

    // const docs = _.cloneDeep(state.elanDocuments)
    // const index = _.findIndex(docs, ['id', elanDocument.id])

    // _.set(docs, `[${index}].sections`, sections)

    // state.elanDocuments = docs
    elanDocument.sections = sections
  },
  // Used with finaliseSection()
  updateDocumentSection(state, { elanDocument, section }) {
    elanDocument.sections = elanDocument.sections.map((sec) => {
      if (sec.id === section.id) return section
      else return sec
    })
  },
  setElanDocuments(state, documents) {
    state.elanDocuments = documents
  },
  setElanDocumentTemplates(state, documents) {
    state.elanDocumentTemplates = documents
  },
  removeElanDocument(state, document) {
    let index = null
    switch (document?.type) {
      case ElanDocumentType.ELAN_DOCUMENT:
        index = state.elanDocuments.findIndex((el) => el.id === document?.id)
        state.elanDocuments.splice(index, 1)
        break
      case ElanDocumentType.TEMPLATE_ELAN_DOCUMENT:
        index = state.elanDocumentTemplates.findIndex((el) => el.id === document?.id)
        state.elanDocumentTemplates.splice(index, 1)
        break
    }
  },
  addElanDocument(state, document) {
    switch (document?.type) {
      case ElanDocumentType.ELAN_DOCUMENT:
        state.elanDocuments.push(document)
        break
      case ElanDocumentType.TEMPLATE_ELAN_DOCUMENT:
        state.elanDocumentTemplates.push(document)
        break
    }
  },
  updateElanDocument(state, document) {
    let index = null
    switch (document?.type) {
      case ElanDocumentType.ELAN_DOCUMENT:
        index = state.elanDocuments.findIndex((el) => el.id === document?.id)
        state.elanDocuments.splice(index, 1, document)
        break
      case ElanDocumentType.TEMPLATE_ELAN_DOCUMENT:
        index = state.elanDocumentTemplates.findIndex((el) => el.id === document?.id)
        state.elanDocumentTemplates.splice(index, 1, document)
        break
    }
  },
  removeTemplateSection(state, sectionId) {
    let sectionIndex = state.sectionTemplates.findIndex((el) => el.id === sectionId)
    if (sectionIndex >= 0) state.sectionTemplates.splice(sectionIndex, 1)
  },
  addDocumentSections(state, sections) {
    state.sections = state.sections.concat(sections)
  },
  addSection(state, section) {
    switch (section.type) {
      case DocumentSectionType.TEMPLATE_DOCUMENT_SECTION:
        state.sectionTemplates.push(section)
        break
      case DocumentSectionType.DOCUMENT_SECTION:
        state.sections.push(section)
        break
    }
  },
  updateSection(state, section) {
    if (!section) return
    let foundIndex = -1
    switch (section.type) {
      case DocumentSectionType.TEMPLATE_DOCUMENT_SECTION:
        foundIndex = state.sectionTemplates.findIndex((el) => el.id === section.id)
        if (foundIndex >= 0) state.sectionTemplates.splice(foundIndex, 1, section)
        break
      case DocumentSectionType.DOCUMENT_SECTION:
      case DocumentSectionType.FINALISED_DOCUMENT_SECTION:
        foundIndex = state.sections.findIndex((el) => el.id === section.id)
        if (foundIndex >= 0) state.sections.splice(foundIndex, 1, section)
        break
    }
  },
  removeDocumentSection(state, sectionId) {
    let sectionIndex = state.sections.findIndex((el) => el.id === sectionId)
    if (sectionIndex >= 0) state.sections.splice(sectionIndex, 1)
  },
}

export const actions = {
  ...crud.actions,
  async renderDocument({ commit, state }, {file,mappings,section}) {
    let formData = new FormData()
    formData.append('file', file)

    const flattened = _.flattenDeep(_.map(mappings, (value, key) => {
      if (_.isObject(value)) {
        return _.map(value, (nestedValue, nestedKey) => `${key}.${nestedKey}`);
      }
      return key;
    }));

    const mappingObj = {
      "section": section,
      "fieldNames": [...flattened],
      "fieldValues": [..._.flattenDeep(_.values(mappings).map(v => _.values(v)))],
      }

    formData.append('mappings', new Blob([JSON.stringify(mappingObj, false)], { type: 'application/json' }))
    // Content-Type needs to be undefined because its sending both a file and json
    let data = (await this.$axios.post('/api/v1/documentSection/renderSection', formData, { headers: { 'Content-Type': undefined } })).data
    if(data){
      let sectionRef = _.cloneDeep(section)
      sectionRef.hasRendered = true
      commit('updateSection', sectionRef)
    }
    return 
  },
  async saveDocument({}, { section, payload }) {
    return await this.$axios.post(`/api/v1/documentSection/${section}/save`, payload, { headers: { 'Content-Type': 'application/octet-stream' } })
  },
  async convertHtml({}, payload) {
    return (await this.$axios.post('/api/v1/document/convertHtml', { 'data': payload })).data
  },
  async sfdtToHtml({}, payload) {
    return (await this.$axios.post('/api/v1/document/sfdtToHtml', { 'data': payload })).data
  },
  async sfdtToTxt({}, payload) {
    return (await this.$axios.post('/api/v1/document/sfdtToTxt', { 'data': payload })).data
  },
  async htmlToDocx({}, payload) {
    return (await this.$axios.post('/api/v1/document/htmlToDocx', { 'data': payload }, { responseType: 'blob' })).data
  },
  async saveSectionWithExtractedElements({ commit, state }, { section, elements }) {
    await this.$axios.post(`/api/v1/documentSection/${section}/elementUpdate`, { elements: elements.filter((el) => typeof el === 'string'), repeatingElements: elements.filter((el) => typeof el === 'object') }).then((res) => {
      if (res?.status === 200) commit('updateSection', res.data)
    })
  },
  /************************
   * Webstomp
   ***********************/
  eventUpdate({ commit, rootState }, stompMessage) {
    if (stompMessage !== null && stompMessage.eventName) {
      let eventName = stompMessage.eventName
      if (eventName.includes('Publish')) {
        // Only allow publish messages from yourself in the same tab
        if (_.get(this.$auth, '$state.user.sub') === stompMessage.instigator && stompMessage.transactionId !== null && stompMessage.transactionId === rootState.application.webStomp.transactionId) {
          let action = eventName.split('-')[1]
          let progress = null

          switch (action) {
            case 'preparingSections':
              progress = { step: 1, text: 'Preparing sections...' }
              break
            case 'renderSectionStart':
            case 'renderSectionComplete':
              const sectionName = eventName.split('-')[2]
              progress = { step: 2, text: `Rendering ${sectionName}...` }
              break
            case 'uploadMergeDoc':
              progress = { step: 3, text: 'Merging sections...' }
              break
            case 'cleanup':
              progress = { step: 4, text: 'Cleaning up...' }
              break
            case 'savingDoc':
              progress = { step: 5, text: 'Saving...' }
              break
            case 'complete':
              progress = { step: 6, text: 'Completed' }
              break
          }
          commit('updatePublishProgress', progress)
        }
      }
    }
  },
  /************************
   * Fetching
   ***********************/
  async fetchSnippets({ commit }, ids) {
    return await this.$axios.get(`/api/v1/snippet/getSnippets?snippetIds=${ids.join('&snippetIds=')}`).then((res) => {
      return res
    })
  },
  async fetchElanDocuments({ commit }, type) {
    await this.$axios.get(`/api/v1/elanDocument/type/${type}`).then((res) => {
      if (res.data) {
        switch (type) {
          case ElanDocumentType.ELAN_DOCUMENT:
            commit('setElanDocuments', res.data)
            break
          case ElanDocumentType.TEMPLATE_ELAN_DOCUMENT:
            commit('setElanDocumentTemplates', res.data)
            break
        }
      }
    })
  },
  async fetchDocumentSections({ commit }, type) {
    await this.$axios.get(`/api/v1/documentSection/type/${type}`).then((res) => {
      if (res.data) {
        switch (type) {
          case DocumentSectionType.DOCUMENT_SECTION:
            commit('setSections', res.data)
            break
          case DocumentSectionType.TEMPLATE_DOCUMENT_SECTION:
            commit('setSectionTemplates', res.data)
            break
        }
      }
    })
  },
  async fetchDocumentSectionsByIds({ commit }, ids) {
    await this.$axios.get(`/api/v1/documentSection/getMultipleByIds?ids=${ids.join('&ids=')}`).then((res) => {
      if (res.data) {
        commit('setSections', res.data)
      }
    })
  },
  async fetchDocumentSection({}, id) {
    return (await this.$axios.get(`/api/v1/documentSection/${id}`)).data
  },
  /************************
   * Elan Document Actions
   ***********************/
  async createNewElanDocument({ commit }, data) {
    return await this.$axios.post(`/api/v1/elanDocument`, data).then((res) => {
      if (res?.status === 200 && res?.data) {
        data.id = res.data // Update ID with new one given
        commit('addElanDocument', data)
      }
      return res
    })
  },
  async updateElanDocument({ commit }, data) {
    if (!data) return
    return await this.$axios.put(`/api/v1/elanDocument/${data.id}`, data).then((res) => {
      if (res?.status === 200) {
        commit('updateElanDocument', data)
      }
      return res
    })
  },
  async removeElanDocument({ commit, dispatch }, elanDocument) {
    // Remove the document
    return await this.$axios.post(`/api/v1/elanDocument/deletes`, [elanDocument.id]).then((res) => {
      if (res?.status === 200) {
        commit('removeElanDocument', elanDocument)
        if (Array.isArray(elanDocument.sections)) {
          // Remove sections from S3 that were in the document and aren't templates
          elanDocument.sections.forEach((section) => {
            if (section.type !== DocumentSectionType.TEMPLATE_DOCUMENT_SECTION) dispatch('removeS3Section', section)
          })
        }
      }
      return res
    })
  },
  async duplicateElanDocument({ state, dispatch }, elanDocument) {
    if (!elanDocument) return

    let clone = _.cloneDeep(elanDocument)
    const newId = this.$uuid()
    clone.id = newId
    clone.creationDate = new Date()
    clone.name = this.$utils.generateCopyName(
      clone.name,
      state.elanDocuments.map((el) => el.name)
    )

    let failedCopy = false
    if (_.get(elanDocument, 'sections', []).length > 0) {
      await dispatch('cloneSections', { sections: clone?.sections, type: DocumentSectionType.DOCUMENT_SECTION }).then((res) => {
        if (res) clone.sections = res
        else failedCopy = true
      })
    }

    if (!failedCopy) {
      clone.sections = clone.sections.map(({id}) => id)
      return await dispatch('createNewElanDocument', clone).then((res) => {
        return res
      })
    }
  },
  async duplicateElanDocumentTemplate({ state, dispatch }, elanDocumentTemplate) {
    if (!elanDocumentTemplate) return

    let clone = _.cloneDeep(elanDocumentTemplate)
    const newId = this.$uuid()
    clone.id = newId
    clone.creationDate = new Date()
    clone.name = this.$utils.generateCopyName(
      clone.name,
      state.elanDocumentTemplates.map((el) => el.name)
    )

    return await dispatch('createNewElanDocument', clone).then((res) => {
      if (res?.status === 200) return clone
    })
  },
  async addDocumentSections({ state, commit, dispatch }, { elanDocumentId, sections }) {
    let found = _.cloneDeep(state.elanDocuments.find((el) => el.id === elanDocumentId))
    if (!found) return

    found.sections = found.sections.concat(sections.map((section) => section.id))

    commit('addDocumentSections', sections)
    return await dispatch('updateElanDocument', found)
  },
  async removeDocumentSection({ state, commit, dispatch }, { elanDocumentId, sectionId }) {
    let found = _.cloneDeep(state.elanDocuments.find((el) => el.id === elanDocumentId))
    if (!found) return

    found.sections = found.sections.filter((secId) => secId !== sectionId)

    commit('removeDocumentSection', sectionId)
    return await dispatch('updateElanDocument', found)
  },
  /************************
   * Section Actions
   ***********************/
  async createSection({ commit }, { file, payload }) {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('documentSection', new Blob([JSON.stringify(payload, false)], { type: 'application/json' }))

    // Content-Type needs to be undefined because its sending both a file and json
    return await this.$axios.post('/api/v1/documentSection/newSection', formData, { headers: { 'Content-Type': undefined } }).then((res) => {
      if (res?.status === 200) {
        commit('addSection', res.data)
        return res.data
      }
    })
  },
  async updateSection({ commit }, payload) {
    // Content-Type needs to be undefined because its sending both a file and json
    await this.$axios.put(`/api/v1/documentSection/${payload.id}`, payload).then((res) => {
      if (res?.status === 200) commit('updateSection', payload)
    })
  },
  async removeTemplateSection({ commit, dispatch }, section) {
    let removed = await dispatch('removeS3Section', section)
    if (!removed) return null
    return await this.$axios.post(`/api/v1/documentSection/deletes`, [section.id]).then((res) => {
      commit('removeTemplateSection', section.id)
      return res
    })
  },
  async duplicateTemplateSection({ state, dispatch, commit }, templateSection) {
    if (!templateSection) return

    let clone = _.cloneDeep(templateSection)
    clone.name = this.$utils.generateCopyName(clone.name,state.sectionTemplates.map((el) => el.name))

    clone = await dispatch('cloneSection', { section: clone, type: DocumentSectionType.TEMPLATE_DOCUMENT_SECTION })
    if (!clone) {
      commit('addSection', clone)
      return clone
    }
  },
  async previewSection({ commit }, section) {
    return await new Promise(async (res) => {
      await this.$axios
        .post(`/api/v1/documentSection/preview`, section)
        .then((result) => {
          if (result.status === 200) res(result.data)
        })
        .catch((err) => {
          const errMsg = _.get(err.response, 'data.shortMsg', '')
          if (!errMsg) return
          commit('addError', errMsg)
          res(null)
        })
    })
  },
  /************************
   * Snippet Actions
   ***********************/
  async saveSnippets({ dispatch }, { section, snippets }) {
    // Post the snippets
    let res = await this.$axios.post(`/api/v1/snippet/multiple`, snippets)
    if (res?.status === 200 && section) {
      // Find the section using the snippets
      if (section) {
        // Update the section's snippet IDs and save it
        let clone = _.cloneDeep(section)
        clone.snippets = res.data
        await dispatch(`updateSection`, clone)
        return clone
      }
    }
  },
  /************************
   * S3 Actions
   ***********************/

  async cloneSections({ state }, { sections, type }) {
    sections = _.cloneDeep(sections)

    if (sections && !Array.isArray(sections)) sections = [sections]
    sections = sections.filter((el) => el !== undefined && el !== null)
    if (sections.length === 0) return
    
    if(typeof sections[0] === 'string'){
      sections = (await this.$axios.get(`/api/v1/documentSection/getMultipleByIds?ids=${sections.join('&ids=')}`)).data
    }
    // After the sections have been copied, overwrite the old section templates with the new section clones
    return (await this.$axios.post(`/api/v1/documentSection/cloneSection/cloneMultiple`, sections)).data
  },
  async cloneSection({ state }, { section, type }) {
    section = _.cloneDeep(section)
    if(type) section.type = type
    return (await this.$axios.post(`/api/v1/documentSection/cloneSection`, section)).data
  },
  async removeS3Section({ state }, section) {
    return (await this.$axios.post(`/api/v1/documentSection/removeSection`, section)).data
  },
}
