
import storeMixin from '@/mixins/storeMixin'
export default {
  mixins: [storeMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
    },
    createNew: {
      type: Boolean,
      default: false,
    },
    slotModel: {},
    customClass: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#374B63', //rgb(11, 98, 135)
    },
    showCross: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '800px',
    },
    noSave: {
      type: Boolean,
      default: false,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '70%',
    },
    minHeight: {
      type: String,
      default: '100px',
    },
    minHeight: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Apply',
    },
    closeOnSave: {
      type: Boolean,
      default: true,
    },
    /**
     * Removes the close confirmation dialog.
     */
    hideConfirm: {
      type: Boolean,
      default: false,
    },
    /**
     * Shows a skeleton loader for input fields if set to true
     */
    skeletonLoader: {
      type: Boolean,
      default: null,
    },
    /**
     * Flag to show that the slotModel is null because data is being fetched
     */
    fetching: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes padding from the bottom of the dialog body.
     */
    dense: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes padding from the top and bottom of the dialog body.
     */
    denseContent: {
      type: Boolean,
      default: false,
    },
    /**
     * Shows the load template button on top of dialog. Emits ('template-click')
     */
    templates: {
      type: Boolean,
      default: false,
    },
    childDataChanges: {
      type: Boolean,
      default: false,
    },
    changes: {
      type: [Object, Array, String],
    },
    /**
     * Array of values to be shown as selectable tabs in the dialog
     * Each tab has its own edit and labels slot - Naming is "tabName"-edit and "tabName"-labels, e.g. Assets-edit
     */
    tabs: {
      type: Array,
      default: null,
    },
    /**
     * If tabs are used, the name of the tab to open by default
     */
    openTab: {
      type: String,
      default: null,
    },
    /**
     * Adds a checkbox with the string as the text next to it, ticking the box keeps the dialog open when saving
     */
    addMultiple: {
      type: String,
      default: null,
    },
    submitOnEnter: {
      type: Boolean,
      default: true,
    },
    /**
     * Allows for additional validation to be run before submitting
     * The method should return {success:true/false, errorMsg:String}
     */
    extraValidation: {
      type: Function,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hideAddAnother: {
      type: Boolean,
      default: false,
    },
    sideDialogOpen: {
      type: Boolean,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      default: false,
    },
    submitEventOverride: {
      type: Function,
      default: null,
    },
    /**
     * Sets v-card-text to height 100%
     */
    fillHeight: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedTab: null,
      modelSnapshot: null,
      closeDialog: false,
      changesDetected: false,

      stayOpen: null,
      // slight debounce so custom-save isnt called multiple times if multiple dialogs are open
      customSave: _.debounce(() => this.$emit('custom-save'), 500),
    }
  },
  computed: {
    canShowEdit() {
      return !this.readOnly && !this.$db.editInProgress() && !this.editMode
    },
    canShowSave() {
      return !this.readOnly && !this.$db.editInProgress() && this.editMode && this.showSaveButton
    },
    // The dialog should never close if in edit mode. However clicking the close button will close it
    isPersistent() {
      if (this.selectedModule == 'tasks') {
        return this.persistent || this.isEdit()
      }
      return this.persistent || (this.isEdit() && (this.changesDetected || this.childDataChanges))
    },
    isSmallScreen() {
      // Screen width threshold to set the dialog min width
      return this.$vuetify.breakpoint?.width <= 1500
    },
  },
  methods: {
    enterPress(e) {
      if (e?.srcElement?.className === 'ql-editor') return // Rich text editor is focused, don't close the dialog
      this.submit(true)
    },
    setShow(show) {
      if (!show) this.$emit('close')
      this.$emit('input', show)
    },
    hasSlot(name) {
      return this.$slots[name] || this.$scopedSlots[name]
    },
    isEdit() {
      return !this.readOnly && this.editMode === true
    },
    editSlots() {
      return !!this.$slots.edit || !!this.$slots.labels
    },
    actions() {
      return !!this.$slots.actions
    },
    skeletonSlot() {
      return !!this.$slots.skeleton
    },
    emitEdit() {
      this.$emit('edit', !this.editMode)
    },
    saveBtnClick() {
      if (!!this.$listeners?.saveBtn) {
        this.$emit('saveBtn')
      } else {
        this.submit(false, true)
      }
    },
    submit(enter = false, stayOpenOverride = false, saveBtnEvt = false) {
      if (!_.isNil(this.submitEventOverride)) {
        this.submitEventOverride()
        return
      }
      if (enter && (!this.submitOnEnter || this.loading)) return
      //If there is extra validation, run it.
      if (this.extraValidation) {
        //The method should return {success:true/false, errorMsg:String}
        let result = this.extraValidation()
        if (!result.success) {
          this.$store.commit('application/snack/set', { type: 'error', message: ['Validation failed.'].concat(result.errorMsg) })
          return false
        }
      }

      if (!this.$refs.validation) return false
      if (this.$refs.validation.validate()) {
        // stayOpen will be null if the "Add Another" was never ticked
        if (!this.stayOpen && this.closeOnSave && !stayOpenOverride) {
          this.$emit('input', false)
        }

        this.$emit('save', saveBtnEvt)
        return true
      } else {
        this.$forms.errorSnack(this.$refs.validation)
        return false
      }
    },
    // Method used by outer components to validate the form via a ref on this dialog
    formValidation(errorSnack = true) {
      if (!this.$refs.validation) return false
      const valid = this.$refs.validation.validate()
      if (!valid && errorSnack) this.$forms.errorSnack(this.$refs.validation)
      return valid
    },
    isMenuOpen() {
      let menuIsOpen = false
      // Loop over each component in the form - check if any children components have a menu open
      _.get(this.$refs, 'validation.$children', []).forEach((component) => {
        if (menuIsOpen) return // Break loop
        if (this.nestedComponentCheck(component)) {
          menuIsOpen = true
          return
        }
        _.get(component, '$children', []).forEach((child) => {
          if (menuIsOpen) return // Break loop
          if (this.nestedComponentCheck(child)) {
            menuIsOpen = true
            return
          }
          _.get(child, '$children', []).forEach((deepChild) => {
            if (this.nestedComponentCheck(deepChild)) {
              menuIsOpen = true
              return
            }
          })
        })
      })
      return menuIsOpen
    },
    nestedComponentCheck(component) {
      if (!component) return false
      // Check if a menu is active or the active component has a keydown event on it already
      if (_.get(component, 'isMenuActive') || (Object.keys(_.get(component, '$listeners', {})).includes('keydown') && _.get(component, 'isFocused')) || (_.get(component, '_name') === '<VMenu>' && _.get(component, 'value'))) {
        return true
      }
      return false
    },
    close() {
      if (this.$db.editInProgress() || this.editMode) {
        if (this.modelSnapshot) {
          // Revert dialog data
          if (typeof this.modelSnapshot === 'object') {
            Object.keys(this.modelSnapshot).forEach((k) => (this.slotModel[k] = this.modelSnapshot[k]))
          } else if (typeof this.modelSnapshot === 'string' || typeof this.modelSnapshot === 'number') {
            this.slotModel = this.modelSnapshot
          }
        }
      }
      this.closeDialog = false
      this.$emit('close')
      this.$emit('input', false)
    },
  },
  watch: {
    stayOpen(val) {
      this.$emit('stayOpen', val)
    },
    changes: {
      deep: true,
      handler(newVal) {
        this.changesDetected = true
      },
    },
    value(newValue) {
      this.changesDetected = false
      if (newValue === true) {
        if (this.tabs && this.selectedTab !== this.tabs[0]) {
          // Default selected tab to the first tab
          this.selectedTab = this.tabs[0]
        }
        if (this.slotModel) {
          // Create a snapshot to revert back to if data is not saved
          this.modelSnapshot = this._.cloneDeep(this.slotModel)
        }
      } else {
        // revert 'stayOpen' flag when closing dialog
        this.stayOpen = false
      }
    },
    fetching(newVal, prevVal) {
      // If data was still being fetched when the dialog opened, set up modelSnapshot here instead as it would've been null on dialog open
      if (prevVal === true && newVal === false && this.value === true && this.slotModel) {
        this.modelSnapshot = this._.cloneDeep(this.slotModel)
      }
    },
  },
  mounted() {
    if (this.tabs) {
      if (this.openTab) {
        // Open given tab
        this.selectedTab = this.openTab
      } else {
        // Default selected tab to the first tab
        this.selectedTab = this.tabs[0]
      }
    }

    // Alt + S hotkey
    this.$bus.$on('request-save', () => {
      if (!this.value) return

      // submit form
      if (!this.submit()) return

      this.customSave()
    })
  },
}
