
export default {
  data() {
    return {
      completed: true, // Default to true because we're not currently using this loading page
      timeoutId: null,
      failedToLoad: false,
    }
  },
  computed: {
    isMobile() {
      return this.$route.name === 'mobile'
    },
    loading() {
      return this.$store.state.application.loading.isLoading
    },
    loadingState() {
      return this.$store.state.application.appData.loadingState
    },
    progressValue() {
      let values = Object.values(this.$store.state.application.appData.loadingState)
      return (values.filter((el) => el === true).length / values.length) * 100
    },
    failed() {
      return this.failedToLoad || Object.values(this.loadingState).filter((el) => el === 'failed').length > 0
    },
  },
  methods: {
    start() {
      if (!this.completed) {
        this.$store.commit(`application/loading/setLoading`, true)
        this.timeoutId = setTimeout(() => {
          this.failedToLoad = true
        }, 30000)
      }
    },
    finish() {
      clearTimeout(this.timeoutId)
      this.$store.commit(`application/loading/setLoading`, false)
      this.completed = true
    },
  },
}
