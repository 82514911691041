import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('modelling', null)

export const state = () => ({
  ...crud.state,
})

export const getters = {
  ...crud.getters,
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
}
