import { PublicClientApplication } from '@azure/msal-browser'

export const state = () => ({
  CLIENT_ID: '39bfcbe2-3b62-4b45-a43a-8dcfeb9d43de',
  SCOPES: ['User.Read', 'Mail.ReadWrite', 'Mail.Send'],

  msal: null, // Microsoft Authentication Library
})

export const getters = {
  getMsal(state) {
    return _.cloneDeep(state.msal)
  },
}

export const mutations = {
  setMsal(state, msal) {
    state.msal = msal
  },
}

export const actions = {
  async init({ state, commit }) {
    const msalConfig = {
      auth: {
        clientId: state.CLIENT_ID,
        // authority: 'https://login.microsoftonline.com/3bef1a37-f6ac-4e07-a328-e7ed5f48cc2a',
      },
    }
    const msalInstance = new PublicClientApplication(msalConfig)
    await msalInstance.initialize()
    commit('setMsal', msalInstance)
  },
  async signIn({ state, commit, getters }, redirect) {
    const msal = getters[`getMsal`]
    var loginRequest = {
      scopes: state.SCOPES,
      prompt: 'select_account',
    }
    if (redirect) loginRequest.state = redirect
    try {
      const loginResponse = await msal.loginPopup(loginRequest)
      await this.$axios.$post(`/api/v1/microsoft/auth`, { accessToken: loginResponse?.accessToken, expiresOn: loginResponse?.expiresOn, account: loginResponse?.account }).then((res) => {
        // Add integration email to store
        if (res) {
          commit('integrations/integrationEmails/addLinkedEmail', res, { root: true })
          if (loginResponse.state.includes('/emails/')) this.$router.push({ path: loginResponse.state })
        }
      })
    } catch (err) {
      // handle error
    }
  },
  async signOut({ getters }) {
    const msal = getters[`getMsal`]
    msal.logoutRedirect({
      onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false
      },
    })
  },
  async removeLinkedEmail({ dispatch, commit }, email) {
    this.$axios.delete(`/api/v1/integrations/emails/delete/outlook/${email}`)
    const isEmailLoggedIn = await dispatch(`isEmailLoggedIn`, email)
    if (isEmailLoggedIn) await dispatch('signOut')
    commit('integrations/integrationEmails/removeLinkedEmail', { email: email, type: 'OUTLOOK' }, { root: true })
  },
  async isEmailLoggedIn({}, email) {
    return await this.$axios.$get(`/api/v1/microsoft/auth/isLoggedIn/${encodeURIComponent(email)}`)
  },
  async createMimeDraft({}, mime) {
    return await this.$axios.$post(`/api/v1/microsoft/outlook/drafts/mime`, mime)
  },
  async createDraft({}, { to, cc, bcc, subject, htmlContent, attachments }) {
    // ** Make sure the correct MS account is authenticated before sending because it will automatically use their outlook to send from
    const generateRecipients = (emails) => {
      if (!emails) return []
      if (!Array.isArray(emails)) emails = [emails]
      let recipients = []
      emails.forEach((e) => {
        recipients.push({
          emailAddress: {
            address: e,
          },
        })
      })
      return recipients
    }

    const draft = {
      subject: subject,
      body: {
        contentType: 'HTML',
        content: htmlContent,
      },
      toRecipients: generateRecipients(to),
      ccRecipients: generateRecipients(cc),
      bccRecipients: generateRecipients(bcc),
      attachments: attachments,
    }
    return await this.$axios.$post(`/api/v1/microsoft/outlook/drafts`, draft)
  },
  // async createDraft({}, emlString) {
  //   const utf8Bytes = new TextEncoder().encode(emlString)
  //   let base64String = btoa(new Uint8Array(utf8Bytes).reduce((data, byte) => data + String.fromCharCode(byte), ''))
  //   // let draft = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  //   return await this.$axios.$post(`/api/v1/microsoft/outlook/drafts`, base64String)
  // },
  async updateDraft({}, { draftId, to, cc, bcc, subject, htmlContent, attachments }) {
    if (!draftId) return // Required draftId

    const generateRecipients = (emails) => {
      if (!emails) return []
      if (!Array.isArray(emails)) emails = [emails]
      let recipients = []
      emails.forEach((e) => {
        recipients.push({
          emailAddress: {
            address: e,
          },
        })
      })
      return recipients
    }

    const draft = {
      subject: subject,
      body: {
        contentType: 'HTML',
        content: htmlContent,
      },
      toRecipients: generateRecipients(to),
      ccRecipients: generateRecipients(cc),
      bccRecipients: generateRecipients(bcc),
      attachments: attachments ?? [],
    }
    return await this.$axios.patch(`/api/v1/microsoft/outlook/drafts/${draftId}`, draft)
  },
  async sendDraft({}, draftId) {
    return await this.$axios.$post(`/api/v1/microsoft/outlook/drafts/send`, draftId)
  },
}
