import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('invoices', null, false, true)

export const state = () => ({
  ...crud.state,
  noSidebar: true,
  topBarButtons: [
    { name: 'Invoices', link: '/invoices' },
    { name: 'Template Invoices', link: '/invoices/templates' },
  ],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = typeof id === 'object' ? id : rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const invoice = cachedObj ? _.cloneDeep(cachedObj) : getters['getAllItems'].find((el) => el.id === id) // If not found in cache, try find it
    if (!invoice) return ''
    return invoice.name
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    return ''
  },
  getItems(state, getters, rootState, rootGetters) {
    if (!rootGetters[`integrations/xero/isConnected`]) return []
    // Returns our Invoice objects only if they exist in the Xero organisation or there is no 'to' field meaning it hasn't been saved in Xero yet
    let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
    if (!Array.isArray(items)) return []
    const xeroInvoiceIds = rootGetters[`integrations/xero/getInvoices`].map((el) => el.InvoiceID)
    items = items.filter((el) => xeroInvoiceIds.includes(el.xeroInvoiceId) || (!el.xeroInvoiceId && !el.xeroInvoice.to))
    return _.sortBy(items, (el) => $nuxt.$utils.getDisplayName(el?.id)?.toLowerCase()) // Sort the items by default
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
}
