import appStructure from '../datafiles/appStructure.json'

export const state = () => ({
  connectionToServer: false,
  showContextMenu: false,
  menuOverlay: false,
  enums: null,
  apps: appStructure,
  snackbarSaving: false,
  tabNavIntention: null,
  loadingState: { websocket: false, settings: false, groupData: false, companyDetails: false },
  configHubModule: {
    class: 'ConfigurationHub',
    name: 'Configuration Hub',
    value: 'configurationHub',
  },
  pendingAxiosRequests: [],
  serverMessage: null,
})

export const getters = {
  getServerMessage(state) {
    return state.serverMessage
  },
  isRequestPending: (state) => (request) => {
    if (!request) return false
    return state.pendingAxiosRequests.includes(request)
  },
  getConfigHubModule(state) {
    return state.configHubModule
  },
  getLoadingState(state) {
    return state.loadingState
  },
  enum: (state) => (key) => {
    return state.enums ? _.get(state, `enums[${key}]`, []) : []
  },
  getBackendClassFromModule: (state) => (moduleValue) => {
    const flat = _.flatten(Object.values(state.apps))
    return flat.find((v) => v.value === moduleValue)?.class || null
  },
  getModuleValueFromMetaTag: (state) => (metaTag) => {
    let foundModule
    let flattened = _.flatten(Object.values(state.apps))
    flattened.every((el) => {
      let index = _.get(el, 'metaTags', []).findIndex((tag) => tag.toLowerCase() === metaTag.toLowerCase())
      if (index != -1) {
        foundModule = el
        return false
      }
      return true
    })
    return foundModule?.value
  },
  getNameFromValue: (state) => (value, ignoreWarning = false) => {
    let flattened = _.flatten(Object.values(state.apps))
    for (let i = 0; i < flattened.length; i++) {
      if (flattened[i].value.toLowerCase() === value.toLowerCase()) {
        return flattened[i].name
      }
    }
    if (value === 'configurationHub') return 'Config Hub'
    if (value === 'users') return 'Users'
    if (value !== 'dashboard' && !ignoreWarning) console.warn('Could not find app name from value:', value)
    return value
  },
  getModuleValues(state, getters, rootState, rootGetters) {
    return _.flatMapDeep(state.apps, (el) => el)
      .map(({ value }) => value)
      .concat([state.configHubModule.value])
      .filter((el) => el !== 'comingSoon' && rootGetters[`application/devContent/isModuleActive`](el))
  },
  getAppValueFromModule: (state) => (module) => {
    let foundName = null
    Object.entries(state.apps).forEach(([k, v]) => {
      const result = v.find((el) => {
        if (el.value === module) return true
        else if (_.get(el, 'menuItems', []).find((menuItem) => menuItem.value === module)) return true
      })
      if (result) {
        foundName = k
        return
      }
    })
    return foundName ? _.camelCase(foundName) : null
  },
  getModuleValueFromName: (state) => (module) => {
    let foundModule = _.flatMap(Object.values(state.apps))
      .filter((el) => !el.disabled)
      .find(({ name }) => name === module)
    return foundModule
  },
  getModuleNameFromValue: (state) => (module) => {
    let foundModule = _.flatMap(Object.values(state.apps))
      .filter((el) => !el.disabled)
      .find(({ value }) => value === module)
    return foundModule.name
  },
  getModuleFromValue: (state) => (module) => {
    let foundModule = _.flatMap(Object.values(state.apps))
      .filter((el) => !el.disabled)
      .find(({ value }) => value === module)
    return module === 'configurationHub' ? state.configHubModule : foundModule
  },
  getSinglePageStores(state, getters, rootState, rootGetters) {
    return getters['getModuleValues'].filter((module) => rootGetters[`modules/${module}/isSinglePage`] === true)
  },
  isEditMode(state, getters, rootState, rootGetters) {
    let isEdit = false
    const moduleValues = getters[`getModuleValues`]
    moduleValues.forEach((module) => {
      if (rootGetters[`modules/${module}/isEditMode`]) isEdit = true
    })
    return isEdit
  },
}

export const mutations = {
  setServerMessage(state, message) {
    state.serverMessage = message
  },
  setSnackbarSaving(state, value) {
    state.snackbarSaving = value
  },
  setTabNavIntention(state, value) {
    state.tabNavIntention = value
  },
  setLoading(state, { key, value }) {
    state.loadingState[key] = value
    const finishedLoading = Object.values(state.loadingState).filter((el) => el !== true).length === 0
    if (process.browser && finishedLoading) {
      setTimeout(() => {
        window.$nuxt.$root.$loading.finish()
      }, 1000)
    }
  },
  setMenuOverlay(state, isOpen) {
    state.menuOverlay = isOpen
  },
  updateServerConnection(state, isConnected) {
    state.connectionToServer = isConnected
  },
  setToggleContextMenu(state, showOverride) {
    // No subscription active, don't open the menu
    if (!this.$auth.$state.user[this.$auth.ctx.$config.audience + '/hasActiveSubscription']) return false
    // Else open as normal
    if (!_.isUndefined(showOverride)) {
      state.showContextMenu = showOverride
    } else {
      state.showContextMenu = !state.showContextMenu
    }
  },
  setEntitySearch(state, isShowing) {
    state.entitySearch = isShowing
  },
  addAxiosRequest(state, request) {
    if (!state.pendingAxiosRequests.includes(request)) state.pendingAxiosRequests.push(request)
  },
  removeAxiosRequest(state, request) {
    const index = state.pendingAxiosRequests.findIndex((el) => el === request)
    if (index >= 0) state.pendingAxiosRequests.splice(index, 1)
  },
  removeAllAxiosRequest(state) {
    _.set(state, 'pendingAxiosRequests', [])
  },
}

export const actions = {
  async fetchEnums({ commit, state }) {
    commit('application/storeUtils/setItem', { localState: state, item: 'enums', value: await this.$axios.$get('/api/v1/general/enums') }, { root: true })
  },
  fetchAllModuleData({ getters, dispatch }) {
    const exclude = ['documents']
    const moduleValues = getters[`getModuleValues`]
    moduleValues.forEach((module) => {
      if (this._actions[`modules/${module}/getAll`] && !exclude.includes(module)) {
        dispatch(`modules/${module}/getAll`, true, { root: true })
      }
    })
  },
  setAllEdit({ getters, commit }, editMode) {
    const exclude = ['documents']
    const moduleValues = getters[`getModuleValues`]
    moduleValues.forEach((module) => {
      if (this._mutations[`modules/${module}/setEdit`] && !exclude.includes(module)) {
        commit(`modules/${module}/setEdit`, editMode, { root: true })
      }
    })
  },
  preventEditNavigation({ getters }, event) {
    if (getters[`application/appData/isEditMode`]) {
      event.preventDefault()
      event.returnValue = ''
    }
  },
  async fetchServerMessage({ commit }) {
    commit('setServerMessage', await this.$axios.$get('/status/message'))
  },
}
