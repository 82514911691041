
export default {
  props: {
    selectedModule: {
      type: String,
    },
    tabNavIntention: {
      type: Object,
    },
  },
  data() {
    return {
      tabs: [],
      tabOrder: [
        'configurationHub',
        // client data
        'entityGroups',
        'entities',
        'employment',
        'assets',
        'superannuations',
        'portfolios',
        'liabilities',
        'insurances',
        'governmentBenefits',
        'incomeExpenses',
        'fileNotes',
        'fileManager',
        'goalsEventsMilestones',
        // practice manager
        'professionals',
        'sharedFolders',
        // organisation tools
        'tasks',
        'workflows',
        'kanbans',
        'requests',
        'clientPortal',
        'invoices',
        'emails',
      ],
    }
  },
  mounted() {
    this.$bus.$on('ApplicationTabs', (key) => {
      let idx = _.findIndex(this.openModules, { value: this.selectedModule })

      let newModule
      if (key === 'ArrowRight') {
        if (idx < this.openModules.length - 1) newModule = this.openModules[idx + 1]
      } else if (key === 'ArrowLeft') {
        if (idx > 0) newModule = this.openModules[idx - 1]
      }

      if (newModule) {
        this.$router.replace({ path: `/${newModule.value}` }).catch(() => {})
        this.selectModule(newModule)
      }
    })
  },
  computed: {
    openModules() {
      // Get the array of open tabs from the store state
      const openTabs = this.$store.state.auxiliary.user.openTabs

      // Create an array of objects containing tabValue and its corresponding index in tabOrder
      const tabsWithIndexes = openTabs.map((tabValue) => ({
        tabValue,
        index: this.tabOrder.indexOf(tabValue),
      }))

      // Filter out objects with an index of -1 (not found in tabOrder)
      const validTabs = tabsWithIndexes.filter((item) => item.index !== -1)

      // Sort the validTabs array based on the index in ascending order
      const sortedTabs = validTabs.sort((a, b) => a.index - b.index)
      // Map the sortedTabs array to an array of modules using tabValue
      let modules = sortedTabs.map((item) => this.$store.getters['application/appData/getModuleFromValue'](item.tabValue))
      // Filter out modules that shouldn't show
      modules = modules.filter((el) => this.$store.getters[`application/devContent/isModuleActive`](el?.value))
      
      // Return the array of modules
      return modules
    },
    lastTabSelected() {
      let openModule = this.$route.params.module
      if (this._.get(this.openModules, 'length', 0) > 0 && this.openModules[this.openModules.length - 1].value === openModule) return true
      return false
    },
  },
  methods: {
    tabRoute(tab) {
      return `/${tab.value}`
    },
    selectModule(tab) {
      this.$store.commit('application/appData/setTabNavIntention', tab)
      this.tabs.push(tab)
      this.tabs = _.uniq(this.tabs)
    },
    closeApp(tab) {
      this.$store.commit('auxiliary/user/removeTab', { tab })
      if (this.selectedModule.toLowerCase() === tab.value.toLowerCase()) this.$router.push({ path: `/` })
    },
  },
}
