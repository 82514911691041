let localContext

export const db = {
  // Storing for saving when a different panel is selected
  storedFormData: null,
  storedErrors: false,

  // Gets the name of the current store in use or from the given store name
  storeInUse(storeName = null) {
    if (!storeName) return localContext.route.params.module
  },
  // Gets the name of the current app in use or from the given app name
  appInUse(appName = null) {
    return appName ? appName : localContext.route.path.slice(1).split('/')[0]
  },
  // Checks if selected panel is in edit mode
  selectedPanelIsEdit() {
    if (!db.storeInUse()) return false
    if (db.storeInUse() === 'cashFlow') return true
    return db.editInProgress(true) && localContext.store.state.modules[db.appInUse()][db.storeInUse()].localEdit.id === localContext.route.params.id ? true : false
  },

  // Checks all stores to see if the newEdit field is in use
  newEditInProgress() {
    if (!db.storeInUse()) return false
    return localContext.store.state.modules[db.appInUse()][db.storeInUse()].newEdit ? true : false
  },

  // Checks if a new edit or local edit is in progress
  editInProgress(onlyCheckLocal = false) {
    if (!db.storeInUse()) return false
    const store = _.get(localContext, `store.state.modules[${db.appInUse()}][${db.storeInUse()}]`)
    let localEdit = store?.localEdit ? true : false
    if (onlyCheckLocal) return localEdit
    else return localEdit || store?.newEdit ? true : false
  },

  // Check if the given app/store are in edit mode
  isAppInEdit(appInUse, storeInUse) {
    let app = db.appInUse(appInUse)
    const store = db.storeInUse(db.appInUse(appInUse)) ? db.storeInUse(db.appInUse(appInUse)) : storeInUse
    if (app && store) {
      let localEdit = _.get(localContext.store.state.modules, `[${app}][${store}].localEdit`, false) ? true : false
      return localEdit || _.get(localContext.store.state.modules, `[${app}][${store}].newEdit`, false) ? true : false
    }
    return false
  },

  // Checks if the store's card being editted matches the given card
  cardIsEdit(cardName) {
    if (!db.storeInUse()) return false
    return db.newEditInProgress() || (db.selectedPanelIsEdit() && localContext.store.state.modules[db.appInUse()][db.storeInUse()].localEdit && localContext.store.state.modules[db.appInUse()][db.storeInUse()].localEdit.cardName === cardName) ? true : false
  },

  deselectAll() {
    let modules = localContext.store.getters['application/appData/getModuleValues']
    modules.forEach((store) => {
      console.error(`modules/${store} (modules/\${store}) REQUIRES ATTENTION! db.js:49`)
      // FIX !!!
      if (localContext.store.dispatch(`modules/${store}/select`)) {
        localContext.store.dispatch(`modules/${store}/select`, null)
      } else {
        console.warn('Select action does not exist in store: ' + store)
      }
    })
  },
}

export default (context, inject) => {
  localContext = context
  inject('db', db)
}
