export const state = () => ({
  org: {
    clientPortalSlug: null,
    users: [],
  },
  auth0Roles: [], // Roles fetched from auth0 on server startup
  appMetadata: {},
  openTabs: localStorage.getItem('openTabs') ? localStorage.getItem('openTabs').split(',') : [],
})

/**
 * Takes a user and adds a status field
 * @param {*} user
 * @returns
 */
const userStatusMapper = (user) => {
  if (user.status === undefined) user.status = 'ACTIVE'
  if (user.app_metadata?.invited !== undefined) user.status = 'PENDING'
  if (user.blocked) user.status = 'INACTIVE'
  else if (!user.blocked && user.status != 'PENDING') user.status = 'ACTIVE'
  return user
}
export const getters = {
  username(state, getters) {
    const user = _.cloneDeep(getters.getCurrentUser)
    return getters.getUsernameForId(user?.user_id)
  },
  getCurrentUser(state, getters) {
    return getters.getUser($nuxt?.$auth?.user?.sub)
  },
  getRoleId: (state) => (roleName) => {
    return state.auth0Roles.find((el) => el?.name === roleName)?.id
  },
  isUserAdmin: (state, getters) => (id) => {
    let user = getters['getUsersInOrg'].find((el) => el.user_id === id)
    return user && Array.isArray(user.app_metadata.auth0RoleIds) && user.app_metadata.auth0RoleIds.find((id) => id === getters['getRoleId']('Administrator')) ? true : false
  },
  isOrgOwner: (state, getters) => (id) => {
    let user = getters['getUsersInOrg'].find((el) => el.user_id === id)
    return user && Array.isArray(user.app_metadata.auth0RoleIds) && user.app_metadata.auth0RoleIds.find((id) => id === getters['getRoleId']('Organisation Owner')) ? true : false
  },
  isStandardUser: (state, getters) => (id) => {
    let user = getters['getUsersInOrg'].find((el) => el.user_id === id)
    return user && Array.isArray(user.app_metadata.auth0RoleIds) && user.app_metadata.auth0RoleIds.find((id) => id === getters['getRoleId']('Standard')) ? true : false
  },
  isElanStaff: (state, getters) => (id) => {
    let user = getters['getUsersInOrg'].find((el) => el.user_id === id)
    return user && Array.isArray(user.app_metadata.auth0RoleIds) && user.app_metadata.auth0RoleIds.find((id) => id === getters['getRoleId']('Elan Staff')) ? true : false
  },
  getUsersInOrg(state) {
    // Return users with the current state added to them
    return _.get(state, 'org.users', [])
      .filter((user) => user != undefined && _.isNil(user?.app_metadata?.isSupportUser))
      .map(userStatusMapper)
  },
  /**
   * @return all users, including blocked/support users
   */
  getAllUsers(state) {
    return state?.org?.users ?? []
  },
  getSupportUser(state) {
    return _.first(
      _.get(state, 'org.users', [])
        .filter((user) => user != undefined && !_.isNil(user?.app_metadata?.isSupportUser))
        .map(userStatusMapper)
    )
  },
  getUser: (state, getters) => (id) => {
    let orgUsers = _.cloneDeep(getters['getAllUsers'])
    return orgUsers.find((el) => el.user_id === id)
  },
  getUserRoles: (state, getters) => (id) => {
    const user = getters['getUser'](id)
    return _.get(user, 'roles')
  },
  getActiveUsersInOrg(state, getters) {
    let orgUsers = getters['getUsersInOrg']
    return (
      // Only return non-blocked active users
      orgUsers.filter((f) => f !== undefined && (f.blocked === undefined || f.blocked === false) && f.status === 'ACTIVE')
    )
  },
  getMappedUsersInOrg(state, getters) {
    let orgUsers = getters['getActiveUsersInOrg']
    return orgUsers.map((el) => {
      return { id: el.user_id, name: el.name, email: el.email, picture: el.user_metadata?.picture || el.picture } // Metadata picture (base64) if it exists otherwise the auth0 url
    })
  },
  getUsernameForId: (state) => (id) => {
    const defaultName = 'Unknown Person'
    if (!id) return defaultName
    if (typeof id === 'object') id = id.user_id
    let found = state.org.users.find((user) => user.user_id === id)
    if (found) {
      if (found.given_name || found.family_name) {
        const nameArr = _.concat(found.given_name, found.family_name).filter((el) => !!el)
        if (nameArr.length !== 0) return nameArr.join(' ')
      }
      if (found.name) return found.name
      else if (found.nickname) return found.nickname
    }
    return defaultName
  },
  searchUsers: (state, getters) => (search) => {
    if (search?.length < 2) return []
    let partialUserMatches = []
    state.org.users.forEach(({ user_id }) => {
      const name = getters['getUsernameForId'](user_id)
      if (name.toLowerCase().includes(search)) partialUserMatches.push(user_id)
    })
    return partialUserMatches
  },
  getUsernamesForIds: (state, getters) => (ids) => {
    let usernames = []
    ids.forEach((id) => {
      usernames.push(getters.getUsernameForId(id))
    })
    return usernames
  },
  replaceIdWithUser: (state) => (idArray) => {
    let userArray = []
    idArray.forEach((el) => {
      let found = state.org.users.find((user) => user.user_id === el)
      found ? userArray.push(found) : null
    })
    return userArray
  },
  nameToUserId: (state) => (search) => {
    let userIds = []
    let condition = new RegExp(search)
    let result = state.org.users.filter(function (el) {
      return condition.test(el.name) || condition.test(el.nickname) || condition.test(el.email)
    })
    if (result !== undefined) {
      result.forEach((r) => {
        userIds.push(r.user_id)
      })
    }

    return userIds
  },
  hasActiveSubscription() {
    return $nuxt.$auth.$state.user[$nuxt.$auth.ctx.$config.audience + '/hasActiveSubscription'] || $nuxt.$auth.$state.user[$nuxt.$auth.ctx.$config.audience + '/isSupportUser']
  },
}

export const mutations = {
  addTab: (state, { tab }) => {
    state.openTabs = _.union(state.openTabs, [tab.value])
    localStorage.setItem('openTabs', state.openTabs)
  },
  removeTab: (state, { tab }) => {
    state.openTabs = _.without(state.openTabs, tab.value)
    localStorage.setItem('openTabs', state.openTabs)
  },
  setTabs: (state, tabs) => {
    state.openTabs = _.union(
      state.openTabs,
      tabs.map(({ value }) => value)
    )
    localStorage.setItem('openTabs', state.openTabs)
  },
  updateUserMetadata(state, { id, metadata }) {
    state.org.users.find((user) => {
      if (user.user_id === id) {
        _.set(user, 'user_metadata', metadata)
      }
    })
  },
  setClientPortalSlug: (state, slug) => {
    state.org.clientPortalSlug = slug
  },
  setUserAppMetadata(state) {
    // Set store variable from auth
    // The only variable we actually currently use is 'hasActiveSubscription'
    // ^^^ !!! HES CAPPING !!!
    // Do not use this over getUserAppMetadata action
    let appMetadata = { hasActiveSubscription: false }
    for (const [key, value] of Object.entries(this.$auth.user)) {
      if (key.includes('hasActiveSubscription')) {
        appMetadata['hasActiveSubscription'] = value
        break
      }
    }
    state.appMetadata = appMetadata
  },
}

export const actions = {
  async updateUserRoles({ state, commit }, { userId, roles }) {
    await this.$axios.$post(`/api/v1/roles/update/${userId}`, roles).then((res) => {
      if (res) commit('application/storeUtils/setItem', { localState: state, item: 'auth0Roles', value: res }, { root: true })
    })
  },
  /**
   * Fetches organisation's roles which have an array of users with the role
   */
  fetchRoles({ state, commit }) {
    this.$axios.$get('/api/v1/roles').then((res) => {
      if (res) commit('application/storeUtils/setItem', { localState: state, item: 'auth0Roles', value: res }, { root: true })
    })
  },
  async changeClientPortalSlug({ commit, state }, url) {
    if (state.org.clientPortalSlug === url) return url

    try {
      const data = await this.$axios.$patch(`/api/v1/org/url/${url}`)
      commit('setClientPortalSlug', data)
      return data
    } catch (e) {
      throw e
    }
  },
  // Just calls getUserAppMetadata after a webstomp then shows a dialog of appreciation
  async performUpgrade({ dispatch }) {
    await dispatch('getUserAppMetadata')
    this.$bus.$emit('accountUpgrade')
  },
  async getUserAppMetadata({ state, commit }) {
    const data = await this.$axios.$get(`/api/v1/metadata/app`)
    if (!data) return
    commit('application/storeUtils/setItem', { localState: state, item: 'appMetadata', value: data }, { root: true })
  },
  async getBlankUser({ dispatch }) {
    return await dispatch(`application/storeUtils/getBackendClass`, 'User', { root: true })
  },
  async updateUserMetadata({ state }, { userId, metadata }) {
    if (metadata.needsAdefaultValue) delete metadata.needsAdefaultValue
    return await this.$axios.patch(`/api/v1/metadata/${userId}`, metadata).then((res) => {
      return res.status
    })
  },
  async deleteTaskFilter({ dispatch, rootGetters }, filter) {
    // Task filters are saved in config manager > user > sessionSettings
    let taskManagementFilters = _.get(rootGetters['modules/configurationHub/getSessionSettings'](this.$auth.user.sub), 'taskManagementFilters')
    if (taskManagementFilters && Object.keys(taskManagementFilters).includes(_.startCase(_.camelCase(filter)))) {
      delete taskManagementFilters[_.startCase(_.camelCase(filter))]
      await dispatch('modules/configurationHub/patchSessionSettings', { userId: this.$auth.user.sub, newObj: { taskManagementFilters: taskManagementFilters } }, { root: true })
    }
  },
  async saveTaskFilter({ dispatch, rootGetters }, filter) {
    // Task filters are saved in config manager > user > sessionSettings
    let taskManagementFilters = _.get(rootGetters['modules/configurationHub/getSessionSettings'](this.$auth.user.sub), 'taskManagementFilters')
    if (!taskManagementFilters && !rootGetters['modules/configurationHub/isPending']) taskManagementFilters = {}
    if (taskManagementFilters) {
      taskManagementFilters[_.startCase(_.camelCase(filter.name))] = filter.filters // TODO filter.filters is undefined
      await dispatch('modules/configurationHub/patchSessionSettings', { userId: this.$auth.user.sub, newObj: { taskManagementFilters: taskManagementFilters } }, { root: true })
      this.$router.push(`/tasks/${_.camelCase(filter.name)}`)
    }
  },
  async getOrgUsers({ state, commit }) {
    await this.$axios.get('/api/v1/users/all').then((res) => {
      if (res.data) {
        commit('application/storeUtils/setItem', { localState: state, item: 'users', value: res.data }, { root: true })
      }
    })
  },
  async getUserById({ state, commit }, id) {
    await this.$axios.get(`/api/v1/users/by_id/${id}`).then(async (res) => {
      if (res.data) {
        commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: res.data, action: 'update' }, { root: true })
      }
    })
  },
  async getCompanyDetails({ state, commit, rootState }, cancelToken) {
    commit('application/appData/setLoading', { key: 'companyDetails', value: 'pending' }, { root: true })
    return await this.$axios
      .get('/api/v1/org/company_details', { cancelToken: cancelToken ? cancelToken.token : null })
      .then((res) => {
        if (res.data) {
          commit('application/storeUtils/setItem', { localState: state.org, item: 'users', value: res.data.users }, { root: true })
          commit('application/storeUtils/setItem', { localState: state.org, item: 'clientPortalSlug', value: res.data.clientPortalSlug }, { root: true })
          commit('application/appData/setLoading', { key: 'companyDetails', value: true }, { root: true })
        }
        return res
      })
      .catch((e) => {
        commit('application/appData/setLoading', { key: 'companyDetails', value: 'failed' }, { root: true })
        throw e
      })
  },
  async getBillingDetails({ state, commit }) {
    await this.$axios
      .get('/api/v1/org/billing_details')
      .then((res) => {
        if (res.data) {
          commit('auxiliary/stripe/updateReceipts', res.data?.receipts, { root: true })
          commit('auxiliary/stripe/updateCustomer', res.data?.customer, { root: true })
        }
      })
      .catch((err) => console.error('BILLING FAILED', err))
  },
  async updateMember({ state, commit, dispatch }, payload) {
    let rollbackRef = _.cloneDeep(state.org.users.find((member) => member.user_id === payload?.user_id))
    let updatedUser = null // Object with the updated changes so that we can update all the store's user variables at once
    let status = await this.$axios
      .patch('/api/v1/users/update', payload)
      .then((res) => {
        if (res?.status === 200) updatedUser = res.data
        commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: rollbackRef, action: 'update' }, { root: true })
        return res?.status
      })
      .catch((err) => {
        commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: rollbackRef, action: 'update' }, { root: true })
        return 400
      })

    // Update metadata too if it exists
    if (status === 200 && payload.user_metadata) {
      status = await dispatch('updateUserMetadata', { userId: payload.user_id, metadata: payload.user_metadata })
        .then((res) => {
          updatedUser.user_metadata = payload.user_metadata
          return res
        })
        .catch((err) => {
          updatedUser.user_metadata = rollbackRef.user_metadata
          return 400
        })
    }
    // Update user with all the changes at once
    if (updatedUser) commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: updatedUser, action: 'update' }, { root: true })
    return status
  },
  async removeMember({ state, commit, dispatch }, userId) {
    return await this.$axios.delete(`/api/v1/users/${userId}`).then((res) => {
      if (res.data) {
        commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: [userId], action: 'delete' }, { root: true })
        dispatch('modules/configurationHub/removeUserFromTeams', userId, { root: true })
      }
      return res.status == 200
    })
  },
  /**
   * @description Revoke a member from the organization, set thier blocked field to true and updated the store for reactivity
   * @param {*} user The whole user object from auth0
   * @returns {Boolean}
   */
  async toggleMemberAccess({ state, commit, dispatch }, user) {
    //We must clone this because its now the value in the store, vuex mutation

    //Insta Update the store
    user.blocked = !user.blocked
    commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: _.cloneDeep(user), action: 'update' }, { root: true })

    //Default the the unsuccessful case
    let status = 400
    //If we need to block the user
    if (user.blocked) {
      status = (await this.$axios.delete(`/api/v1/users/revoke/${user.user_id}`)).status
      if (status === 200) dispatch('modules/configurationHub/removeUserFromTeams', user.user_id, { root: true })
    } else {
      status = (await this.$axios.patch('/api/v1/users/restore', _.cloneDeep(user))).status
    }

    //Undo the store modification
    if (status != 200) {
      //We must reclone this because its now the value in the store, vuex mutation
      clondedUser = _.cloneDeep(user)
      clondedUser.blocked = !clondedUser.blocked
      commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: clondedUser, action: 'update' }, { root: true })
    }

    return status === 200
  },
  async addMember({ state, commit }, payload) {
    return await this.$axios.post('/api/v1/users', payload).then((res) => {
      if (res?.data) {
        commit('application/storeUtils/modifyCollection', { collection: state.org.users, item: res?.data, action: 'add' }, { root: true })
      }
      return res.status
    })
  },
  async passwordChange({ state, commit }, payload) {
    return await this.$axios.post('/api/v1/users/password_change', { email: payload.email }).then((res) => {
      return res.status
    })
  },
  async logout({ commit }, warn = false) {
    window.removeEventListener('beforeunload', this.$navigation.preventEditNavigation) // Remove navigation prevention so that logout always occurs
    if (!warn) {
      commit(`application/appData/removeAllAxiosRequest`, {}, { root: true })
      // Clear tokens and cookies before logout
      this.$auth.strategies?.auth0?.reset()
      this.$auth.strategy?.token?.reset()
      //await this.$axios.get('/api/v1/users/logout')

      // Logout
      this.$axios.get('/api/v1/users/logout')
      await this.$auth.logout()
      return
    }

    // Send to ApplicationUserLogoutModal - logout will be called inside that component
    this.$bus.$emit('forceLogoutModal')
  },
}
