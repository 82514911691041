
import head from '@/mixins/head.js'

export default {
  name: 'DefaultLayout',
  mixins: [head],
  mounted() {
    // inject a quilljs mention style specific for your user
    const style = document.createElement('style')
    style.textContent = `[data-id="${this.$auth.$state.user.sub}"] { background-color: var(--v-highlight-base) !important; color: white !important; }`
    document.head.appendChild(style)

    // this.$nuxt.error({ statusCode: 500, message: 'Unable to connect to Elan' })
    /**
     * Key bindings
     */
    window.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        this.hideOverlay()
        this.hideClientSearchOverlay()
        if (this.snackbar) this.snackbar = false
      }
      if (e.code === 'Enter') {
        if (this.snackbar) this.saveEdit()
      }

      // Global Hotkeys
      if (e.altKey) {
        let hasHotkey = false
        if (e.key === 's' || e.key === 'e') {
          hasHotkey = true
        }

        // detect globally if any dialogs are open
        let nodeList = []
        if (e.key === 'e' || e.key === 's') nodeList = document.querySelectorAll('.v-overlay.v-overlay--active')

        // enter edit
        if (e.key === 'e' && !this.inEdit) {
          this.$bus.$emit('custom-edit', false)
        }
        // save
        if (e.key === 's' && this.inEdit) {
          // this request-save is caught in ApplicationDialog
          if (nodeList.length) this.$bus.$emit('request-save', this.inEdit)
          else this.$bus.$emit('custom-save')
        }
        // tab nav
        if (e.key === 'ArrowRight') {
          hasHotkey = true
          this.$bus.$emit('ApplicationTabs', e.key)
        }
        if (e.key === 'ArrowLeft') {
          hasHotkey = trueApplicationHeaderBar
          this.$bus.$emit('ApplicationTabs', e.key)
        }
        // notification menu
        if (e.key === 'n') {
          hasHotkey = true
          this.$bus.$emit('ApplicationNotificationMenu')
        }

        if (hasHotkey) {
          e.preventDefault()
          e.stopImmediatePropagation()
          e.stopPropagation()
        }
      }
    })

    this.$bus.$on('global/navigationBlocked', () => {
      this.snackbar = true
    })
  },
  data() {
    return {
      hiddenTabApps: ['configurationHub'],
      snackbar: false,
      savingFlag: false,
      overlay: false,
      groupSearchOverlay: false,
      clientSearchOverlay: false,
      subscriptionError: false,
      restoringData: false,
      tabNavIntention: null,
    }
  },
  methods: {
    async saveEdit() {
      this.savingFlag = true
      setTimeout(() => {
        setTimeout(() => {
          this.$bus.$emit('custom-edit')
          this.snackbar = false
          if (_.has(this.$store.state.application.appData.tabNavIntention, 'value')) this.$router.push(`/${this.$store.state.application.appData.tabNavIntention.value}`)
          this.savingFlag = false
        }, 300)
      }, 300)
    },
    hideOverlay() {
      this.overlay = false
    },
    hideClientSearchOverlay() {
      this.$store.commit('application/appData/setEntitySearch', false)
    },
    checkActiveSubscription(stripeCust) {
      // https://stripe.com/docs/api/subscriptions/object#subscription_object-status
      this.subscriptionError = !['active', 'trialing'].includes(stripeCust.subscriptions?.status)
    },
  },
  computed: {
    inEdit() {
      return _.get(this.$store.state, `modules.${this.selectedModule}.editMode`)
    },
    selectedModule() {
      return this.$route.name?.split('-')[0]
    },
    snackbarSaving: {
      get() {
        return this.$store.state.application.appData.snackbarSaving
      },
      set(v) {
        this.$store.commit('application/appData/setSnackbarSaving', v)
      },
    },
    isLoading() {
      return _.get(this.$store.state, 'application.loading.isLoading', false)
    },
    appInUse() {
      return this.$utils.getRouteParams(this.$route.path)[0]
    },
    moduleInUse() {
      return this.$utils.getRouteParams(this.$route.path)[1]
    },
    disableNav() {
      const isEdit = _.get(this.$store.state, `modules.${this.selectedModule}.editMode`, false)
      const autosave = _.get(this.$store.getters['modules/configurationHub/getCurrentUserSettings'], 'autoSave', false)
      if (autosave) return false
      return isEdit
    },
    showTabs() {
      return !this.hiddenTabApps.includes(this.appUrl)
    },
    showMenuOverlay() {
      return this.$store.state.application.appData.menuOverlay
    },
    connectionToServer() {
      return this.$store.state.application.appData.connectionToServer
    },
  },
  watch: {
    selectedModule: {
      deep: true,
      immediate: true,
      handler(val) {
        //Is it already a tab?
        const tabs = this.$store.state.auxiliary.user.openTabs
        if (Array.isArray(tabs) && tabs.filter((tabValue) => tabValue === val).length > 0) {
          return
        }

        let foundModule = _.flatMap(Object.values(this.$store.state.application.appData.apps))
          .filter((el) => !el.disabled)
          .find(({ value }) => value.toLowerCase() === val)

        if (val === 'configurationHub') foundModule = this.$store.state.application.appData.configHubModule

        if (_.get(foundModule, 'value', false)) {
          this.$store.commit('auxiliary/user/addTab', { tab: foundModule })
        }
      },
    },
    '$store.state.auxiliary.stripe.customer': {
      deep: true,
      handler(val) {
        if (val !== null) {
          this.checkActiveSubscription(val)
        }
      },
    },
    disableNav(v) {
      if (v) return
      this.snackbar = false
    },
  },
}
