export default {
  computed: {
    hasAccess() {
      // No access if not logged in or no subscription
      if (!this.$auth.loggedIn || (this.$auth.loggedIn && !this.$store.getters[`auxiliary/user/hasActiveSubscription`])) return false

      // Admins & org owners always have access
      if (this.isAdmin) return true

      return false
    },
    isStandardUser() {
      return this.$store.getters[`auxiliary/user/isStandardUser`](this.$auth.user.sub)
    },
    isOrgOwner() {
      return this.$store.getters[`auxiliary/user/isOrgOwner`](this.$auth.user.sub) || this.isElanStaff
    },
    isAdmin() {
      return this.$store.getters[`auxiliary/user/isUserAdmin`](this.$auth.user.sub) || this.isOrgOwner
    },
    isElanStaff() {
      return this.$store.getters[`auxiliary/user/isElanStaff`](this.$auth.user.sub)
    },
    rolesMapping() {
      return this.$store.state?.auxiliary?.user?.auth0Roles || []
    },
    rolesIdList() {
      return this.rolesMapping.map((m) => m.id)
    },
  },
  methods: {
    getUserRoleName(userId) {
      // Return a name based on which permissions the user has, otherwise permissions is Standard
      if (userId && this.$store.getters[`auxiliary/user/isElanStaff`](userId)) return 'Elan Staff'
      else if (userId && this.$store.getters[`auxiliary/user/isOrgOwner`](userId)) return 'Organisation Owner'
      else if (userId && this.$store.getters[`auxiliary/user/isUserAdmin`](userId)) return 'Administrator'
      return 'Standard'
    },
  },
}
