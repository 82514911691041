export const state = () => ({
  coordinates: {},
})

export const getters = {
  getCanvas: (state) => (id) => {
    return state.coordinates[id]
  },
}

export const mutations = {
  setCanvas(state, { id, canvas }) {
    state.coordinates[id] = canvas
  },
}

export const actions = {
  async fetchCanvas({ getters, commit }, id) {
    // Check if it exists in state before fetching
    const exists = getters['getCanvas'](id)
    if (exists) return exists
    // Not already fetched so fetch it
    const res = await this.$axios.$get(`/api/v1/canvas/${id}`)
    commit('setCanvas', { id: id, canvas: res })
    return res
  },
  async saveCanvas({ commit }, { id, canvas }) {
    await this.$axios.$post(`/api/v1/canvas/${id}`, canvas)
    commit('setCanvas', { id: id, canvas: canvas })
  },
}
