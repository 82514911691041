export default {
  computed: {
    riskProfileList() {
      let riskProfiles = this.$store.state.modules.configurationHub.allItems?.entities?.riskProfiles?.items
      return riskProfiles ? riskProfiles : []
    },
    pendingRiskProfiles() {
      return this.$store.state.modules.configurationHub.allItems === 'pending'
    },
    teams() {
      return this.$store.getters[`modules/configurationHub/getTeams`]
    },
    roles() {
      return this.$store.getters[`modules/configurationHub/getRoles`]
    },
    tags() {
      return this.$store.getters[`modules/configurationHub/getTags`]
    },
    mappedTags() {
      if (!Array.isArray(this.tags())) {
        return this.tags().map((tag) => {
          return { name: tag.name, value: tag.id }
        })
      }
    },
    usersInOrg() {
      return this.$store.getters['auxiliary/user/getMappedUsersInOrg'].map((el) => ({ value: el.id, ...el }))
    },
    dashboardTypes() {
      return this.getConfigItems('dashboard.types')
    },
  },
  methods: {
    getActivityType(id) {
      if (!Array.isArray(this.dashboardTypes)) return
      return this.dashboardTypes.find((el) => el.id === id)
    },
    /**
     * Get a tag by its id ( can also use configSearch )
     * @param {String} id
     * @returns
     */
    getTag(id) {
      return this.$store.getters[`modules/configurationHub/getTag`](id)
    },
    /**
     * @argument id - the id of the ConfigTableItem to lookup anywhere in config hub
     * @argument path - the lodash path of the item you are looking for - will speed up the lookup
     * @returns ConfigTableItem { editable, id, name } - or null if not found
     */
    configSearch(id, path, ignoreWarnings = false) {
      return this.$store.getters[`modules/configurationHub/idSearch`](id, path, ignoreWarnings)
    },
    /**
     * @param {String} path the lodash _get path to the items, e.g. `entities.riskProfiles` -> gets `state.allItems.entities.riskProfiles.items`
     * @param {(m: any) => any} [mapper=(m) => Object.assign(m, { value: this._.toUpper(m.name).replace(/_/g, '_') })] optional mapper function to apply
     * @returns Array
     */
    getConfigItems(path, mapper = (m) => Object.assign(m, { value: this._.toUpper(m.name).replace(/_/g, '_') })) {
      return this.$store.getters[`modules/configurationHub/getItems`](path)?.map(mapper)
    },
  },
}
