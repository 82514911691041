
export default {
  data () {
    return {
      dialog: false,
      thankYouDialog: false,
      loading: false,

      // moment object which updates every 5 secs
      currentTimeMoment: this.$moment()
    }
  },
  mounted() {
    this.$bus.$on('accountUpgrade', () => {
      this.dialog = false
      this.thankYouDialog = true
    })

    this.$bus.$on('trialExpired', () => {
      this.dialog = true
    })
    if (this.isExpired) this.dialog = true

    setInterval(() => {
      this.currentTimeMoment = this.$moment()
    }, 5000)
  },
  computed: {
    isOwner() {
      return this.$store.getters['auxiliary/user/isOrgOwner'](this.$auth.user.sub)
    },
    checkoutUrl() {
      return _.get(this.$store.state, 'auxiliary.stripe.checkout_session', '')
    },
    trialEnd() {
      return _.get(this.$store.state, 'auxiliary.user.appMetadata.trialEnd', null)
    },
    isTraining() {
      return this.trialEnd === null
    },
    isExpired() {
      if (!this.trialEnd) return false
      
      const trialEnd = this.$moment.unix(this.trialEnd)
      return this.currentTimeMoment.isAfter(trialEnd)
    },
    checkoutLoading() {
      return _.get(this.$store.state, 'auxiliary.stripe.loading_checkout_session', false)
    },
    extensionLoading() {
      return _.get(this.$store.state, 'auxiliary.stripe.loading_extension', false)
    },
  },
  methods: {
    upgradeTrialDialog() {
      this.$store.dispatch('auxiliary/stripe/upgradeTrialDialog')
    },
    requestTrialExtension() {
      this.$store.dispatch('auxiliary/stripe/requestTrialExtension', { message: "", email: this.$auth.user.email })
    },
    async logout() {
      sessionStorage.setItem('auth0Message', 'Thank you for using Elan. We hope to see you again!')
      await this.$store.dispatch('auxiliary/user/logout')
    }
  },
  watch: {
    isExpired(v) {
      this.dialog = true
    }
  }
}
