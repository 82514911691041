
export default {
  computed: {
    message() {
      let msg = this._.get(this.snack, 'message')
      return Array.isArray(msg) ? msg : [msg]
    },
    height() {
      // Manually set the height (20px per line of text + 20px padding) because the icon col doesn't want to fill height on its own
      let height = this._.get(this.snack, 'height')
      if (!height) height = (Array.isArray(this.message) ? this.message.length * 20 + 20 : 40) + 'px'
      return height
    },
    snack: {
      get: function () {
        return this.$store.state.application.snack.centerSnack
      },
      set: function (val) {
        if (val === false) {
          this.$store.commit('application/snack/setCenter', false)
        }
      },
    },
  },
  methods: {
    openLink() {
      if (!_.get(this.snack, 'link')) return
      this.$router.push(_.get(this.snack, 'link'))
      this.snack = false
    },
  },
}
