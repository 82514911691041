export const state = () => ({
  /**
   * --- An object with the following keys ---
   * type (string): 'error', 'info', 'success'
   * message (string): Message to be shown
   * height (string): Height of snackbar, default 40px
   *
   * --- Optional Keys (for undoing delete/archive) ---
   * timeoutId (string): ID given from setTimeout function
   * module (string): Store name
   * data (array): Array of objects of data to be restored
   * action (string): 'add' for deleted data or 'restore' for archived data
   * callback (function): A callback function for custom functionality when 'UNDO' is clicked
   * link (string): Show 'VIEW' button and open the link when clicked
   */
  notification: false,
  timeoutId: null,

  // Snackbar timeout times
  defaultTime: '5000',
  successTime: '5000',
  infoTime: '7000',
  errorTime: '5000',

  // history
  history: [],

  // Center snack for "The completed X was assocaited with X" messages
  centerSnack: false,
})

export const mutations = {
  removeHistoryItem(state, id) {
    state.history = state.history.filter((item) => item.id !== id)
  },
  clearHistory(state) {
    state.history = []
  },
  setCenter(state, notification) {
    state.centerSnack = notification
  },
  set(state, notification) {
    state.notification = notification

    // Manually set/reset timeout as the vuetify inbuilt one doesn't reset the timer correctly
    if (notification) {
      if (state.timeoutId) clearTimeout(state.timeoutId)
      state.timeoutId = setTimeout(
        () => {
          this.commit('application/snack/reset')
        },
        notification.timeout ? notification.timeout : state.defaultTime
      )
    }
  },
  reset(state) {
    if (state.notification) {
      const notif = _.cloneDeep(state.notification)
      // unpack items into individual snack histories
      _.defaultTo(notif?.data?.items, []).forEach(item => {
        state.history.push({ ...notif, data: item, time: $nuxt.$moment(), id: $nuxt.$uuid() })
      })
    }

    if (state.notification && state.notification.timeoutId) clearTimeout(state.notification.timeoutId) // Reset timer for delete/archiving
    if (!state.notification || (state.notification && state.notification.message !== 'Creating...')) {
      // Close notification if message isn't creating
      if (state.timeoutId) clearTimeout(state.timeoutId)
      state.timeoutId = null
      state.notification = false
    }
  },
}
