let localContext

export const calculations = {
  /**
   *
   * @param {*} frequency
   * @param {*} amount
   * @param {*} dateRange
   * @returns
   */
  sumBreakdown: (frequency, amount, dateRange = null) => {
    if (!frequency || !amount) return ''

    const oneOff = _.get(dateRange, 'oneOff', false)
    if (oneOff) return localContext.$utils.moneyDisplay(amount) + ' one-off'
    else return `${localContext.$utils.moneyDisplay(amount)} per ${localContext.$common.printFrequency(frequency)}`
  },
  sumAnnualDollars: (arr, decimals = 2) => {
    if (!arr || (arr && !Array.isArray(arr))) return 0
    let total = 0
    arr.forEach((item) => {
      total += Number(calculations.getAnnualDollars(item.frequency, item.amount, decimals))
    })
    return total
  },
  sumAnnualDollarsString: (arr, decimals = 2) => {
    if (!arr || (arr && !Array.isArray(arr))) return localContext.$utils.moneyDisplay(0, decimals)
    let total = 0
    arr.forEach((item) => {
      total += Number(calculations.getAnnualDollars(item.frequency, item.amount))
    })
    return localContext.$utils.moneyDisplay(total, decimals)
  },
  getAnnualDollars: (frequency, amount, decimals = 2) => {
    if (!frequency || !amount || !Number(amount)) return 0
    frequency = localContext.$common.frequencyValues(frequency)
    return _.round(Number(localContext.$common.annualMultiplier(Number(frequency)) * Number(amount)), decimals)
  },
  getAnnualDollarsString: (frequency, amount, decimals = 2) => {
    if (!frequency || !amount || !Number(amount)) return localContext.$utils.moneyDisplay(0, decimals)
    return localContext.$utils.moneyDisplay(calculations.getAnnualDollars(frequency, amount), decimals)
  },
  getAnnualPercent: (startDate, balanceAtJune, amount, percentage) => {
    let newDate = new Date()
    // If start date after 30th of June
    if (localContext.$moment(startDate).isAfter(newDate.getFullYear() + '-06-30')) {
      if (!startDate || !amount || !Number(amount) || !percentage) return 0
      let daysInYear = localContext.$moment().isLeapYear() ? 366 : 365
      var startOfFinancialYear = localContext.$moment(newDate.getFullYear() + '-07-01')
      return Number(Number(amount) * Number(percentage) * ((daysInYear - localContext.$moment(startDate).diff(startOfFinancialYear, 'days')) / daysInYear)).toFixed(0)
    } else {
      if (!balanceAtJune || !percentage) return 0
      return Number(Number(balanceAtJune) * Number(percentage)).toFixed(0)
    }
  },
  // For amount inputs (money or percent)
  getAnnualDollarsVaried: (frequency, amountObj, percentOf, decimals = 2) => {
    if (!amountObj) return 0
    if (amountObj.type === 'MONEY' && frequency) {
      return _.round(Number(calculations.getAnnualDollars(frequency, amountObj.value)), decimals)
    } else if (amountObj.type === 'PERCENT' && percentOf) {
      return _.round((Number(amountObj.value) / 100) * Number(percentOf), decimals)
    }
    return 0
  },
  // For amount inputs (money or percent)
  getAnnualDollarsVariedString: (frequency, amountObj, percentOf, decimals = 2) => {
    if (amountObj && amountObj.type === 'MONEY' && frequency) {
      return localContext.$utils.moneyDisplay(calculations.getAnnualDollars(frequency, amountObj.value), decimals)
    } else if (amountObj && amountObj.type === 'PERCENT' && percentOf) {
      return localContext.$utils.moneyDisplay((Number(amountObj.value) / 100) * Number(percentOf), decimals)
    }
    return localContext.$utils.moneyDisplay(0, decimals)
  },
  getAge: (dob) => {
    if (dob) {
      const today = localContext.$moment()
      const birthDate = localContext.$moment(dob)
      const years = today.diff(birthDate, 'years')
      const months = today.diff(birthDate, 'months') - years * 12

      if (months > 0) {
        return `${years}y ${months}m`
      } else {
        return `${years}y`
      }
    }
    return ''
  },
  totalHoldings(holdings) {
    let totalValue = 0
    if (holdings) {
      let arrays = Object.values(holdings)
      arrays.forEach((el) => {
        totalValue += localContext.$utils.sumArrayObjs(el, 'value')
      })
    }
    return totalValue
  },
  /**
   * Given an array of decimal numbers representing percentages, adjust them to equal 100%
   * @param {Array<Number>} values
   * @param {Integer} decimals
   * @returns Array of objects { original, rounded, adjusted }
   */
  largestRemainder(values, decimals = 2) {
    if (!values) return []
    let data = []
    let currentSum = 0

    const truncateMultiplier = 10 ** decimals // Use exponentiation to calculate truncateMultiplier
    values.forEach((value, i) => {
      // Determine what the original values would be (rounded) and sum the truncated numbers
      const rounded = _.round(value, decimals)
      const truncatedValue = Math.trunc(value * truncateMultiplier) / truncateMultiplier
      const truncatedDecimals = parseFloat('0.' + _.get((value * truncateMultiplier).toString().split('.'), '[1]', '0'))
      currentSum += parseFloat(truncatedValue)
      data.push({ original: value, rounded: rounded, adjusted: truncatedValue, truncated: truncatedDecimals, index: i })
    })
    currentSum = _.round(currentSum, decimals) // Round so no trailing decimals
    let remainingError = _.round((100 - currentSum) * truncateMultiplier, 0)
    let sortedData = _.reverse(_.sortBy(data, 'truncated')) // Highest decimals first
    const adjustmentValue = 1 / truncateMultiplier // Calculate the adjustment decimal value (e.g. 0.01 for 2 decimals)
    for (let i = 0; i < sortedData.length; i++) {
      if (remainingError <= 0) break
      if (sortedData[i].original === 0) continue
      // Add the adjustmentValue until the sum is 100
      sortedData[i].adjusted = _.round(sortedData[i].adjusted + adjustmentValue, decimals)
      remainingError -= 1
    }
    // Return array of objects with the original value and what the adjusted values would be
    return sortedData
  },
}

export default (context, inject) => {
  localContext = context
  inject('calculations', calculations)
}
