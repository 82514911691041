import FilterStore from './common/filterStore'

const crud = new FilterStore('gantts', null, false, true)

export const state = () => ({
  ...crud.state,
  topBarButtons: [
    { name: 'Gantts', link: '/gantts' },
    { name: 'Template Gantts', link: '/gantts/templates' },
  ],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    return obj?.name
  },
}
export const mutations = {
  ...crud.mutations,
}
export const actions = {
  ...crud.actions,
  async onStompUpdate({ getters, commit, dispatch }, stompProps) {},
  onStompAdd({ getters, commit, dispatch }, stompProps) {
    // Do nothing on stomp add event
  },
}
