export default async function ({ app, route, store, $device, $utils, $bus, redirect, $auth, $faro }) {
  // If not using a desktop PC, show custom page
  if (!$device.isDesktop) {
    if (route.path !== '/mobile' && route.path !== '/management/auth/signIn') redirect('/mobile')
    return
  }
  // If on desktop but trying to show mobile page, redirect
  else if ($device.isDesktop && route.path === '/mobile') {
    redirect('/')
    return
  }

  // Remove the existing beforeResolve hooks before adding our new one
  if (app.router.beforeHooks.length) {
    app.router.beforeHooks = []
  }

  // ROUTE PREVENTION - Prevent navigation while in edit mode
  app.router.beforeEach((to, from, next) => {
    const fromModule = _.get($utils.getRouteParams(from.path), '[0]')
    const editMode = _.get(store.state, `modules.${fromModule}.editMode`, false)
    const autoSave = _.get(store.getters['modules/configurationHub/getUserSettings']($auth.user.sub), 'autoSave', false)

    if (fromModule) {
      // Check if navigation needs to be prevented if the previous route was in edit mode and the path was going to change - allows changing hash/params
      if (autoSave && editMode && to?.path !== from?.path) {
        // Check if inputs are valid before auto save navigating
        let validForm = true
        $bus.$emit('form-validation', (formValidation, customValid) => {
          validForm = formValidation && customValid
        })
        if (!validForm) return false
        // Do the autosave
        $bus.$emit('custom-edit')
      } else if (editMode && to?.path !== from?.path) {
        // emit an event, to indicate to user that they tried to click away from a card in edit mode
        //Remove the first slash from the path
        store.commit('application/appData/setTabNavIntention', { value: to.path.substring(1) })
        $bus.$emit('global/navigationBlocked')
        return false
      }
    }

    if (editMode && autoSave) {
      // If autosaving, little timeout to ensure the module doesn't change too quickly for data to be saved
      setTimeout(() => {
        next()
      }, 200)
    } else {
      // Navigate as normal if not in edit mode
      next()
    }

    const pattern = /(\?.*)|(\/[^\/-]+-[^\/]+$)/;
    // Add Faro API tracking for page views
    $faro.api.pushEvent('page_view', {
      from: from.path,
      to: to.path.replace(pattern, ''),
      user_id: $auth.user.sub
    })
  })
}
