/**
 * Sends a header on request to backend containing flag used in SingleSessionRequestFilter.
 * 
 *  `force-refresh` determines if this client should have a new session created in the backend, if their session has been expired.
 * 
 * force-refresh is set after an auth token refresh or for the first backend request
 */
export default function ({ $axios, $config }) {
  let forceRefresh = true

  $axios.onRequest((config) => {
    // On requests to our backend
    if (forceRefresh && config.url.startsWith('/api/')) {
      config.headers.common = { ...config.headers.common, 'force-refresh': true }
      forceRefresh = false
    }
    
    if (config.url === `https://${$config.authDomain}/oauth/token`) {
      // The next request after a token refresh will have this header attached
      forceRefresh = true
    }
  })
}