import { _ } from 'core-js'
import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('configurationHub', null)

import customLayouts from '../datafiles/customLayouts.json'
export const state = () => ({
  ...crud.state,
  activeConfig: '',
  customLayouts: customLayouts,
  practiceSettings: [
    {
      header: 'Organisation Settings',
      value: 'organisation',
      options: [
        { name: 'Organisation Details', value: 'details' },
        { name: 'Layouts', value: 'layouts' },
        { name: 'Roles', value: 'roles' },
        { name: 'Billing and Users', value: 'billingAndUsers' },
        { name: 'Teams', value: 'teams' },
        { name: 'Tags', value: 'tags' },
        { name: 'Integrations', value: 'integrations' },
      ],
    },
    {
      header: 'User Settings',
      value: 'user',
      lockable: false,
      options: [{ name: 'User Details', value: 'details' }],
    },
    // {
    //   header: 'Integrations',
    //   value: 'integration',
    //   lockable: false,
    //   options: [{ name: 'Xero', value: 'xero' }],
    //   options: [{ name: 'Gmail', value: 'gmail' }],
    // },
  ],

  applicationSettings: [
    {
      header: 'Client Group',
      value: 'clientGroup',
      options: [{ name: 'Segments', value: 'segmentations' }],
    },
    {
      header: 'Dashboard',
      value: 'dashboard',
      options: [{ name: 'Activity Type', value: 'types' }],
    },
    {
      header: 'Entities',
      value: 'entities',
      options: [{ name: 'Risk Profiles', value: 'profiles' }],
    },
    {
      header: 'Goals and Objectives',
      value: 'goals',
      options: [
        { name: 'Category', value: 'category' },
        { name: 'Status', value: 'status' },
        { name: 'Priority', value: 'priority' },
        { name: 'Time Frame', value: 'timeframe' },
      ],
    },
    {
      header: 'File Notes',
      value: 'fileNote',
      options: [{ name: 'File Note Type', value: 'types' }],
    },
    {
      header: 'File Manager',
      value: 'fileManager',
      options: [{ name: 'Default Folder Structure', value: 'default' }],
    },
    {
      header: 'Client Portal',
      value: 'clientPortal',
      options: [{ name: 'Theme', value: 'theme' }],
    },
    {
      header: 'Professionals & Providers',
      value: 'professionals',
      options: [{ name: 'Services', value: 'services' }],
    },
  ],
})

export const getters = {
  ...crud.getters,
  getObject: (state, getters, rootState) => (id) => {
    let found = null
    const allItems = _.cloneDeep(state.allItems)
    let items = _.get(allItems, [state.activeConfig], [])

    if ($nuxt.$route.params?.settings === 'user' && $nuxt.$route.params?.suboption === 'details') {
      items.activeUser = _.cloneDeep(rootState.auxiliary.user.org.users.find((el) => el.user_id === rootState.auth.user.sub))
    }

    if (!_.isNil(id)) found = items.find((el) => el.id === id)
    return !_.isNil(found) ? found : items
  },
  isPending(state) {
    return state.allItems === 'pending'
  },
  getTeams(state) {
    return _.get(state.allItems, 'organisation.teams.items', [])
  },
  getRoles(state) {
    return _.get(state.allItems, 'organisation.roles.items', [])
  },
  getClient(state) {
    return _.get(state.allItems, 'clientGroup', [])
  },
  // get all tags, concatenating specific tags onto the global tags when providing tagKey
  // don't bother sorting so we maintain the config hub order set
  getTags: (state) => (tagKey) => {
    let globalTags = _.cloneDeep(_.get(state.allItems, 'organisation.tags.items', []))
    if (!tagKey || typeof tagKey !== 'string') {
      return globalTags
    } else {
      let tags = null
      Object.values(state?.allItems || {}).map((config) => {
        if (tags) return
        Object.entries(config || {}).map(([k, v]) => {
          if (tags) return
          if (k.toLowerCase() === tagKey.toLowerCase()) tags = _.cloneDeep(_.get(v, 'items', []))
        })
      })

      return !tags || !Array.isArray(tags) || tags.length === 0 ? globalTags : _.union(globalTags, tags)
    }
  },
  // get tag by id
  getTag: (state, getters) => (id) => {
    return _.cloneDeep(_.find(getters.getTags(), { id }))
  },
  getUserSettings: (state) => (userId) => {
    return _.get(state.allItems, `user.userSettings[${userId}]`)
  },
  getSelectedLayout: (state) => {
    return _.get(state.allItems, `organisation.appLayout.selectedLayout`, 'Default')
  },
  getCardLayout: (state) => (page) => {
    return _.get(state.allItems, `organisation.appLayout.cardLayouts[${page}]`, null)
  },
  getModulesLayout(state) {
    let items = _.get(state.allItems, `organisation.appLayout.ultraMenuItems`, null)
    return _.isNil(items) ? state.customLayouts['Default'].ultraMenuItems : items
  },
  getCurrentUserSettings: (state, getters, rootState) => {
    return getters.getUserSettings(rootState?.auth?.user?.sub)
  },
  getSessionSettings: (state) => (userId) => {
    return _.get(state.allItems, `user.userSettings[${userId}].sessionSettings`)
  },
  headerFromValue: (state, getters) => (value) => {
    let found = getters.getAllSettings.find((el) => el.value === value)
    if (found) return found.header
    console.warn('Setting header not found from value:', value)
    return ''
  },
  getConfig: (state) => (type) => {
    return _.get(state.allItems, `[${type}]`, [])
  },
  getAllSettings(state) {
    return state.practiceSettings.concat(state.applicationSettings)
  },
  getDefaultOption: (state, getters) => (routerParams) => {
    let found = getters.getAllSettings.find((el) => el.value === routerParams.settings)
    if (found) return found.options[0].value
  },
  isSettingValid: (state, getters) => (routerParams) => {
    if (!routerParams.settings) return true // Valid if no setting selected, it will show the home page
    return getters.getAllSettings.findIndex((el) => el.value === routerParams.settings) >= 0 ? true : false
  },
  isOptionValid: (state, getters) => (routerParams) => {
    if (!routerParams.settings && !routerParams.suboption) return true // Valid if neither are selected, it will show the home page
    const settings = getters.getAllSettings.find((el) => el.value === routerParams.settings)
    if (!settings) return false
    return settings.options.findIndex((el) => el.value === routerParams.suboption) >= 0 ? true : false
  },
  getItems: (state) => (path) => {
    return _.cloneDeep(_.get(state.allItems, path.endsWith('.items') ? path : path + '.items', []))
  },
  /**
   * Look up any item in config hub by it's id
   *
   * @returns the ConfigTableItem { editable, id, name } else null
   */
  idSearch: (state) => (id, path = undefined, ignoreWarnings = false) => {
    if (!id) return null
    if (state.allItems === 'pending') return null

    if (path) {
      path = path.endsWith('.items') ? path : path + '.items'
      const items = _.get(state.allItems, path, null)
      if (!items && !ignoreWarnings) console.warn('Could not find items at the path: ', path)
      if (!Array.isArray(items)) console.warn('The value at the path are not an array: ', path)
      const value = _.find(items, { id })
      if (!value && !ignoreWarnings) console.warn('modules/configurationHub/idSearch unsuccessful ID lookup: ', id)
      return value
    }

    // Get the first occurence of id
    let value = null
    Object.values(state.allItems).forEach((moduleConfig) => {
      if (!_.isObject(moduleConfig) || value) return

      Object.values(moduleConfig).forEach((configItem) => {
        if (value) return
        const items = _.get(configItem, 'items', [])
        value = _.find(items, { id }) || null
      })
    })

    if (!value && !ignoreWarnings) console.warn('modules/configurationHub/idSearch unsuccessful ID lookup: ', id)
    return value
  },
  getTopBarButtons: (state, getters) => (currentRoute) => {
    if (currentRoute.params.settings) {
      let found = getters.getAllSettings.find((el) => el.value === currentRoute.params.settings)
      if (found && found.options)
        return found.options.map((el) => {
          return { ...el, link: `/configurationHub/${currentRoute.params.settings}/${el.value}` }
        })
    }
    return []
  },
  moduleInLayout: (state, getters) => (mod) => {
    let modulesLayout = _.flatten(_.cloneDeep(getters[`getModulesLayout`]).map((layout) => layout.modules))
    return modulesLayout.includes(mod)
  },
}

export const mutations = {
  ...crud.mutations,
  setActiveConfig(state, val) {
    _.set(state, 'activeConfig', val)
  },
  setCustomLayout(state, { page, cardLayout, ultraMenuItems }) {
    _.set(state.allItems, 'organisation.appLayout.selectedLayout', 'Custom')
    if (!_.isNil(cardLayout)) _.set(state.allItems, `organisation.appLayout.cardLayouts[${page}]`, _.pick(cardLayout, ['left', 'right']))
    if (!_.isNil(ultraMenuItems)) _.set(state.allItems, 'organisation.appLayout.ultraMenuItems', ultraMenuItems)
  },
  setSelectedLayout(state, val) {
    _.set(state.allItems, 'organisation.appLayout.selectedLayout', val)
    //find and set the layouts
    if (val != 'Custom') {
      _.set(state.allItems, 'organisation.appLayout.cardLayouts', _.get(state.customLayouts, val, state.customLayouts['Default']).cardLayouts)
      _.set(state.allItems, 'organisation.appLayout.ultraMenuItems', _.get(state.customLayouts, val, state.customLayouts['Default']).ultraMenuItems)
    }
  },
  updateUserSettingKey(state, { userId, key, val }) {
    _.set(state.allItems, `user.userSettings[${userId}][${key}]`, val)
  },
  updateAllUserSetting(state, { userId, val }) {
    _.set(state.allItems, `user.userSettings[${userId}]`, val)
  },
  updateConfigurationOption(state, { config, option, data }) {
    _.set(state.allItems[config], option, data)
  },
  updateConfiguration(state, { config, data }) {
    state.allItems[config] = data
  },
  updateAllConfigurations(state, data) {
    state.allItems = data
  },
  setPermissions(state, { config, permissions }) {
    _.set(state.allItems, `[${config}].permissions`, permissions)
  },
  addPermission(state, { config, user }) {
    let permissions = _.get(state.allItems, `[${config}].permissions`)
    if (Array.isArray(permissions)) {
      permissions.push(user)
    }
  },
  removePermission(state, { config, user }) {
    let permissions = _.get(state.allItems, `[${config}].permissions`)
    if (Array.isArray(permissions)) {
      let index = permissions.indexOf(user)
      if (index >= 0) permissions.splice(index, 1)
    }
  },
}

export const actions = {
  ...crud.actions,
  async patch({ getters, rootGetters, dispatch }, { id, object }) {
    let existingObj = getters['getObject']()
    const payload = _.mergeWith(_.cloneDeep(existingObj), object, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return srcValue // Overwrite arrays with the new values instead of merging them
      }
    })
    if ($nuxt.$route.params?.settings === 'user' && $nuxt.$route.params?.suboption === 'details') {
      if (payload?.activeUser) {
        dispatch(`auxiliary/user/updateMember`, payload.activeUser, { root: true })
        delete payload.activeUser
      } else {
        console.error('Error updating user details')
      }
    }

    return await dispatch('update', { payload })
  },
  async getInitialConfigs({ state, commit, rootState, getters, dispatch }) {
    // Fetch configs, creates configs that don't exist
    if (state.allItems) commit('application/appData/setLoading', { key: 'settings', value: 'pending' }, { root: true })
    await this.$axios
      .get('/api/v1/configurations')
      .then(async (res) => {
        commit('application/storeUtils/setItem', { localState: state, item: 'allItems', value: res.data }, { root: true })
        commit('application/appData/setLoading', { key: 'settings', value: true }, { root: true })
        const userSub = _.get(rootState, `auth.user.sub`)
        if (!_.isNil(userSub) && _.isEmpty(getters.getUserSettings(userSub), true)) {
          let userSettings = await dispatch('application/storeUtils/getBackendClass', 'UserSettings', { root: true })
          _.set(state.allItems, `user.userSettings[${userSub}]`, userSettings)
          if (state.allItems) this.$axios.put(`/api/v1/configurations/${state.allItems.id}`, state.allItems)
        }
      })
      .catch((e) => {
        console.error(e)
        commit('application/appData/setLoading', { key: 'settings', value: 'failed' }, { root: true })
      })
  },
  async getAll({ state, commit }) {
    // Fetch configs
    if (state.allItems) commit('application/appData/setLoading', { key: 'settings', value: 'pending' }, { root: true })
    await this.$axios.get('/api/v1/configurations').then((res) => {
      commit('application/storeUtils/setItem', { localState: state, item: 'allItems', value: res.data }, { root: true })
    })
  },
  setupSession({ state, commit, getters, dispatch, rootState }) {
    /*
      - If there is a ?group=[id] URL query, use that
      - Next, if there is a selectedEntityGroup saved in sessionStorage, use that
      - Otherwise, use sessionSettings (deprecated)
    */
    let routeGroup = _.get(this.$router, 'currentRoute.query.group')
    let sessionSettings = getters['getSessionSettings'](this.$auth.user.sub)
    if (routeGroup && rootState.modules.entityGroups.allItems.find((el) => el.id === routeGroup)) {
      // Select group from route query
      dispatch('modules/entityGroups/selectEntityGroup', { id: routeGroup }, { root: true })
    } else if (_.has(sessionStorage, 'selectedEntityGroup')) {
      // Select group from sessionStorage, if it is not 'null'
      if (sessionStorage.getItem('selectedEntityGroup') === 'null') {
        commit('application/appData/setLoading', { key: 'groupData', value: true }, { root: true })
      } else {
        dispatch('modules/entityGroups/selectEntityGroup', { id: sessionStorage.getItem('selectedEntityGroup') }, { root: true })
      }
    } else {
      // No group so no need to load
      commit('application/appData/setLoading', { key: 'groupData', value: true }, { root: true })
    }
    if (_.get(sessionSettings, 'openModules', false)) {
      // dispatch('application/appData/setOpenModules', sessionSettings.openModules, { root: true })
    }
  },
  eventUpdate({ dispatch, rootState }, stompMessage) {
    // Re-pull config data on change
    if (stompMessage !== null) {
      let eventName = _.get(stompMessage, 'eventName', '').toLowerCase()
      // Message wasn't from the same tab (different transactionId)
      if (stompMessage.transactionId !== rootState.application.webStomp.transactionId) {
        if (eventName.includes('update')) {
          // Refresh no matter where you are in the app
          // If the config someone else is on changes they will need to refresh
          // TODO - Prompt for refresh if config someone is on changes?
          setTimeout(() => {
            dispatch('getAll')
          }, 2000)
        }
      }
    }
  },
  setLocalEdit({ state, commit, rootState }, cardName) {
    if (!cardName) {
      commit('application/storeUtils/setItem', { localState: state, item: 'localEdit', value: null }, { root: true })
    } else {
      commit('application/storeUtils/setItem', { localState: state, item: 'localEdit', value: { cardName, suboption: this.$router.currentRoute.params.suboption } }, { root: true })
    }
  },
  async patchPermissions({ getters }, configType) {
    let config = getters['getConfig'](configType)
    if (config) await this.$axios.patch(`/api/v1/configurations/permissions/${configType}`, config.permissions)
  },
  triggerSave({ commit, state }) {
    this.$axios.put(`/api/v1/configurations/${state.allItems.id}`, state.allItems)
  },
  update({ commit, state }, { payload, callback, data }) {
    commit('updateConfiguration', { config: this.$router.currentRoute.params.settings, data: payload })
    this.$axios.put(`/api/v1/configurations/${state.allItems.id}`, state.allItems).then((res) => {
      if (callback) data !== null ? callback(res.status, data) : callback(res.status)
    })
  },
  async removeUserFromTeams({ state, getters, commit }, id) {
    let teams = getters['getTeams']

    teams?.forEach((team) => {
      _.remove(team?.teamMembers, (members) => {
        return _.isEqual(members.userId, id)
      })
    })
    commit('updateConfigurationOption', { config: 'organisation', option: 'teams.items', data: teams })
    this.$axios.put(`/api/v1/configurations/${state.allItems.id}`, state.allItems)
  },
}
