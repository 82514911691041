import FilterStore from './common/filterStore'

const crud = new FilterStore('sharedFolders', null, false, true)

const _state = { }

/**
 * @type { import('../../types/store').CrudState & import('../../types/store').FilterState & typeof _state }
 */
export const state = () => ({
  ...crud.state,
  ..._state
})

/**
 * @type {import('../../types/store').Getters<typeof state>}
 */
export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    const split = obj?.prefix.split('/').filter((el) => !!el)
    return split[split.length - 1]
  },
  findShared: (state) => (prefix) => {
    let rootPrefix = $nuxt.$store.state.auxiliary.documents.rootPrefix
    rootPrefix = rootPrefix ? '.'+rootPrefix : rootPrefix
    prefix = [$nuxt.$store.state.auxiliary.user.appMetadata.orgId, rootPrefix, prefix].filter((el) => !!el).join('/')
    return state.allItems.find((el) => el.prefix === prefix) ?? null
  },
}

/**
 * @type { import('../../types/store').Mutations<typeof state> }
 */
export const mutations = {
  ...crud.mutations,
}

/**
 * @type { import('../../types/store').Actions<typeof state> }
 */
export const actions = {
  ...crud.actions
}
