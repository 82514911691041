import CrudStore from '~/store/modules/common/crudStore'

var crud = new CrudStore('liabilities', [
  { name: 'Loan', value: 'LOAN' },
  { name: 'Student Loan', value: 'STUDENT_LOAN' },
])

export const state = () => ({
  ...crud.state,
  blankBalance: null,
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName

    const providerProduct = $nuxt.$utils.concatStrings(' ', obj.summary?.provider, obj.summary?.product)
    if (!providerProduct && !obj.summary?.description) return defaultName
    return $nuxt.$utils.concatStrings(' - ', providerProduct, obj.summary?.description)
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    const liability = getters.getObject(id)
    if (!liability) return ''

    return $nuxt.$utils.concatStrings(' - ', $nuxt.$utils.getOwnershipString(liability.summary?.ownership), liability.summary.type)
  },
  // Get the array of liabilities for use within a link-input
  getConcat: (state, getters, rootState) => ($utils) => {
    const liabilities = _.cloneDeep(rootState.modules.entityGroups.selectedEntityGroup.liabilities)

    return !Array.isArray(liabilities)
      ? []
      : liabilities.map((liability) => {
          const owners = _.cloneDeep(liability.ownership.items)
            .map((ownershipItem) => ($utils.isUUID(ownershipItem.name) ? $utils.getDisplayName(ownershipItem.name) : ownershipItem.name))
            .join(', ')

          const subType = ['MORTGAGE', 'PERSONAL_LOAN'].includes(liability.type) ? (liability.personalLoanType ? liability.personalLoanType : liability.mortgageType) : null

          return {
            ...liability,
            name: owners ? owners : subType ? $utils.capitaliseWords(subType) : liability.providerId ? $utils.getDisplayName(liability.providerId) : $utils.capitaliseWords(liability.type),
          }
        })
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
  updateLinks({ commit, dispatch, rootGetters }, { payload, old }) {
    // Links removed via the update
    const removed = _.difference(old.balance.offsetAccounts, payload.balance.offsetAccounts)
    dispatch('removeLinkedOffsetAccounts', { loanId: payload.id, offsetIds: removed })

    // Assets referenced as offset accounts
    const offsetAccounts = rootGetters['modules/assets/getItems'].filter((el) => payload.balance.offsetAccounts.includes(el.id))

    // Update loans, add the asset ID as an offset account
    offsetAccounts.forEach((account) => {
      if (Array.isArray(account?.summary?.linkedLoan) && !account.summary.linkedLoan.includes(payload.id)) {
        let clone = _.cloneDeep(account)
        clone.summary.linkedLoan.push(payload.id)
        commit(`modules/assets/updateObject`, clone, { root: true })
        this.$axios.put(`/api/v1/assets/` + clone.id, clone)
      }
    })
  },
  removeLinks({ getters, dispatch }, ids) {
    // Remove links when loan is deleted
    const loans = getters['getItems'].filter((el) => ids.includes(el.id))
    loans.forEach((loan) => {
      dispatch('removeLinkedOffsetAccounts', { loanId: loan.id, offsetIds: loan?.balance?.offsetAccounts })
    })
  },
  removeLinkedOffsetAccounts({ commit, rootGetters }, { loanId, offsetIds }) {
    if (!loanId || !Array.isArray(offsetIds) || offsetIds.length === 0) return

    // Offset accounts referenced by the loan
    const offsetAccounts = rootGetters['modules/assets/getItems'].filter((el) => offsetIds.includes(el.id))
    offsetAccounts.forEach((account) => {
      if (Array.isArray(account?.summary?.linkedLoan) && account.summary.linkedLoan.includes(loanId)) {
        let clone = _.cloneDeep(account)
        const index = clone.summary.linkedLoan.findIndex((id) => id === loanId)
        if (index >= 0) {
          clone.summary.linkedLoan.splice(index, 1)
        }
        commit(`modules/assets/updateObject`, clone, { root: true })
        this.$axios.put(`/api/v1/assets/` + clone.id, clone)
      }
    })
  },
}
