import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('entities', [
  {
    name: 'Individual',
    value: 'INDIVIDUAL',
    class: 'Individual',
    sub: [],
  },
  {
    name: 'Trust',
    value: 'TRUST',
    class: 'Trust',
    sub: [],
  },
  {
    name: 'SMSF',
    value: 'SMSF',
    class: 'SMSF',
    sub: [],
  },
  {
    name: 'Company',
    value: 'COMPANY',
    class: 'Company',
    sub: [],
  },
  {
    name: 'Partnership',
    value: 'PARTNERSHIP',
    class: 'Partnership',
  },
])

export const state = () => ({
  ...crud.state,
  extraOptions: [{ name: 'Education', value: 'EDUCATION' }],
  selectedExtra: [],
  taxResidencyTypes: [
    { name: 'Tax Resident', value: 'TAX_RESIDENT' },
    { name: 'Foreign Resident', value: 'FOREIGN_RESIDENT' },
  ],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName

    const summary = obj?.summary
    if (!summary) return defaultName

    switch (obj.type) {
      case 'INDIVIDUAL':
        // Use preferred name as first name if it exists
        const firstName = summary.preferredName ? summary.preferredName : summary.firstName
        return $nuxt.$utils.concatStrings(' ', firstName, summary.lastName) || defaultName
      default:
        return obj?.summary?.name || defaultName
    }
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    const entity = getters.getObject(id)
    if (!entity) return ''

    // Return the subtype if it exists, otherwise main type
    return $nuxt.$utils.capitaliseWords(entity.summary?.type) || $nuxt.$utils.capitaliseWords(entity.type)
  },
  getEntity: (state) => (entityId) => {
    let found = state.allItems.find((el) => el.id === entityId)
    return found ? `${found.firstName} ${found.lastName}` : '--'
  },
}

export const mutations = {
  ...crud.mutations,
  setSelectedExtra(state, type) {
    // Type can be the whole object (given when first selecting) or the data when a field is updated
    state.selectedExtra = []
    if (!type) state.selectedExtra = []
    else if (type.subType) {
      let typeLookup = type.subType
      if (type.subType == 'CHILD') typeLookup = 'EDUCATION'
      let found = state.extraOptions.find((el) => el.value === typeLookup)
      if (found) state.selectedExtra = [found]
    } else {
      let found = state.extraOptions.find((el) => el.value === type)
      if (found) state.selectedExtra = [found]
    }
  },
}

export const actions = {
  ...crud.actions,
  updateLinks({ getters, commit }, { payload, old }) {
    const relationships = _.get(payload, 'rolesRelationships.roleRelationshipList', [])
    const oldRelationships = _.get(old, 'rolesRelationships.roleRelationshipList', [])
    const opposingRolesMap = {
      Parent: 'Child',
      Child: 'Parent',
      Spouse: 'Spouse',
      Friend: 'Friend',
    }

    const invertRoles = (roles) => {
      if (!Array.isArray(roles)) return []
      return roles
        .map((role) => {
          for (const [k, v] of Object.entries(opposingRolesMap)) {
            if (k === role) return v
            else if (v === role) return k
          }
          return null
        })
        .filter((el) => el !== null)
    }

    const updateObject = (entity) => {
      // Send request straight away so we don't call this same method again from the dispatch
      commit(`updateObject`, entity)
      this.$axios.put(`/api/v1/entities/` + entity.id, entity)
    }

    // Sort relationships into added/removed/updated
    const addedRelationships = _.differenceBy(relationships, oldRelationships, 'id')
    const removedRelationships = _.differenceBy(oldRelationships, relationships, 'id')
    const updatedRelationships = relationships.filter((el) => !addedRelationships.map((rel) => rel.id).includes(el.id) && !removedRelationships.map((rel) => rel.id).includes(el.id))

    addedRelationships.forEach((relationship) => {
      /**
       * Added
       */
      if (invertRoles(relationship.roles).length === 0) return // Do nothing if no roles need to be added
      let toUpdate = _.cloneDeep(getters['getObject'](relationship.id))
      toUpdate.rolesRelationships.roleRelationshipList.push({ id: payload.id, roles: invertRoles(relationship.roles) })
      updateObject(toUpdate)
    })

    removedRelationships.forEach((relationship) => {
      /**
       * Removed
       */
      let toUpdate = _.cloneDeep(getters['getObject'](relationship.id))
      let linkedRole = toUpdate.rolesRelationships.roleRelationshipList.find((el) => el.id === payload.id)
      if (linkedRole) {
        linkedRole.roles = linkedRole.roles.filter((role) => !invertRoles(relationship.roles).includes(role))
        if (linkedRole.roles.length === 0) {
          // Only delete the role if no other roles exist after taking away the linked ones
          _.remove(toUpdate.rolesRelationships.roleRelationshipList, (el) => el.id === linkedRole.id)
        } else {
          // Remove linked roles
          const index = _.findIndex(toUpdate.rolesRelationships.roleRelationshipList, (el) => el.id === linkedRole.id)
          toUpdate.rolesRelationships.roleRelationshipList.splice(index, 1, linkedRole)
        }
        updateObject(toUpdate)
      }
    })

    updatedRelationships.forEach((relationship) => {
      /**
       * Updated / Unmodified
       */
      let toUpdate = _.cloneDeep(getters['getObject'](relationship.id))
      let linkedRole = toUpdate.rolesRelationships.roleRelationshipList.find((el) => el.id === payload.id)
      if (linkedRole) {
        // Add roles to existing relationship listing
        const linkRoles = _.remove(linkedRole.roles, (role) => Object.keys(opposingRolesMap).includes(role))
        if (_.isEqual(invertRoles(relationship.roles), linkRoles)) return // Roles already exist, do nothing
        linkedRole.roles = _.union(invertRoles(relationship.roles), linkedRole.roles)

        const index = _.findIndex(toUpdate.rolesRelationships.roleRelationshipList, (el) => el.id === linkedRole.id)
        toUpdate.rolesRelationships.roleRelationshipList.splice(index, 1, linkedRole)
      } else {
        // Role wasn't found so add it
        toUpdate.rolesRelationships.roleRelationshipList.push({ id: payload.id, roles: invertRoles(relationship.roles) })
      }
      updateObject(toUpdate)
    })
  },
  removeLinks({ getters, commit }, ids) {
    const entities = getters['getItems'].filter((el) => ids.includes(el.id))
    entities.forEach((entity) => {
      // Check each entity from the relationships to see if they were linked, if they are remove the link
      entity.rolesRelationships.roleRelationshipList.forEach((relationship) => {
        let linkedEntity = _.cloneDeep(getters['getObject'](relationship.id))
        if (!linkedEntity) return
        const filtered = linkedEntity.rolesRelationships.roleRelationshipList.filter((el) => el.id !== entity.id)
        // If the relationships are different after filtering, save the entity with the changes
        if (!_.isEqual(linkedEntity.rolesRelationships.roleRelationshipList, filtered)) {
          linkedEntity.rolesRelationships.roleRelationshipList = filtered
          commit(`updateObject`, linkedEntity)
          this.$axios.put(`/api/v1/entities/` + linkedEntity.id, linkedEntity)
        }
      })
    })
  },
}
