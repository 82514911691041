import FilterStore from './common/filterStore'
import _ from 'lodash'

const crud = new FilterStore('emails', null, false, true)

export const state = () => ({
  ...crud.state,
  topBarButtons: [
    { name: 'Emails', link: '/emails' },
    { name: 'Template Emails', link: '/emails/templates' },
  ],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    return obj?.name
  },
  getItems(state, getters, rootState) {
    let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
    return _.sortBy(items, (el) => $nuxt.$utils.getDisplayName(el?.id)?.toLowerCase()) // Sort the items by default
  }
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
}