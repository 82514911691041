export const state = () => ({
  connected: false,
  code: null,
  secret: null,
  shortCode: undefined, // Organisation short code for deep linking

  currencyItems: ['AUD', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYN', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EEK', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MRU', 'MUR', 'MVR', 'MWK', 'MXN', 'MXV', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SKK', 'SLE', 'SLL', 'SOS', 'SRD', 'STD', 'STN', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'UYU', 'UZS', 'VEF', 'VES', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMW', 'ZMK', 'ZWD'],
  taxOptions: [
    { name: 'Tax exclusive', value: 'Exclusive' },
    { name: 'Tax inclusive', value: 'Inclusive' },
    { name: 'No tax', value: 'NoTax' },
  ],

  lineItems: undefined,
  accounts: undefined,
  brandingThemes: undefined,
  taxRates: undefined,

  contacts: undefined,
  invoices: undefined,
})

export const getters = {
  getItem: (state) => ({ type, idKey, id }) => {
    if (!state.connected) return null
    const items = _.get(state, type, [])
    if (!Array.isArray(items)) return null
    return items.find((el) => el[idKey] === id)
  },
  getShortCode(state) {
    return state.shortCode
  },
  getXeroInvoiceLink: (state) => (invoiceId) => {
    if (!state.shortCode) return null
    return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${state.shortCode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${invoiceId}`
  },
  isConnected(state) {
    return state.connected
  },
  getInvoices(state) {
    if (!Array.isArray(state.invoices)) return []
    return state.invoices.filter((el) => !['DELETED'].includes(el.Status)) // Return active invoices
  },
  getInvoice: (state, getters) => (invoiceId) => {
    if (!Array.isArray(state.invoices)) return null
    return getters['getInvoices'].find((el) => el.InvoiceID === invoiceId)
  },
  getContacts(state) {
    if (!Array.isArray(state.contacts)) return []
    return state.contacts
  },
  getMappedContacts(state) {
    if (!Array.isArray(state.contacts)) return []
    return state.contacts.map((el) => {
      return { name: el.Name, value: el.ContactID }
    })
  },
  getContact: (state) => (contactId) => {
    if (!Array.isArray(state.contacts)) return null
    return state.contacts.find((el) => el.ContactID === contactId)
  },
  getBrandingThemes(state) {
    if (!Array.isArray(state.brandingThemes)) return []
    return state.brandingThemes.map((el) => {
      return { name: el.Name, value: el.BrandingThemeID }
    })
  },
  getCurrencies(state) {
    if (!Array.isArray(state.currencyItems)) return []
    return state.currencyItems.map((el) => {
      return { name: el, value: el }
    })
  },
  getTaxOptions(state) {
    if (!Array.isArray(state.taxOptions)) return []
    return state.taxOptions
  },
  getAccounts(state) {
    if (!Array.isArray(state.accounts)) return []
    return _.sortBy(state.accounts, ['Code'])
      .filter((el) => el.Type !== 'BANK') // Filter out Bank Accounts
      .map((el) => {
        return { name: `${el.Code} - ${el.Name}`, value: el.Code }
      })
  },
  getLineItemCodes(state) {
    if (!Array.isArray(state.lineItems)) return []
    return state.lineItems
      .map((el) => {
        return { name: el.Name, value: el.Code }
      })
      .filter((el) => !!el.name)
  },
  getTaxRates(state) {
    if (!Array.isArray(state.taxRates)) return []
    return state.taxRates
      .map((el) => {
        return { name: el.Name, value: el.TaxType, rate: el.EffectiveRate }
      })
      .concat([{ name: 'N/A', value: 'NONE', rate: 0 }])
  },
  getAccount: (state) => (accountCode) => {
    if (!Array.isArray(state.accounts)) return null
    return state.accounts.find((el) => el.Code === accountCode)
  },
  getLineItemFromCode: (state) => (code) => {
    if (!Array.isArray(state.lineItems)) return null
    return state.lineItems.find((el) => el.Code === code)
  },
}

export const mutations = {
  setItems(state, { type, items }) {
    _.set(state, type, items)
  },
  setConnected(state, connected) {
    state.connected = connected
  },
  updateItems(state, { type, idKey, items }) {
    if (!items) return
    if (!Array.isArray(items)) items = [items]
    const stateItems = _.get(state, type, [])
    items.forEach((item) => {
      const index = stateItems.findIndex((el) => {
        return el[idKey] === item[idKey]
      })
      if (index >= 0) _.set(state, `[${type}][${index}]`, item)
    })
  },
  addItems(state, { type, idKey, items }) {
    if (!items) return
    if (!Array.isArray(items)) items = [items]
    items.forEach((item) => {
      const stateItems = _.get(state, type, [])
      const index = stateItems.findIndex((el) => el[idKey] === item[idKey])
      if (index < 0) state[type].push(item) // Not in items already so add it
    })
  },
  removeItems(state, { type, idKey, ids }) {
    if (!ids) return
    if (!Array.isArray(ids)) ids = [ids]
    _.remove(state[type], (el) => {
      ids.includes(el[idKey])
    })
  },
  setState(state, { code, secret, returnUrl }) {
    state.code = code
    state.secret = secret
    state.returnUrl = returnUrl
  },
}

export const actions = {
  async getLoginUrl({}, returnUrl) {
    localStorage.setItem('xeroReturnUrl', returnUrl)
    return await this.$axios.$get(`/api/v1/xero/auth/url?returnUrl=${returnUrl}`)
  },
  logout() {
    this.$axios.get(`/api/v1/xero/auth/token/logout`)
  },
  async getAuthToken({ state }) {
    const returnUrl = localStorage.getItem('xeroReturnUrl')
    // Sets token cookies
    return await this.$axios
      .$get(`/api/v1/xero/auth/token?state=${state.secret}&code=${state.code}&returnUrl=${returnUrl}`)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  },
  async checkToken({ commit }) {
    // Checks if the stored token is valid
    const validToken = await this.$axios.$get(`/api/v1/xero/auth/token/check`)
    commit('setConnected', validToken)
    return validToken
  },
  async fetchMappedInvoice({ commit }, invoiceId) {
    return await this.$axios.$get(`/api/v1/xero/accounting/invoice/mapped/${invoiceId}`).catch((err) => {
      if (_.get(err, 'response.data', '').includes('404 Not Found')) {
        commit('application/snack/set', { type: 'error', message: 'Invoice could not be found' }, { root: true })
      }
      return null
    })
  },
  createFetchPromise({ state, commit }, { endpoint, stateKey }) {
    // Only fetch if undefined, otherwise we've already tried to fetch it
    if (state[stateKey] !== undefined) return true
    return new Promise((resolve, reject) =>
      this.$axios
        .$get(endpoint)
        .then((res) => {
          if (res) {
            commit('setItems', { type: stateKey, items: res })
            resolve(res)
          } else {
            console.error('Failed to fetch Xero: ' + endpoint)
            console.error(res)
            resolve(false)
          }
        })
        .catch((err) => {
          const data = err?.response?.data
          if (data && data.includes('429')) {
            this.$store.commit('application/snack/set', { type: 'error', message: '429 Too Many Requests' })
          } else {
            this.$store.commit('application/snack/set', { type: 'error', message: 'Failed to fetch Xero data' })
          }
          reject(err)
        })
    )
  },
  async fetchInvoices({ dispatch }) {
    return await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/accounting/invoices`, stateKey: 'invoices' }).then((res) => res)
  },
  async fetchData({ dispatch }) {
    // Data used for Invoices
    let promises = []
    promises.push(await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/accounting/invoices`, stateKey: 'invoices' }))
    promises.push(await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/branding`, stateKey: 'brandingThemes' }))
    promises.push(await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/contact`, stateKey: 'contacts' }))
    promises.push(await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/taxRates`, stateKey: 'taxRates' }))
    promises.push(await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/accounting/accounts`, stateKey: 'accounts' }))
    promises.push(await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/accounting/items`, stateKey: 'lineItems' }))
    promises.push(await dispatch('createFetchPromise', { endpoint: `/api/v1/xero/organisation/shortCode`, stateKey: 'shortCode' }))
    let res = await Promise.all(promises)
    return res
  },
  /***********
   * Invoices
   ***********/
  async updateInvoice({ getters, commit }, { id, invoice, publish }) {
    // Default quantity and amounts to 0 if none were input so that Xero doesn't change them
    invoice.invoiceItems.forEach((item) => {
      if (!item.quantity) item.quantity = 0
      if (!item.unitAmount) item.unitAmount = 0
    })
    return await this.$axios
      .$post(`/api/v1/xero/accounting/invoices/update/${id}`, invoice, {
        params: {
          publish: publish,
        },
      })
      .then((res) => {
        if (res) {
          // Add Invoice and Contact to store
          commit('updateItems', { type: 'invoices', idKey: 'InvoiceID', items: res })
          const contactId = _.get(res, 'Contact.ContactID')
          if (contactId && !getters[`getContact`](contactId)) {
            commit('addItems', { type: 'contacts', idKey: 'ContactID', items: res.Contact })
          }
          return res
        }
      })
      .catch((err) => {
        const resData = _.get(err, 'response.data')
        if (Array.isArray(resData) && resData.length > 0) {
          // Validation errors
          console.error('updateInvoice - ValidationErrors:', resData)
          commit('application/snack/set', { type: 'error', message: resData }, { root: true })
        }
      })
  },
  async addInvoice({ getters, commit }, { invoice, publish, parentId }) {
    // Default quantity and amounts to 0 if none were input so that Xero doesn't change them
    invoice.invoiceItems.forEach((item) => {
      if (!item.quantity) item.quantity = 0
      if (!item.unitAmount) item.unitAmount = 0
    })
    return await this.$axios
      .$post(`/api/v1/xero/accounting/invoice`, invoice, {
        params: {
          publish: publish,
          parentId: parentId,
        },
      })
      .then((res) => {
        if (res) {
          // Add Invoice and Contact to store
          commit('addItems', { type: 'invoices', idKey: 'InvoiceID', items: res })
          const contactId = _.get(res, 'Contact.ContactID')
          if (contactId && !getters[`getContact`](contactId)) {
            commit('addItems', { type: 'contacts', idKey: 'ContactID', items: res.Contact })
          }
          return res
        }
      })
      .catch((err) => {
        const resData = _.get(err, 'response.data')
        if (Array.isArray(resData) && resData.length > 0) {
          // Validation errors
          console.error('addInvoice - ValidationErrors:', resData)
          commit('application/snack/set', { type: 'error', message: resData }, { root: true })
        }
      })
  },
  async fetchInvoicePdf({}, id) {
    return await this.$axios.$get(`api/v1/xero/accounting/invoice/preview/${id}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
  },
  async fetchInvoiceUrl({}, id) {
    return await this.$axios.$get(`api/v1/xero/accounting/invoice/${id}/url`)
  },
  async deleteInvoice({ commit }, id) {
    return await this.$axios.delete(`api/v1/xero/accounting/invoice/${id}`).then((res) => {
      if (res?.status == 200) {
        commit('removeItems', { type: 'invoices', idKey: 'InvoiceID', ids: id })
        return true
      }
      return false
    })
  },
  /***********
   * Contacts
   ***********/
  async addContact({ commit }, individual) {
    await this.$axios.$post(`/api/v1/xero/contact`, individual).then((res) => {
      // if (res) commit('addItems', { type: 'contacts', idKey: 'ContactId', items: res })
    })
  },
  async getContacts({ commit }) {
    await this.$axios.$get(`/api/v1/xero/contact`).then((res) => {
      if (res) commit('setItems', { type: 'contacts', idKey: 'ContactId', items: res })
    })
  },
}
