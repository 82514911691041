import CheckboxOn from '~/components/shared/icons/CheckboxOn'
import CheckboxOff from '~/components/shared/icons/CheckboxOff'
import CheckboxMinus from '~/components/shared/icons/CheckboxMinus'
import Chevron from '~/components/shared/icons/Chevron'
import Close from '~/components/shared/icons/Close'

export default {
  breakpoint: {
    scrollBarWidth: 16,
  },
  defaultAssets: { icons: 'mdi' },
  font: {
    family: 'Lato',
  },
  icons: {
    values: {
      checkboxOn: {
        component: CheckboxOn,
      },
      checkboxOff: {
        component: CheckboxOff,
      },
      checkboxMinus: {
        component: CheckboxMinus,
      },
      dropdown: {
        component: Chevron,
      },
      clear: {
        component: Close,
      },
    },
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: '#374B63',
        secondary: {
          base: '#E3E3E3',
          darken1: '#cbcbcb',
        },
        accent: '#414244', // Used for Section Dividers
        error: '#D23232',
        info: '#C8981C',
        success: '#579B32',
        warning: '#FFC107',
        header: '#374B63',
        background: '#E2E2E2',
        tabBackground: '#2E4055',
        card: {
          base: '#EFEFEF',
          lighten1: '#F6F6F6',
          darken1: '#e3e3e3',
        },
        cardDark: '#2C2C2C',
        cardLight: '#393B3B',
        cardLabel: '#707070',
        invert: '#FFFFFF',
        expansion: '#E3E3E3',
        expansionOpacity: '#f2f2f2',
        tableRow: '#F6F6F6',
        border: {
          base: '#D3D3D3',
          lighten5: '#d8eaff',
          lighten4: '#bccee6',
          lighten3: '#a1b3ca',
          lighten2: '#8698ae',
          lighten1: '#6d7e94',
          darken1: '#3c4d61',
          darken2: '#253649',
          darken3: '#0e2133',
          darken4: '#000a1e',
        },
        highlight: '#5A7CA6',
        shadow: '#C3D5EA',
        list: '#B9CADF',
        tableHeaders: '#5C7798',
        divider: '#C8C8C8',
        text: {
          base: '#292929',
          lighten5: '#a2a2a2',
          lighten4: '#888888',
          lighten3: '#6e6e6e',
          lighten2: '#565656',
          lighten1: '#3f3f3f',
          darken1: '#444',
          darken2: '#333',
          darken3: '#111',
          darken4: '#111',
        },
        searchBorder: {
          base: '#9B9B9B',
          darken1: '#5B5B5B',
        },
        //Workflow stuff
        dfBackground: '#ADADAD',
        dfSinkNode: '#2E4055',
        dfCardBackground: '#F6F6F6',
        hyperlink: '#1A6AB5',
        descriptionText: '#8d8d8d',
      },
      dark: {
        primary: '#7CBE58',
        secondary: '#393B3C',
        accent: '#414244', // Used for Section Dividers
        error: '#D23232',
        info: '#C8981C',
        success: '#79BA54',
        warning: '#FFC107',
        header: '#333435',
        background: '#242526',
        tabBackground: '#292A2B',
        card: '#303233',
        cardDark: '#2C2C2C',
        cardLight: '#393B3B',
        cardLabel: '#979899',
        invert: '#979899',
        expansion: '#303233',
        expansionOpacity: '#4a4d4f',
        tableRow: '#434343',
        border: '#3E3E3F',
        highlight: '#484848',
        shadow: '#242526',
        list: '#939393',
        tableHeaders: '#555555',
        divider: '#5a5c5e',
        text: {
          base: '#ffffff',
          invert: '#292929',
          lighten5: '#ffffff',
          lighten4: '#ffffff',
          lighten3: '#ffffff',
          lighten2: '#ffffff',
          lighten1: '#ffffff',
          darken1: '#e2e2e2',
          darken2: '#333',
          darken3: '#191919',
          darken4: '#111',
        },
        searchBorder: '#3E3E3F',
        //Workflow stuff
        dfBackground: '#1B1B1C',
        dfSinkNode: '#FFFFFF',
        dfCardBackground: '#2E2F30',
        hyperlink: '#1A6AB5',
      },
    },
  },
}
