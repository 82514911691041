
import configPermissionMixin from '~/mixins/configPermissionMixin'
export default {
  props: ['attach'],
  mixins: [configPermissionMixin],
  computed: {
    version() {
      return `v${this.$config.version} (${this.$config.compLibVersion})`
    },
    user() {
      return this.$store.getters['auxiliary/user/getCurrentUser']
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auxiliary/user/logout')
    },
  },
}
