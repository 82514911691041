export default function ({ $auth, $config, $sentry, route, redirect, store, $faro }) {
  let exclude = ['management-auth-signIn', 'management-auth-signOut', 'validate']

  if (!$auth.loggedIn && !exclude.includes(route.name)) {
    const message = sessionStorage.getItem('auth0Message') || ''
    sessionStorage.removeItem('auth0Message')

    $faro.api.resetUser()

    $auth.loginWith('auth0', { params: { message: typeof message === 'string' ? message : '' } }).then(() => redirect('/'))
  } else if ($auth.loggedIn) {

    const user = {
      id: $auth.user.sub,  
      email: $auth.user.email, 
      username: `${$auth?.user?.given_name ? $auth?.user?.given_name + ' ' : ''}${$auth?.user?.family_name ? $auth?.user?.family_name : ''}`,  
    };
    
    $faro.api.setUser(user);

    $faro.api.pushEvent('mainspa_mount', {
      last_seen: new Date(),
      user_id: $auth.user.sub,
      email: $auth.user.email,
      username: `${$auth?.user?.given_name ? $auth?.user?.given_name + ' ' : ''}${$auth?.user?.family_name ? $auth?.user?.family_name : ''}`,  
    })

    $sentry.setUser($auth.user)
  }

  $auth.onError((error, name, endpoint) => {
    console.log('AUTH ERROR: ', error, name, endpoint)
    $sentry.captureException(new Error(error))
    store.dispatch('auxiliary/user/logout')
  })
}
