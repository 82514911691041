let localContext

let initialised = false
let modules = []
let restoreObj = null

export const cache = {
  data: null,
  getObjData: null,
  getLocalData: {},

  init() {
    // Only ever perform setup once so that we do not add extra event listeners
    if (initialised) return

    if (!localContext.store.state.modules.entityGroups.selectedEntityGroup && this.data && this.data.entityGroupId) {
      // If a group ID was cached select the group
      localContext.store.dispatch(`modules/entityGroups/selectEntityGroup`, { id: this.data.entityGroupId })
    }

    //TODO RESTORE DATA DISABLED
    return

    
    modules = localContext.store.getters['application/appData/getModuleValues']
    Object.keys(localStorage).find((key) => {
      // If localStorage contains a module as a key, retrieve that data
      if (modules.includes(key)) {
        try {
          this.data = JSON.parse(localStorage.getItem(key))
        } catch (e) {
          console.warn('Could not parse local JSON data', e)
        }
      }
    })

    // Storing data on page refresh/close
    window.addEventListener('beforeunload', () => {
      let module = localContext.route.params.module
      if (module && localContext.$db.editInProgress()) {
        // Set key as module, save data as object
        localStorage.setItem(module, JSON.stringify(this.getStateObject()))
      }
    })
    initialised = true
  },

  async restoreData() {
    // Snapshot to revert back to if restoration fails
    let initialState = _.cloneDeep(this.getStateObject())
    let restoreSnapshot = _.cloneDeep(this.data)

    // Return to route
    localContext.app.router.push({ path: this.data.route.path })

    let restored = await this.restore(this.data)
    if (restored) {
      // Successfully restored data
      this.clearCache()
    } else {
      // Restore failed, return to snapshot
      this.data = restoreSnapshot
      if (restoreSnapshot.store?.newEdit) localContext.store.dispatch(`modules/${restoreObj.selectedApp ? `${restoreObj.selectedApp}/` : ''}${restoreSnapshot.route.params.module}/cancelNew`)
      await this.restore(initialState)
    }
    return restored
  },

  async restore(restore) {
    let restored = false
    restoreObj = restore // Set as a local variable so that the callback can use it
    if (!restoreObj) {
      console.warn('Object to restore was null', restoreObj)
      return false
    }

    // Try restoring the data X times, if it is restored earlier it breaks the loop
    for (let i = 0; i < 20; i++) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      if (restoreObj.storedData) {
        // Restore stored panel data
        localContext.$db.storedFormData = restoreObj.storedData.data
        localContext.$db.storedErrors = restoreObj.storedData.errors
        restoreObj.storedData = null
      }
      if (restoreObj.store) {
        // Restore store data
        localContext.store.commit(`modules/${restoreObj.selectedApp ? `${restoreObj.selectedApp}/` : ''}${restoreObj.route.params.module}/restoreState`, restoreObj.store)

        // If there was a new edit add it to the module data
        if (restoreObj.store?.newEdit) {
          if (restoreObj.store.org) localContext.store.commit(`modules/${restoreObj.selectedApp ? `${restoreObj.selectedApp}/` : ''}${restoreObj.route.params.module}/addNewObj`, restoreObj.editObj)
          else localContext.store.dispatch('modules/entityGroups/modifySelectedGroup', { module: restoreObj.route.params.module, item: restoreObj.editObj, action: 'add' })
        }
        restoreObj.store = null
      }

      // Emit and restore data after a short timeout to ensure components have mounted for the events
      if (restoreObj.editObj) localContext.$bus.$emit('restoreData', { data: _.cloneDeep(restoreObj.editObj), callback: this.callback })
      if (restoreObj.localData && Object.keys(restoreObj.localData).length > 0) {
        for (const [key, value] of Object.entries(restoreObj.localData)) {
          localContext.$bus.$emit(key, { data: value, callback: this.callback })
        }
      }

      if (restoreObj.editObj === null && Object.keys(restoreObj.localData).length === 0) {
        // All callbacks returned, no need to loop any more
        restored = true
        break
      }
    }
    return restored
  },

  clearCache() {
    // Remove all cached modules
    modules.forEach((module) => {
      localStorage.removeItem(module)
    })
    restoreObj = null
  },

  callback(eventName) {
    // After events restore data, remove them from the object so they don't get called again
    if (eventName === 'restoreData') restoreObj.editObj = null
    else if (restoreObj.localData && Object.keys(restoreObj.localData).includes(eventName)) {
      delete restoreObj.localData[eventName]
    }
  },

  getStateObject() {
    let module = localContext.route.params.module
    if (module) {
      // Get object data via function
      let objData = null
      if (this.getObjData && typeof this.getObjData === 'function') {
        objData = this.getObjData()
      }
      // Get local component data via functions
      let localData = {}
      for (const [key, value] of Object.entries(this.getLocalData)) {
        if (typeof value === 'function') localData[key] = value()
      }

      const app = localContext.store.getters['application/appData/getAppValueFromModule'](module)

      // Unpack store variables we don't want to store in the cache
      let { org, staleData, items, allItems, panelGroupField, endpoint, subOptions, typeSelections, ...storeCache } = localContext.store.state.modules.crm[module]

      return {
        editObj: objData,
        groupId: localContext.store.state.modules.entityGroups.selectedEntityGroup ? localContext.store.state.modules.entityGroups.selectedEntityGroup.id : null,
        storedData: { data: localContext.$db.storedFormData, errors: localContext.$db.storedErrors },
        localData: localData,
        store: storeCache,
        route: { params: localContext.route.params, path: localContext.route.path },
        selectedApp: app,
      }
    }
  },
}

export default (context, inject) => {
  localContext = context
  inject('cache', cache)
}
