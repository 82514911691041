import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'

let localContext
let client
export const webStomp = {
  initSocket: () => {
    localContext.store.commit('application/appData/setLoading', { key: 'websocket', value: 'pending' }, { root: true })
    // Check browser support for websockets and if they dont, use sockjs.
    // Sockjs may not have reliable reconnect but will work similarly otherwise
    if ('WebSocket' in window) {
      client.brokerURL = `${localContext.$config.dev != 'local' ? 'wss' : 'ws'}://${localContext.$config.backendHostname}:8081/ws`
    } else {
      client.webSocketFactory = function () {
        return new SockJS(`${localContext.$config.dev != 'local' ? 'https' : 'http'}://${localContext.$config.backendHostname}:8081/ws`)
      }
    }

    client.onConnect = (frame) => {
      localContext.store.commit('application/appData/setLoading', { key: 'websocket', value: true }, { root: true })
      localContext.store.commit('application/appData/updateServerConnection', true)

      // Server message global subscription
      client.subscribe(`/topic/serverMessage`, () => {
        localContext.store.dispatch(localContext.store.state.application.webStomp.subscriptions['serverMessage'])
      })

      Object.keys(localContext.store.state.application.webStomp.subscriptions).forEach((sub) => {
        client.subscribe(`/topic/${localContext.store.state.auxiliary.user.appMetadata?.orgId}/${sub}`, (msg) => {
          let webstompMsg = JSON.parse(msg.body)
          // Allow stomp events from others and yourself, manage them in the store
          localContext.store.dispatch(localContext.store.state.application.webStomp.subscriptions[sub], webstompMsg)
        })
      })
    }

    client.onWebSocketClose = () => {
      localContext.store.commit('application/appData/updateServerConnection', false)
    }
    client.onWebSocketError = (error) => {
      localContext.store.commit('application/appData/setLoading', { key: 'websocket', value: 'failed' }, { root: true })
      //Maybe add this to sentry in the future
    }

    client.onStompError = function (frame) {
      console.log('Broker reported error: ' + frame.headers['message'])
      console.log('Additional details: ' + frame.body)
    }
    client.activate()
  },
  subscribe: ({ subscription, action }) => {
    // UNUSED - subscriptions are set up on init
    // If we need subscriptions on the fly this needs to account for orgId too
    if (client.connected) {
      client.subscribe(`/topic/${subscription}`, (msg) => {
        let webstompMsg = JSON.parse(msg.body)
        localContext.store.dispatch(action, webstompMsg)
      })
    }
  },
}

export default (context, inject) => {
  localContext = context
  client = new Client()
  client.reconnectDelay = 300
  inject('webStomp', webStomp)
}
