import { render, staticRenderFns } from "./error.vue?vue&type=template&id=69fb4121&scoped=true"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=69fb4121&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69fb4121",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorPage: require('/usr/src/app/components/shared/pages/ErrorPage.vue').default})
