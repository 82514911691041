let localContext

export const forms = {
  errorSnack(form) {
    if (!form.validate()) {
      // Invalid inputs
      let errors = []
      form.inputs.forEach((e) => {
        if (e.errorBucket && e.errorBucket.length > 0) {
          let attrs = _.get(e, '$attrs')
          let fieldName = ''
          // attrs.text for tables otherwise it should be attrs.name
          if (attrs) fieldName = attrs.text ? localContext.$utils.capitaliseWords(attrs.text) : attrs.name
          if(!errors.includes(e.errorBucket[0])) errors.push(`${fieldName ? fieldName + ' - ' : ''}${e.errorBucket[0]}`)
        }
      })
      localContext.store.commit('application/snack/set', { type: 'error', message: ['Validation failed.'].concat(errors) })
    } else if (localContext.$db.storedErrors) {
      // Stored errors for submitting when not on edit panel
      localContext.store.commit('application/snack/set', { type: 'error', message: ['Validation failed.'].concat(localContext.$db.storedErrors) })
    }
  },
}

export default (context, inject) => {
  localContext = context
  inject('forms', forms)
}
