
import { ConsoleInstrumentation, SessionInstrumentation, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import packageJson from '../../package.json';
export default ({app},inject) => {
  const faro = initializeFaro({
    url: 'https://faro-collector-prod-au-southeast-1.grafana.net/collect/64d932d7b60a6bdf55239a67fc4869f9',
    app: {
      name: 'ElanDev',
      version: packageJson.version, // Dynamically get version from package.json
      environment: app.$config.dev 
    },
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: true, // Enable console log capturing
        captureFetch: true, // Enable fetch request capturing
        captureXHR: true, // Enable XMLHttpRequest capturing
        captureErrors: true, // Enable JavaScript error capturing
        captureNavigation: true, // Enable navigation event capturing
        capturePerformance: true // Enable performance metrics capturing
      }),
      new SessionInstrumentation({persistent:true}),
    ],
  });
  inject('faro', faro);
};