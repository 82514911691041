export const state = () => ({
  notifications: [],
  blankNotification: null,
})

export const getters = {
  getNotifications(state) {
    // this works but the date is a string so maybe it's not perfect idk
    return _.orderBy(state.notifications, ['creationDate'], ['desc'])
  },
}

export const mutations = {
  markAsRead(state) {
    state.notifications = state.notifications.map((el) => {
      return { ...el, read: true }
    })
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  addNotification(state, notification) {
    if (notification && !state.notifications.find((el) => el.id === notification.id)) state.notifications.unshift(notification)
  },
  removeNotification(state, id) {
    state.notifications.splice(
      state.notifications.findIndex((el) => el.id === id),
      1
    )
  },
  removeAll(state) {
    state.notifications = []
  },
}

export const actions = {
  markAsRead({ state, commit }, id) {
    let ids = id ? [id] : state.notifications.filter((el) => !el.read).map((el) => el.id)
    if (ids.length > 0) {
      this.$axios.patch(`/api/v1/notifications/markAsRead`, ids)
      commit('markAsRead')
    }
  },
  async eventUpdate({ dispatch }, stompMessage) {
    if (!stompMessage) return
    let userId = _.get(stompMessage, 'ids[0]')
    if (userId === _.get(this.$auth, '$state.user.sub')) dispatch('fetchUserNotifications')
  },
  fetchUserNotifications({ commit }) {
    let currentUser = _.get(this.$auth, 'user.sub')
    if (currentUser) {
      this.$axios.get(`/api/v1/notifications/user/${currentUser}`).then((res) => {
        if (res && Array.isArray(res.data)) commit('setNotifications', res.data)
      })
    }
  },
  // Props: { userId (string), title (string), priority (bool), path (string), documentId (string) }
  async createNew({ state, dispatch }, props) {
    // Fetch blank
    if (!state.blankNotification) await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankNotification', endpoint: '/api/v1/general/blank/Notification' }, { root: true })

    // Create notification object
    let newNotification = _.cloneDeep(state.blankNotification)
    newNotification.creationDate = new Date()
    Object.assign(newNotification, props)
    await this.$axios.post(`/api/v1/notifications`, newNotification)
  },
  removeNotification({ commit }, id) {
    commit('removeNotification', id)
    this.$axios.delete(`/api/v1/notifications/${id}`)
  },
  removeAllNotifications({ state, commit }) {
    this.$axios.delete(`/api/v1/notifications`, { data: state.notifications.map((el) => el.id) })
    commit('removeAll')
  },
  async fetchBlankNotification({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankNotification', endpoint: '/api/v1/general/blank/Notification' }, { root: true })
  },
}
