
import statusMixin from '~/mixins/statusMixin';

const inflection = require('inflection')

export default {
  mixins: [statusMixin],
  data() {
    return {
      hoverDrawer: false,
      sidebarPinned: false,
    }
  },
  computed: {
    favourites() {
      return _.get(this.$store.getters['auxiliary/filters/getByPage'](this.selectedModule), 'favourites', [])
    },
    canShowSidebar() {
      if (!this.selectedModule || !this.selectedId) return false
      else if (this.$store.getters[`modules/${this.selectedModule}/isSinglePage`]) return false
      else if (this.$store.state.modules[this.selectedModule].noSidebar === true) return false
      return true
    },
    getTypesOrModuleName() {
      if (_.get(this.selectedGroupObjects, '[0].type')) {
        // Order it by type selections
        if (Array.isArray(this.moduleTypes)) {
          return _.sortBy(_.uniq(this.selectedGroupObjects.map(({ type }) => type)), (v) =>
            _.indexOf(
              this.moduleTypes.map(({ value }) => value),
              v
            )
          )
        }
        // Otherwise just order by type
        return _.uniq(this.selectedGroupObjects.map(({ type }) => type))
      } else {
        return [this.getModuleName]
      }
    },
    selectedGroupObjects() {
      if (this.selectedModule === 'kanbans') {
        return this.favourites
          .map((el) => this.$store.getters[`modules/kanbans/getObject`](el))
          .filter((el) => el?.name)
      }
      if (!_.isNil(this.selectedObject) && !_.isNil(this.selectedObject.isTemplate)) {
        return this.$store.getters[`modules/${this.selectedModule}/getItems`].filter(f => f.isTemplate === this.selectedObject?.isTemplate)
      } else {
        return this.$store.getters[`modules/${this.selectedModule}/getItems`]
      }
    },
    getModuleName() {
      return this.$store.getters[`application/appData/getNameFromValue`](this.selectedModule)
    },
    selectedModule() {
      return this.$route?.name?.split('-')[0]
    },
    selectedId() {
      return this.$route?.params?.id
    },
    moduleTypes() {
      return this.$store.state.modules[this.selectedModule]?.typeSelections
    },
    selectedObject() {
      return this.$store.getters[`modules/${this.selectedModule}/getObject`](this.selectedId) ?? {}
    }
  },
  methods: {
    pluralizeTitle(word) {
      if (Array.isArray(this.moduleTypes)) {
        // Use names from module types if it exists
        const found = this.moduleTypes.find((el) => el.value === word)
        if (found?.name) return _.toUpper(found.name)
      }
      //damn, pluralize doesnt know trust is trusts...
      if (word.toLowerCase() === 'trust') return 'TRUSTS'
      if (word.toLowerCase() === 'smsf') return 'SMSFs'
      if (word.toLowerCase() === 'self_employed') return 'SELF EMPLOYED'
      if (word.toLowerCase() === 'non_working') return 'NON WORKERS'
      if (word.toLowerCase() === 'kanbans') return 'Favourite Kanbans'
      //This is required to trim things out like underscores
      let fixedWord = this.$utils.capitaliseWords(word)
      return inflection.pluralize(fixedWord).toUpperCase()
    },
    getFilteredGroupObjectsByType(type) {
      if (type === this.getModuleName) {
        return this.selectedGroupObjects
      } else {
        return this.selectedGroupObjects.filter(({ type: t }) => t === type)
      }
    },
    isSidebarOverflowing(id) {
      return this.$utils.isOverflowing(this.$refs[`sidebarText-${id}`])
    },
    isSelected(id) {
      if (!id || !this.selectedId) return false
      return this.selectedId === id
    },
    toggleSidebar() {
      if (this.sidebarPinned) {
        this.sidebarPinned = false
      } else {
        this.sidebarPinned = true
      }
      localStorage.setItem(`pinSidebar.${this.selectedModule}`, this.sidebarPinned)
    },
  },
  created() {
    this.sidebarPinned = (localStorage.getItem(`pinSidebar.${this.selectedModule}`) ?? 'false') == 'true' ? true : false
  },
}
