/* global google */

const getSuggestions = async (searchText, token) => {
  try {
    const rawResult = await searchLocation(searchText, token)
    return rawResult.map((res) => {
      return {
        id: res.place_id,
        value: res.description,
      }
    })
  } catch (err) {
    console.warn("google places getSuggestions has failed ", err)
    return []
  }
}

const getAddress = async (placeId) => {
  return new Promise((resolve, reject) => {
    var service = new google.maps.places.PlacesService(
      document.createElement('div')
    )
    service.getDetails(
      { placeId: placeId, fields: ['address_components'] },
      (details, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(status)
        }
        resolve(details)
      }
    )
  })
}

// Auxiliary functions
// wrap google api's callback to an async function
const searchLocation = async (val, token) => {
  let promise = await new Promise((resolve, reject) => {
    var displaySuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        reject(status)
      }
      resolve(predictions)
    }

    var service = new google.maps.places.AutocompleteService()
    service.getPlacePredictions(
      {
        input: val,
        componentRestrictions: { country: ['AU'] },
        sessionToken: token,
      },
      displaySuggestions
    )
  }).catch(function (err) {
    throw err
  })

  return promise
}

export default ({ app, store }, inject) => {
  // Inject $GetSuggestions(val) in Vue, context and store.
  // Inject $getAddress(val) in Vue, context and store.
  inject('getSuggestions', (val) => getSuggestions(val))
  inject('getAddress', (val) => getAddress(val))
  inject('getSuggestions', (val, apiKey) => getSuggestions(val, apiKey))
}
