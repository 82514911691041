
export default {
  data() {
    return {
      searchInput: '',
      metaTagSearch: null,
      searchResults: [],
    }
  },
  mounted() {
    this.$bus.$on('openContextMenu', () => (this.contextMenu = true))
    this.$bus.$on('closeContextMenu', () => (this.contextMenu = false))
    window.addEventListener('keydown', (event) => {
      if (!this.$db.editInProgress() && event.altKey && event.key === '/') {
        this.contextMenu = true
      }
    })
  },
  methods: {
    openTab(tab) {
      this.$store.commit('auxiliary/user/addTab', { tab })
      this.contextMenu = false
    },
    selectModuleFromSearch(search) {
      if (this.searchResults.length === 0) return
      const mod = _.get(this.searchResults, '0', '')
      this.$router.push({ path: `/${mod?.value}` })
      this.openTab(mod)
      this.metaTagSearch = ''
    },
    selectConfigManager() {
      this.contextMenu = false
    },
    isDisabled(appModule) {
      if (appModule.disabled) return true
      if (!this.metaTagSearch) return false

      const found = _.find(this.searchResults, { value: appModule.value })
      return found ? false : true
    },
    getHighlight(appModule) {
      const startIdx = appModule.name.toLowerCase().indexOf(this.metaTagSearch.toLowerCase()) === -1 ? 0 : appModule.name.toLowerCase().indexOf(this.metaTagSearch.toLowerCase())
      const endIdx = startIdx + this.metaTagSearch.length
      const matchingText = appModule.name.substring(startIdx, endIdx)

      return `<span>${startIdx > 0 ? appModule.name.substring(0, startIdx) : ''}</span><span style="color: var(--v-success-lighten3)">${matchingText}</span><span>${appModule.name.substring(endIdx)}</span>`
    },
  },
  computed: {
    suggestionText() {
      if (this.searchResults.length === 0) return

      const modName = _.get(this.searchResults, '0.name', '')
      if (!modName.toLowerCase().startsWith(this.metaTagSearch)) return ''

      const restOfSuggestion = modName.substring(this.metaTagSearch.length)
      return this.metaTagSearch.concat(restOfSuggestion)
    },
    selectedGroup() {
      return this.$store.state.modules.entityGroups?.selectedEntityGroup
    },
    apps() {
      const jsonApps = this.$store.state.application.appData.apps
      let modulesLayout = _.cloneDeep(this.$store.getters[`modules/configurationHub/getModulesLayout`])

      modulesLayout.forEach((app) => {
        let modules = app.modules.map((module) => jsonApps[app.appName].find((el) => el.value === module)).filter((mod) => mod ?? false)
        modules = modules.filter((el) => this.$store.getters[`application/devContent/isModuleActive`](el?.value)) // Filter out modules which shouldn't show
        app.modules = modules
      })
      return modulesLayout
    },
    contextMenu: {
      get() {
        return this.$store.state.application.appData.showContextMenu
      },
      set(state) {
        return this.$store.commit('application/appData/setToggleContextMenu', state)
      },
    },
    modules() {
      return this.apps.flatMap((app) => app.modules)
    },
  },
  watch: {
    $route() {
      this.contextMenu = false
    },
    contextMenu() {
      this.metaTagSearch = ''
    },
    metaTagSearch(v) {
      v = typeof v === 'string' ? v.trim().toLowerCase() : ''

      if (!v) {
        this.searchResults = []
        return
      }

      const found = []

      this.modules.forEach((mod) => {
        if (mod.value === 'comingSoon') return

        const isAnyMetaMatch = mod.metaTags.map((el) => el?.toLowerCase().includes(this.metaTagSearch.toLowerCase())).includes(true)
        if (isAnyMetaMatch) found.push(mod)
        if (mod.name.toLowerCase().includes(this.metaTagSearch.toLowerCase())) found.push(mod)
      })

      this.searchResults = found
    },
  },
}
