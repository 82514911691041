/**
 * 
 */
export default {
  computed: {
    /**
     * @deprecated
     * @returns selected app
     */
    selectedApp() {
      return  _.camelCase(_.get(this.$store.state.application.appData, 'selectedApp', ''))
    },
    /**
     * 
     * @returns {} selected module
     */
    selectedModule() {
      return this.$route.name?.toLowerCase()?.split('-')[0]
    },
    /**
     * @deprecated
     * @returns [selectedApp]/[selectedModule]
     */
    storePath()  {
      return `${this.selectedApp}/${this.selectedModule}`
    },
    selectedIsArchived() {
      let items = this.$store.getters[`modules/${this.storePath}/getItems`]
      if (Array.isArray(items)) {
        let found = items.find((el) => el.id === this.$route.params.id)
        if (found?.deleted !== undefined) return true
      }
      return false
    },
  },
}