import Vue from 'vue'

export const state = () => ({
  stickyNotes: {},
})

export const getters = {
  getNotesForParentAndCard: (state) => ({ parentId, cardName }) => {
    if (_.get(state.stickyNotes, parentId, false) === 'pending') return 'pending'
    const notes = _.get(state.stickyNotes, parentId, [])
    return Array.isArray(notes) ? notes.filter((el) => el.parentCardName === cardName) : []
  },
}

export const mutations = {
  setNotes(state, { parentId, obj }) {
    Vue.set(state.stickyNotes, parentId, obj)
  },
  updateNote(state, { note, shouldDelete }) {
    let index = state.stickyNotes[note.parentId].findIndex((el) => el.id === note.id)
    //It should never be -1
    if (index === -1) {
      console.error('StickyNote: cant find note')
    }
    if (shouldDelete) state.stickyNotes[note.parentId].splice(index, 1)
    else state.stickyNotes[note.parentId].splice(index, 1, note)
  },
}

export const actions = {
  async saveStickyNote({ state, commit }, stickyNote) {
    stickyNote = await this.$axios.$post(`/api/v1/stickyNote`, stickyNote)
    let currentStickyNotes = _.get(state.stickyNotes, stickyNote.parentId, [])
    commit('setNotes', { parentId: stickyNote.parentId, obj: [...currentStickyNotes, stickyNote] })
  },
  async deleteStickyNote({ state, commit }, stickyNote) {
    const response = await this.$axios.delete(`/api/v1/stickyNote/${stickyNote.id}`)
    commit('updateNote', { note: stickyNote, shouldDelete: true })
  },
  async updateStickyNote({ state, commit }, stickyNote) {
    await this.$axios.put(`/api/v1/stickyNote/${stickyNote.id}`, stickyNote)
    commit('updateNote', { note: stickyNote, shouldDelete: false })
  },
  async getStickyNotesForParent({ state, commit }, parentId) {
    if (!parentId || _.isNil(parentId)) return null
    if (_.has(state.stickyNotes, parentId, false)) {
      return state.stickyNotes[parentId]
    } else {
      commit('setNotes', { parentId, obj: 'pending' })
      commit('setNotes', { parentId, obj: (await this.$axios.get(`/api/v1/stickyNote/${parentId}`)).data })
      return state.stickyNotes[parentId]
    }
  },
  async getBlankStickyNote({ state, dispatch }) {
    state.blankStickyNote = await dispatch(`application/storeUtils/getBackendClass`, 'stickyNote', { root: true })
    return state.blankStickyNote
  },
}
