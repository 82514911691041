import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('incomeExpenses', [
  { name: "Income", value: "INCOME" },
  { name: "Expense", value: "EXPENSE" }
], true)

export const state = () => ({
  ...crud.state,
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    if (!id) return ''
    // Return 'Income' or 'Expense' as the name depending on where the ID is found
    return getters?.getAllItems?.find(v => v.id === id)?.type
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const incomeExpense = cachedObj ? cachedObj : getters?.getAllItems?.find((el) => el.id === id) // If not found in cache, try find it
    if (!incomeExpense) return ''

    // Generate text for fields that should display
    let displayText = []
    if (incomeExpense.type) displayText.push(incomeExpense.type)
    if (incomeExpense.description) displayText.push(incomeExpense.description)
    if (_.get(incomeExpense, 'ownership.items', []).length > 0) displayText.push($nuxt.$utils.getOwnershipString(incomeExpense.ownership))
    if (_.get(incomeExpense, 'amount', []).length > 0) displayText.push($nuxt.$calculations.sumAnnualDollarsString(incomeExpense.amount))
    displayText = displayText.filter((el) => el !== null && el !== undefined)
    return $nuxt.$utils.concatStrings(' - ', displayText)
  },
  getType: (state, getters) => (id) => {
    // Check each table to see if the ID is in it to get the type
    let income = _.get(getters['getItems'], 'income.items', [])
    let isIncome = income.findIndex((el) => el.id === id) >= 0 ? true : false
    if (isIncome) return 'Income'
    let expenses = _.get(getters['getItems'], 'expenses.items', [])
    let isExpense = expenses.findIndex((el) => el.id === id) >= 0 ? true : false
    if (isExpense) return 'Expense'
    return ''
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
}
