export const state = () => ({
  transactionId: null,
  subscriptions: {},
  stompMessage: null,
})

export const getters = {
  getTransactionId: (state) => {
    return state.transactionId
  },
}

export const mutations = {
  setTransactionId(state, id) {
    state.transactionId = id
  },
  updateSubscriptions: (state, { subscription, action }) => {
    if (state.subscriptions[subscription] === undefined) {
      state.subscriptions[subscription] = action
      // $nuxt.$webStomp.subscribe({ subscription, action }) UNUSED - client is subscribed to on init - bring it back if we ever need to update subscriptions on the fly
    }
  },
  setStompMessage(state, stompMessage) {
    state.stompMessage = stompMessage
  },
}

export const actions = {
  unsubAllApps: ({ state, commit }) => {
    Object.keys(state.subscriptions).forEach((subscription) => {
      commit('updateSubscriptions', { subscription })
    })
  },
}
