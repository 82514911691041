
export default {
  async fetch() {
    this.products = await this.$store.dispatch('auxiliary/stripe/getProducts')
  },
  data() {
    return {
      selectedUsers: [],
      loading: false,
      products: [],
      selectedPlan: null,
    }
  },
  computed: {
    dialog: {
      get() {
        return this?.$store?.state?.auxiliary?.stripe?.upgradeDialog
      },
      set(v) {
        this.$store.commit('auxiliary/stripe/setUpgradeDialog', v)
      },
    },
    availableUsers() {
      return _.differenceBy(this.usersInOrg, this.selectedUsers, 'user_id')
    },
    usersInOrg() {
      const userSub = this.$auth?.$state?.user?.sub
      if (!userSub) return []
      return _.cloneDeep(this.$store.getters['auxiliary/user/getUsersInOrg'] || [])
    },
    currentUser() {
      const userSub = this.$auth?.$state?.user?.sub
      if (!userSub) return null
      return _.cloneDeep(this.$store.state?.auxiliary?.user?.org?.users || []).find((user) => user?.user_id === userSub)
    },
    activeUsers() {
      return this.usersInOrg.filter((user) => this.selectedUsers.includes(user?.user_id) && user?.status === 'ACTIVE')
    },
  },
  methods: {
    getPlanName(singular, name) {
      switch (name) {
        case 'month':
          return singular ? 'Month' : 'Monthly'
        case 'year':
          return singular ? 'Year' : 'Annually'
        default:
          return ''
      }
    },
    getPracticePlanAmount(price) {
      if (price.tiers) {
        return _.first(price.tiers.filter(f  => f.upTo !== null)).upTo
      }
      return null
    },
    removeSelectedUser(user) {
      const indexToRemove = this.selectedUsers.findIndex(f => f.user_id === user.user_id)
      if (indexToRemove !== -1) {
        this.selectedUsers.splice(indexToRemove, 1);
      }
    },
    isRecommended(price) {
      return this.selectedUsers.length > 2 && price.billingScheme === 'tiered' || this.selectedUsers.length <= 2 && price.billingScheme === 'per_unit'
    },
    getPrice({value}, formatted) {
      let price = value[0]
      this.selectedPlan = price.id
      let userCount = this.selectedUsers?.length

      if (price.billingScheme === 'per_unit') {
        return formatted ? this.$utils.moneyDisplay((price.unitAmount * userCount) / 100) : (price.unitAmount * userCount) / 100
      } else {
        let tieredPrice = 0
        let additionalUsers = 0

        for (const tier of price.tiers?.filter(f => f.upTo !== null)) {
          additionalUsers += userCount - tier.upTo
          tieredPrice += (tier.flatAmount / 100)
        }

        if (additionalUsers > 0) {
          tieredPrice += ((price.tiers?.find(f => f.upTo === null)?.unitAmount * additionalUsers) / 100)
        }

        return formatted ? this.$utils.moneyDisplay(tieredPrice) : tieredPrice
      }
    },
    getAdditionalPricing(price) {
      let additionalCost = 0
      for (const tier of price.tiers?.filter(f => f.upTo === null)) {
        additionalCost += (tier.unitAmount / 100)
      }
      return this.$utils.moneyDisplay(additionalCost)
    },
    async requestSession() {
      this.loading = true
      await this.$store.dispatch('auxiliary/stripe/createCheckoutSession', { userIds: this.selectedUsers.map(m => m.user_id), priceId: this.selectedPlan })
      this.loading = false
    },
    input(user) {
      if (this.selectedUsers.includes(user?.user_id)) {
        this.selectedUsers = this.selectedUsers.filter((el) => el !== user?.user_id)
      } else {
        this.selectedUsers.push(user?.user_id)
      }
    },
  },
  watch: {
    usersInOrg: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v) {
          this.selectedUsers = _.cloneDeep(v)
        }
      }
    }
  }
}
