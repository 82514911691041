let localContext

export const common = {
  religions: ['No Religion', 'Buddhism', 'Christianity', 'Hinduism', 'Islam', 'Judaism'],

  frequencies: [
    { name: 'Weekly', value: 'WEEKLY' },
    { name: 'Fortnightly', value: 'FORTNIGHTLY' },
    { name: 'Monthly', value: 'MONTHLY' },
    { name: 'Quarterly', value: 'QUARTERLY' },
    { name: 'Semi-Annually', value: 'SEMI_ANNUALLY' },
    { name: 'Annually', value: 'ANNUALLY' },
  ],
  frequencyDefault: () => {
    return 'ANNUALLY'
  },
  frequencyDisplay: (value) => {
    return localContext.$utils.capitaliseWords(value)
  },
  printFrequency: (value) => {
    if (!value || typeof value !== 'string') return ''

    switch (value.toLowerCase()) {
      case 'weekly':
        return 'week'
      case 'fortnightly':
        return 'fortnight'
      case 'monthly':
        return 'month'
      case 'quarterly':
        return 'quarter'
      case 'semi_annually':
        return 'half year'
      case 'annually':
        return 'year'
      default:
        return ''
    }
  },
  percentOfAnnual: (frequency) => {
    // Returns a decimal as a percentage
    let val = frequency
    if (typeof frequency === 'string') val = common.frequencyValues(frequency)
    // If val is monthly, return this because monthly should be a decimal number (4.345)
    if (val === 4) return 0.0833
    return val / common.frequencyValues('annually')
  },
  annualised(frequency, value) {
    if (frequency && value !== null && value !== undefined && !isNaN(Number(value))) {
      return Number(common.annualMultiplier(frequency) * value)
    }
    return 0
  },
  annualMultiplier: (frequency) => {
    // Returns how many times the frequency is run annually e.g. Quarterly returns 4
    if (!frequency) return null
    if (!(typeof frequency === 'number')) frequency = common.frequencyValues(frequency)
    // If frequency is monthly return 12 because it should be decimal otherwise monthly would be 52/4=13
    if (frequency === 4) return 12
    return common.frequencyValues('annually') / Number(frequency)
  },
  frequencyValues: (value) => {
    if (!value) return
    else if (!(typeof value === 'string')) return value
    switch (value.toLowerCase()) {
      case 'weekly':
        return 1
      case 'fortnightly':
        return 2
      case 'monthly':
        return 4
      case 'quarterly':
        return 13
      case 'semi_annually':
        return 26
      case 'annually':
        return 52
      default:
        console.warn('Frequency did not match a conversion case: ',  value)
        return '-'
    }
  },
  educationLevels: () => {
    return [
      { name: 'High School', value: 'HIGH_SCHOOL' },
      { name: 'Certificate I', value: 'CERTIFICATE_I' },
      { name: 'Certificate II', value: 'CERTIFICATE_II' },
      { name: 'Certificate III', value: 'CERTIFICATE_III' },
      { name: 'Certificate IV', value: 'CERTIFICATE_IV' },
      { name: 'Diploma', value: 'DIPLOMA' },
      { name: 'Advanced Diploma', value: 'ADVANCED_DIPLOMA' },
      { name: 'Bachelor Degree', value: 'BACHELOR_DEGREE' },
      { name: 'Graduate Diploma', value: 'GRADUATE_DIPLOMA' },
      { name: 'Masters Degree', value: 'MASTERS_DEGREE' },
      { name: 'Doctoral Degree', value: 'DOCTORAL_DEGREE' },
    ]
  },
  minimumPensionPercentage: (age) => {
    // Values from https://www.ato.gov.au/Rates/Key-superannuation-rates-and-thresholds/?page=8
    age = Number(age)
    switch (true) {
      case age < 65:
        return 0.02
      case age <= 74:
        return 0.025
      case age <= 79:
        return 0.03
      case age <= 84:
        return 0.035
      case age <= 89:
        return 0.045
      case age <= 94:
        return 0.055
      case age >= 95:
        return 0.07
    }
  },
  termPaymentFactor: (termRemaining) => {
    // TODO use a CSV file for this
    // Values from https://taxandsupernewsroom.com.au/payment-factors-for-market-linked-income-streams/

    termRemaining = Number(termRemaining)
    if (termRemaining == 0) return null
    else if (termRemaining > 70) return 3.85
    else if (termRemaining < 1) return 100

    switch (termRemaining) {
      case 70:
        return 3.85
      case 35:
        return 5.0
      case 69:
        return 3.86
      case 34:
        return 5.08
      case 68:
        return 3.87
      case 33:
        return 5.16
      case 67:
        return 3.89
      case 32:
        return 5.24
      case 66:
        return 3.9
      case 31:
        return 5.34
      case 65:
        return 3.92
      case 30:
        return 5.44
      case 64:
        return 3.94
      case 29:
        return 5.54
      case 63:
        return 3.95
      case 28:
        return 5.66
      case 62:
        return 3.97
      case 27:
        return 5.78
      case 61:
        return 3.99
      case 26:
        return 5.92
      case 60:
        return 4.01
      case 25:
        return 6.07
      case 59:
        return 4.03
      case 24:
        return 6.23
      case 58:
        return 4.05
      case 23:
        return 6.4
      case 57:
        return 4.07
      case 22:
        return 6.59
      case 56:
        return 4.1
      case 21:
        return 6.8
      case 55:
        return 4.12
      case 20:
        return 7.04
      case 54:
        return 4.15
      case 19:
        return 7.29
      case 53:
        return 4.17
      case 18:
        return 7.58
      case 52:
        return 4.2
      case 17:
        return 7.91
      case 51:
        return 4.23
      case 16:
        return 8.27
      case 50:
        return 4.26
      case 15:
        return 8.68
      case 49:
        return 4.3
      case 14:
        return 9.16
      case 48:
        return 4.33
      case 13:
        return 9.71
      case 47:
        return 4.37
      case 12:
        return 10.35
      case 46:
        return 4.41
      case 11:
        return 11.11
      case 45:
        return 4.44
      case 10:
        return 12.02
      case 44:
        return 4.49
      case 9:
        return 13.14
      case 43:
        return 4.53
      case 8:
        return 14.56
      case 42:
        return 4.58
      case 7:
        return 16.37
      case 41:
        return 4.63
      case 6:
        return 18.76
      case 40:
        return 4.68
      case 5:
        return 22.12
      case 39:
        return 4.74
      case 4:
        return 27.25
      case 38:
        return 4.8
      case 3:
        return 35.71
      case 37:
        return 4.86
      case 2:
        return 52.63
      case 36:
        return 4.93
      case 1:
        return 100.0
    }
  },
}

export default (context, inject) => {
  localContext = context
  inject('common', common)
}
