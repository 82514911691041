import appStructure from '../datafiles/appStructure.json'

export const getters = {
  isModuleActive: (state) => (module) => {
    // Get values of modules where 'dev' is set to true meaning they shouldn't show in prod
    let devModules = Object.values(appStructure)
      .flatMap((el) => el)
      .filter((el) => el?.dev === true)
      .map((el) => el?.value)
    if (!Array.isArray(devModules)) return true

    // If the given module isn't in the dev only modules or it is and it's not prod then it can be shown
    return !devModules.includes(module) || (devModules.includes(module) && $nuxt.$config.dev !== 'production')
  },
}
