import { render, staticRenderFns } from "./ApplicationKnowledgeBaseMenu.vue?vue&type=template&id=b5af4b1c&scoped=true"
import script from "./ApplicationKnowledgeBaseMenu.vue?vue&type=script&lang=js"
export * from "./ApplicationKnowledgeBaseMenu.vue?vue&type=script&lang=js"
import style0 from "./ApplicationKnowledgeBaseMenu.vue?vue&type=style&index=0&id=b5af4b1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5af4b1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApplicationKeybindsDialog: require('/usr/src/app/components/application/dialog/ApplicationKeybindsDialog.vue').default,ApplicationUserGuidesDialog: require('/usr/src/app/components/application/dialog/ApplicationUserGuidesDialog.vue').default,ApplicationKnowledgeBaseCard: require('/usr/src/app/components/application/card/ApplicationKnowledgeBaseCard.vue').default})
