import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('insurances', null)

export const state = () => ({
  ...crud.state,
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName

    // Prodider Product - Description
    const name = $nuxt.$utils.concatStrings(' ', obj?.summary?.insurer, obj?.summary?.productName)
    return $nuxt.$utils.concatStrings(' - ', name, obj?.summary?.description)
  },
  getSubtitle: (state, getters) => (id) => {
    const obj = getters.getObject(id)
    if (!obj) return ''

    const policyNumber = obj?.summary?.policyNumber
    const covers = obj?.cover?.covers

    let coversText = ''
    if (Array.isArray(covers)) {
      coversText = $nuxt.$utils.concatStrings(', ', _.uniq(covers.map((el) => $nuxt.$utils.capitaliseWords(el?.coverType))))
    }

    return $nuxt.$utils.concatStrings(' - ', policyNumber, coversText)
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
}
