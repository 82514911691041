export default function ({ store, $vuetify, $auth }) {
  // Watch for configuration changes in store and extract/set the user's theme
  store.subscribe((mutation, state) => {
    $vuetify.theme.dark = false
    // const userId = $auth.$state.user.sub
    // if (mutation.type === 'application/storeUtils/setItem' && mutation.payload.item === 'configurations') {
    //   const theme = _.get(mutation, `payload.value.user.userSettings.${userId}.theme`, '')
    //   // $vuetify.theme.dark = theme === 'DARK' ? true : false
    // }
  });
}