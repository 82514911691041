import _ from 'lodash'

function retrieveLocalFilterStore(path, defaultValue = null) {
  let item = localStorage.getItem('filters')
  if (item != null) item = JSON.parse(item)
  else item = {}
  return $nuxt._.get(item, path, defaultValue)
}

function setLocalFilterStore(path, value) {
  // detect filter cleared
  if (_.get(value, 'segment', null) == null && $nuxt._.get(value, 'filter.filterOptions.length', 0) == 0) value = null

  let item = localStorage.getItem('filters')
  if (item != null) item = JSON.parse(item)
  else item = {}

  // set, or delete if filter cleared
  if (value == null) {
    delete item[path]
  } else {
    $nuxt.$set(item, path, value)
  }
  localStorage.setItem('filters', JSON.stringify(item))
}

export const state = () => ({
  filters: { pages: [] },
  filtersPending: false,
  activeFilters: null,
  segments: { tasks: ['Due Date', 'Status'] },
  activeSegments: {},
})

export const getters = {
  getBySegmentsPage: (state) => (page) => {
    return _.get(state.segments, page, [])
  },
  getByActiveSegmentsPage: (state) => (page) => {
    return _.get(state.activeSegments, page, [])
  },
  // Get the filters for the table by page
  getByPage: (state) => (_page) => {
    //TODO Rework this?

    const pages = _.get(state, 'filters.pages', null)
    if (!Array.isArray(pages)) return null

    const page = _.cloneDeep(pages.find((pg) => pg.page === _page))
    if (!page) return null

    // Filter out DELETED
    if (page.filters && Array.isArray(page.filters)) {
      page.filters = page.filters.filter((pg) => !pg.deleted)
    }

    if (pages) return page
    return null
  },
  // Get the filters for the PAGE by the route params
  getByRoute: (state, getters) => {
    const page = getters.getFilterPage
    return getters.getByPage(page)
  },
  // Returns the "table Id" - i,e. where to apply the saved filters
  getFilterPage: (state) => {
    return $nuxt?.$route?.name
  },
  addFilterToPage: (state) => (page, filterOptions) => {
    const pages = _.cloneDeep(_.get(state.filters, 'pages', [])).find((pg) => pg.page === page)
    const newPages = _.cloneDeep(_.get(state.filters, 'pages', []))

    // Add the new filter
    if (!pages) newPages.push({ page, filters: [filterOptions] })
    else newPages.find((pg) => pg.page === page).filters.push(filterOptions)

    return newPages
  },
  createFilterObject: (state) => (filterOptions, segment = '', filterName = '', filterId = '') => {
    return { filterName, filterId, segment, filterOptions }
  },
  getActiveFilterByPage: (state) => (page) => {
    return _.get(state.activeFilters, page)
  },
  isFavourited: (state) => (id) => {
    const flat = state.filters.pages.flatMap((el) => el.favourites)
    return flat.includes(id)
  },
}

export const mutations = {
  setActiveSegmentsPage(state, { page, value, setURL = true }) {
    $nuxt.$set(state.activeSegments, page, value)
    if ($nuxt.$route.name == page && setURL) {
      const newQuery = {
        ...$nuxt.$route.query,
        segments: value?.length ? value : undefined,
      }
      $nuxt.$router.push({ query: newQuery })
    }
  },
  setFiltersPage(state, { page, filters}) {
    $nuxt.$set(state.filters.pages, page, filters)
  },
  setPending(state, payload) {
    state.filtersPending = payload
  },
  setFilters(state, payload) {
    state.filters = payload
  },
  setActiveFilter(state, { page, filter, segment }) {
    const activeFilters = _.set(_.cloneDeep(state.activeFilters) || {}, page, { filter: filter, segment: segment })
    $nuxt.$set(state, 'activeFilters', activeFilters)
    if (_.get($nuxt.$route, 'meta.filters.localStorage', false)) {
      setLocalFilterStore($nuxt.$route.path, _.get(activeFilters, page, {}))
    }
  },
  loadActiveFilterFromLocalStorage(state, { page }) {
    if (!_.get($nuxt.$route, 'meta.filters.localStorage', false)) return
    const localStored = retrieveLocalFilterStore($nuxt.$route.path)
    let clonedFilters = _.cloneDeep(state.activeFilters)
    if (_.isNil(clonedFilters)) {
      clonedFilters = {}
    }
    const activeFilters = _.set(clonedFilters, page, localStored)
    state.activeFilters = activeFilters
  },
  currentPage(state, page) {
    state.currentPage = page
  },
}

export const actions = {
  debouncedParseURL: _.debounce(async function ({ commit, state }) {
    // Access the current query parameters from the route
    const query = $nuxt.$route.query
    const page = $nuxt.$route.name
    // Extract filter options
    const filterOptions = Object.entries(query)
      .filter(([key, value]) => key !== 'filterName' && key !== 'segments')
      .map(([key, values]) => (Array.isArray(values) ? values.map((value) => ({ group: key, value })) : [{ group: key, value: values }]))
      .flat()

    // Extract segments (handling multiple 'segments' query parameters)
    const segments = Array.isArray(query.segments) ? query.segments : [query.segments].filter(Boolean)

    commit('setActiveSegmentsPage', { page, value: segments, setURL: false })
    commit('setActiveFilter', { page, filter: {filterOptions:filterOptions, filterName: query.filterName}, segment: segments })
  }, 500),
  /**
   * Get the saved filters on app load
   */
  async getUserFilters({ state, commit }) {
    if (state.filters.pages.length != 0) return
    const timestamp = new Date().getTime();

    commit('setPending', true)
    await this.$axios.get(`/api/v1/userFilter?cacheBust=${timestamp}`).then((res) => {
      if (!res?.data?.pages) return
      commit('setFilters', _.cloneDeep(res.data))
    })

    commit('setPending', false)
  },
  /**
   * Updates the user's filter object, saving the new filter entry
   */
  async saveUserFilter({ state, commit, getters }, { filterName }) {
    const page = $nuxt.$route.name
    const result = []
    Object.entries($nuxt.$route.query).forEach(([key, value]) => {
      if (key !== 'segments') {
        // Handle cases where value can be an array (e.g., multiple values for the same key)
        if (Array.isArray(value)) {
          value.forEach((v) => result.push({ group: key, value: v }))
        } else {
          result.push({ group: key, value })
        }
      }
    })

    let pages = getters.addFilterToPage(page, getters.createFilterObject(result, getters.getByActiveSegmentsPage(page), filterName, this.$uuid()))
    if (!pages) return
    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages }))
    commit('setPending', false)

    return true
  },
  async updatePage({ state, commit }, page) {
    const pages = _.cloneDeep(state.filters.pages).map((el) => {
      if (el.page == page.page) return page
      return el
    })
    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages }))
    commit('setPending', false)
  },
  // Do a look through all filters in the store, looking for the filter id
  // Then set the optional key-value pairs (rest) to override the existing key-values in the found filter object
  async updateFilter({ state, commit }, { filterId, ...rest }) {
    if (Object.keys(rest).length === 0) return

    // Get page id ( app_module_card_tableId ) via filterId, and
    const newPages = _.get(state, 'filters.pages', []).map((page) => {
      return {
        ...page,
        filters: (page.filters || []).map((filter) => {
          return filter.filterId === filterId ? { ...filter, ...rest } : { ...filter }
        }),
      }
    })

    commit('setPending', true)

    // Send off request
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages: newPages }))
    commit('setPending', false)
  },
  async toggleFavourite({ state, commit, dispatch }, { page, id, remove }) {
    commit('currentPage', page)
    let filterClone = _.cloneDeep(state.filters)
    //Do this so ui updates immediately, it will be overridded in ~500ms with the currect server state
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, state.currentPage))
    if (pageIndex === -1 && !remove) {
      //create a new one
      filterClone.pages.push({ page: state.currentPage, favourites: [id] })
    } else if (remove) {
      _.remove(filterClone.pages[pageIndex].favourites, (el) => el === id)
    } else {
      $nuxt.$set(filterClone.pages, `[${pageIndex}].favourites`, _.xor(state.filters.pages[pageIndex].favourites, [id]))
    }
    commit('setFilters', filterClone)
    dispatch('debouncedUpdateFavourites')
  },
  debouncedUpdateFavourites: _.debounce(async function ({ commit, state }) {
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, state.currentPage))
    if (pageIndex === -1) return // Handle case where page is not found
    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages: state.filters.pages }))
    commit('setPending', false)
  }, 1000),
  async removeFilter({ state, commit }, { page, filterId }) {
    // Remove the filter by the filterId from the page's filters (mark as DELETED)
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, page))
    const currentPage = _.get(state.filters, 'pages.' + pageIndex)
    const newPageFilters = currentPage.filters.map((el) => {
      if (el.filterId !== filterId) return el
      return { ...el, deleted: 'DELETED' }
    })

    const pages = _.set(_.cloneDeep(state.filters.pages), `[${pageIndex}].filters`, newPageFilters)

    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages }))
    commit('setPending', false)
  },
  async restoreFilter({ state, commit }, { page, filterId }) {
    // Filters should never be deleted from a page, instead marked as deleted: 'DELETED'
    //  When retrieving filters, get the non-DELETED ones only
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, page))
    const currentPage = _.get(state.filters, 'pages.' + pageIndex)

    const newPageFilters = currentPage.filters.map((el) => {
      if (el.filterId !== filterId) return el
      // omit the 'deleted' key
      return { filterId: el.filterId, filterName: el.filterName, filterOptions: el.filterOptions, segment: el.segment }
    })

    const pages = _.cloneDeep(state.filters.pages).map((pg, i) => {
      if (i === pageIndex) {
        // Set newPageFilters
        return { ...pg, filters: newPageFilters }
      } else return pg
    })

    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages }))

    commit('setPending', false)
  },
}
