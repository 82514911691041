
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'cog',
    },
    openInNew: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    cols: {
      type: String,
      default: '12',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLarge() {
      // Large if cols are bigger than 6
      return Number(this.cols) && Number(this.cols) > 6
    },
  },
}
