import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('assets', [
  {
    name: 'Cash Account',
    value: 'CASH_ACCOUNT',
    class: 'CashAccount',
  },
  {
    name: 'Property', //adddress (subtype)
    value: 'PROPERTY',
    class: 'Property',
  },
  {
    name: 'Investment Account',
    value: 'INVESTMENT_ACCOUNT',
    class: 'InvestmentAccount',
  },
  {
    name: 'Annuity',
    value: 'ANNUITY',
    class: 'Annuity',
  },
  {
    name: 'Lifestyle Assets',
    value: 'LIFESTYLE_ASSETS',
    class: 'LifestyleAsset',
  },
])

export const state = () => ({
  ...crud.state,
  blankInvestment: null,
  blankFee: null,
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName

    if (obj?.type === 'PROPERTY') {
      let name = []
      const address = _.get(rootGetters['modules/entityGroups/getAddressEntriesByInstanceId'](id), '0', null)
      if (address) name.push($nuxt.$utils.formatPlace(address.address))
      if (obj?.summary?.description) name.push(obj?.summary?.description)
      // Address - Description
      return name.join(' - ') || defaultName
    } else if (['CASH_ACCOUNT', 'INVESTMENT_ACCOUNT', 'ANNUITY'].includes(obj?.type)) {
      // Prodider Product - Description
      const name = $nuxt.$utils.concatStrings(' ', obj?.summary?.provider, obj?.summary?.product)
      return $nuxt.$utils.concatStrings(' - ', name, obj?.summary?.description)
    }
    return obj?.summary?.description || defaultName
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    const asset = getters.getObject(id)
    if (!asset) return ''

    const ownership = $nuxt.$utils.getOwnershipString(asset?.summary?.ownership)
    const subType = asset?.summary?.type
    return $nuxt.$utils.concatStrings(' - ', ownership, $nuxt.$utils.capitaliseWords(subType))
  },
  getAssetsCashAccounts(state, getters, rootState, rootGetters) {
    const assets = _.cloneDeep(rootGetters[`modules/assets/getItems`]).filter((el) => el && el.type === 'CASH_ACCOUNT')

    if (!Array.isArray(assets)) return []

    return assets.map((asset) => {
      const owners = asset.ownership.items.map((owner) => $nuxt.$utils.getDisplayName(owner.name)).join(', ')
      return { ...asset, name: `${$nuxt.$utils.capitaliseWords(asset.subType)} ${owners ? '(' + owners + ')' : ''}` }
    })
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
  async getBlankInvestment({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankInvestment', endpoint: '/api/v1/investment/new' }, { root: true })
  },
  async getBlankFee({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankFee', endpoint: '/api/v1/general/blank/Fee' }, { root: true })
  },
  updateLinks({ commit, dispatch, rootGetters }, { payload, old }) {
    if (payload?.summary?.type !== 'Offset Account' && old?.summary?.type === 'Offset Account') {
      // Changed from Offset Account to another type, remove links
      dispatch('removeLinkedLoans', { assetId: old.id, loanIds: old.summary.linkedLoan })
    }
    if (payload?.summary?.type !== 'Offset Account') return

    // Links removed via the update
    const removed = _.difference(old.summary.linkedLoan, payload.summary.linkedLoan)
    dispatch('removeLinkedLoans', { assetId: payload.id, loanIds: removed })

    // Loans referenced by the asset's LinkedLoan
    const loans = rootGetters['modules/liabilities/getItems'].filter((el) => payload.summary.linkedLoan.includes(el.id))

    // Update loans, add the asset ID as an offset account
    loans.forEach((loan) => {
      if (Array.isArray(loan?.balance?.offsetAccounts) && !loan.balance.offsetAccounts.includes(payload.id)) {
        let clone = _.cloneDeep(loan)
        clone.balance.offsetAccounts.push(payload.id)
        commit(`modules/liabilities/updateObject`, clone, { root: true })
        this.$axios.put(`/api/v1/liabilities/` + clone.id, clone)
      }
    })
  },
  removeLinks({ getters, dispatch }, ids) {
    // Remove links when asset is deleted
    const assets = getters['getItems'].filter((el) => ids.includes(el.id))
    assets.forEach((asset) => {
      dispatch('removeLinkedLoans', { assetId: asset.id, loanIds: asset?.summary?.linkedLoan })
    })
  },
  removeLinkedLoans({ commit, rootGetters }, { assetId, loanIds }) {
    if (!assetId || !Array.isArray(loanIds) || loanIds.length === 0) return

    // Loans referenced by the asset's LinkedLoan
    const loans = rootGetters['modules/liabilities/getItems'].filter((el) => loanIds.includes(el.id))
    loans.forEach((loan) => {
      if (Array.isArray(loan?.balance?.offsetAccounts) && loan.balance.offsetAccounts.includes(assetId)) {
        let clone = _.cloneDeep(loan)
        const index = clone.balance.offsetAccounts.findIndex((id) => id === assetId)
        if (index >= 0) {
          clone.balance.offsetAccounts.splice(index, 1)
        }
        commit(`modules/liabilities/updateObject`, clone, { root: true })
        this.$axios.put(`/api/v1/liabilities/` + clone.id, clone)
      }
    })
  },
}
