import CrudStore from '~/store/modules/common/crudStore'

var crud = new CrudStore('superannuations', [
  {
    name: 'Accumulation',
    value: 'ACCUMULATION',
  },
  {
    name: 'Account Based Pension',
    value: 'ACCOUNT_BASED_PENSION',
  },
  {
    name: 'Defined Benefit',
    value: 'DEFINED_BENEFIT',
  },
  {
    name: 'Defined Pension',
    value: 'DEFINED_PENSION',
  },
  {
    name: 'Superannuation Annuity',
    value: 'SUPER_ANNUITY',
  },
])

export const state = () => ({
  ...crud.state,
  blankContribution: null,
  selectedExtra: [],
  blankMemberBalance: null,
  blankAnnualAmount: null,
  blankTransferBalanceCap: null,
  blankAnnuityTableItem: null,
  extraOptions: [],
  //This to be removed unti the app 'Investments' is being worked on -crispy 28/11/2022
  // extraOptions: [
  //   { name: 'Holdings', value: 'HOLDING' },
  //   { name: 'Fees', value: 'FEES' },
  // ],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters, rootState) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName

    // Prodider Product - Description
    const name = $nuxt.$utils.concatStrings(' ', obj?.summary?.superFund, obj?.summary?.productName)
    return $nuxt.$utils.concatStrings(' - ', name, obj?.summary?.description)
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    const superannuation = getters.getObject(id)
    if (!superannuation) return ''

    const ownerName = $nuxt.$utils.getDisplayName(superannuation?.summary?.owner)
    return $nuxt.$utils.concatStrings(' - ', ownerName === 'Unnamed' ? '' : ownerName, $nuxt.$utils.capitaliseWords(superannuation?.type))
  },
  // List of all superannuations, with a name property added. Use this in link-inputs
  // Must pass this.$utils as argument
  getConcat: (state, getters, rootState, rootGetters) => (utils) => {
    const supers = _.cloneDeep(rootGetters[`modules/super/getItems`])
    if (!Array.isArray(supers)) return []

    return supers.map((_super) => {
      _super.name = 'New Super'
      if (_super.provider) {
        return {
          ..._super,
          name: utils.getDisplayName(_super.provider),
        }
      }
      if (_super.providerId) {
        return {
          ..._super,
          name: utils.getDisplayName(getDisplayName_super.providerId) + (_super.ownership ? ` (${utils.getDisplayName(_super.ownership)})` : ''),
        }
      }
      if (_super.ownership) {
        return {
          ..._super,
          name: utils.getDisplayName(_super.ownership),
        }
      }
    })
  },
}

export const mutations = {
  ...crud.mutations,
  setSelectedExtra(state, type) {
    // Type can be the whole object (given when first selecting) or the data when a field is updated
    state.selectedExtra = []
    if (type == null) state.selectedExtra = []
    else if (type.type && (type.type === 'ACCUMULATION' || type.type === 'ACCOUNT_BASED_PENSION')) {
      // if(type.smsf === false) state.selectedExtra = state.extraOptions
    } else if (type === false) {
      state.selectedExtra = state.extraOptions
    }
  },
}

export const actions = {
  ...crud.actions,
  async getBlankContribution({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankContribution', endpoint: '/api/v1/superannuations/new/contribution' }, { root: true })
  },
  async getBlankTransferBalanceCap({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankTransferBalanceCap', endpoint: '/api/v1/general/blank/TransferBalanceCap' }, { root: true })
  },
  async getBlankAnnualAmount({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankAnnualAmount', endpoint: '/api/v1/general/blank/AnnualAmount' }, { root: true })
  },
  async getBlankAnnuityTableItem({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankAnnuityTableItem', endpoint: '/api/v1/general/blank/AnnualAmount' }, { root: true })
  },
  async getBlankMemberBalance({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankMemberBalance', endpoint: '/api/v1/general/blank/MemberBalance' }, { root: true })
  },
}
