import FilterStore from './common/filterStore'

const crud = new FilterStore('tasks', null, false, true)

export const state = () => ({
  ...crud.state,
  noSidebar: true,
  pendingScheduledData: null,
  pendingParentTask: null,
  newTemplates: {
    task: null,
    template: null,
  },
  actionStatus: [],

  /**
   * @override
   */
  segments: ["Due Date", "Status"],
  editMode: false,
  topBarButtons: [
    { name: 'Tasks', link: '/tasks' },
    { name: 'Template Tasks', link: '/tasks/templates' },
  ],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    return obj?.name
  },
  getItems(state, getters) {
    let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
    return items // DON'T Sort the items by default
  },
  getTasks(state, getters, rootState) {
    return getters['getAllItems'].filter((el) => (!el.deleted || el.deleted === 'ARCHIVED') && !el.isTemplate)
  },
  getTemplateTasks(state, getters, rootState) {
    return getters['getAllItems'].filter((el) => (!el.deleted || el.deleted === 'ARCHIVED') && el.isTemplate)
  },
  areFiltersActive: (state) => {
    return state.filters.task.assignees.length > 0 || state.filters.task.statuses.length > 0 || state.filters.task.segment != undefined
  }
}

export const mutations = {
  ...crud.mutations,
  setPendingParentTask: (state, task) => {
    state.pendingParentTask = task
  },
  setPendingScheduleData: (state, scheduleData) => {
    state.pendingScheduledData = scheduleData
  },
  clearPending: (state) => {
    state.pendingParentTask = null
    state.pendingScheduledData = null
  },
  addItems(state, items) {
    state.allItems = [...state.allItems, ...items]
  },
}
export const actions = {
  ...crud.actions,
  getActionStatus: (state) => (id) => {
    // Statuses not tied to object for file notes because they just have active/inactive
    if (['fileNotes'].includes(id?.type)) {
      return id?.isComplete ? 'COMPLETED' : 'IN_PROGRESS'
    }
    if (typeof id === 'object' && _.has(id, 'lookupId') && !_.isNil(id.lookupId)) id = id?.lookupId
    else if (typeof id === 'object' && _.has(id, 'id')) id = id?.id
    return state.actionStatus.find((f) => f.id === id)?.status ?? 'NOT_STARTED'
  },
  /**
   * @override
   */
  async patchStatus({ commit }, { id, status }) {
    if (!status) return
    commit('updateField', { id: id, field: 'status', value: status })
    await this.$axios.patch(`/api/v1/tasks/status/${id}`, null, {
      params: {
        status: status,
      },
    })
  },
  async getTasksByIds({ commit }, ids) {
    let tasks = await this.$axios.$get(`/api/v1/tasks?ids=${ids}`)
    commit('mergeItems', tasks)
    return tasks
  },
  async saveNewTask({ state, dispatch, commit }, task) {
    let taskClone = _.cloneDeep(task)
    taskClone.dateCreated = new Date()
    taskClone.dueDate = taskClone.dueDate == null || taskClone.dueDate == 'Invalid date' || taskClone.dueDate == '' ? null : $nuxt.$utils.formatDateForSever(taskClone.dueDate)
    const kanbanStageIds = taskClone.kanbanStageIds
    const newTask = _.cloneDeep(await this.$axios.$post(`/api/v1/tasks`, taskClone))
    if(!_.isNil(kanbanStageIds)){
      dispatch('modules/kanbans/fetchIds', kanbanStageIds.map((el) => el.split(':')[0]),{root:true})
    }
    commit('addObject', newTask)

    return newTask
  },
  async updateTask({ commit, dispatch, state }, task) {

    // when updating, ensure to filter out old or deleted ids
    let _task = _.cloneDeep(task)
    _task.dueDate = _task.dueDate == null || _task.dueDate == 'Invalid date' || _task.dueDate == '' ? null : $nuxt.$utils.formatDateForSever(_task.dueDate)

    commit('updateObject', _task)
    _task = await this.$axios.$put(`/api/v1/tasks/${task.id}`, _task)
    // Update scheduling after returning result because it's calculated in the backend
    commit('updateObjectInCurrentContent', _task)
    commit('updateField', { id: _task.id, field: 'scheduledTask', value: _task.scheduledTask })
    return _task
  },
  async delete({ state, dispatch, commit}, ids) {
    if (!Array.isArray(ids)) ids = [ids]
    if (ids.length === 0) return

    // Remove objects from state
    try {
      commit('removeObjects', ids)
      return await this.$axios.delete(`/api/v1/tasks?ids=${ids.join(',')}`)
    } catch (e) {
      return e
    }
  },
  async onStompUpdate({ getters, commit }, stompProps) {
    const routeName = this.$router?.currentRoute?.name
    const data = stompProps?.data
    if (!data) return
    // If on tasks just update comments to avoid messing with the displayed tasks in the table
    if (routeName && routeName.includes('tasks')) {
      commit('updateField', { id: data.id, field: 'comments', value: data?.comments })
      this.$bus.$emit('comments-update', { id: data.id, comments: data.comments })
    } else {
      // Any other route update the whole object
      commit('updateObject', data)
    }
  },
  onStompAdd({}) {
    // Do nothing on stomp add event
  },
}
