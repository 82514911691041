const ElanDocumentType = {
  OBJECT: 'OBJECT',
  CUSTOM: 'CUSTOM',
  TABLE: 'TABLE',
}

export const state = () => ({
  elements: [],
})

export const getters = {}

export const mutations = {
  addElement: (state, ele) => {
    state.elements.push(ele)
  },
  addElements: (state, eles) => {
    state.elements = eles
  },
  updateElement: (state, ele) => {
    state.elements.splice(
      state.elements.findIndex((el) => el.id === ele.id),
      1,
      ele
    )
  },
  deleteElement: (state, eleId) => {
    state.elements.splice(
      state.elements.findIndex((el) => el.id === eleId),
      1
    )
  },
}

export const actions = {
  async getElements({ commit }) {
    commit('addElements', (await this.$axios.get(`/api/v1/element/all`)).data)
  },
  async getElementsBySectionId({}, sectionId) {
    return (await this.$axios.get(`/api/v1/element/getElements/${sectionId}`)).data
  },
  async getElementsBySectionIds({}, sectionIds) {
    if (!Array.isArray(sectionIds)) return
    return (await this.$axios.get(`/api/v1/element/getElements?sectionIds=${sectionIds.join('&sectionIds=')}`)).data
  },
  async saveElement({ commit }, element) {
    const newElement = (await this.$axios.post(`/api/v1/element`, element)).data
    commit('addElement', newElement)
    return newElement
  },
  async updateElement({ commit }, element) {
    await this.$axios.put(`/api/v1/element/${element.id}`, element)
    commit('updateElement', element)
    return element
  },
  async updateElements({ commit, state }, elements) {
    const element = _.difference(elements, state.elements)

    if (!element[0]) return

    const newElement = (await this.$axios.post(`/api/v1/element`, element[0])).data
    commit('addElement', newElement)
    return newElement
  },
  async deleteElement({ commit, state }, elementId) {
    return await this.$axios.post(`/api/v1/element/deletes`, [elementId]).then((res) => {
      if (res?.status === 200) {
        commit('deleteElement', elementId)
        return true
      }
      return false
    })
  },

  async checkUniqueElementName({ state }, name) {
    if (name.trim() == '' || name === null) return false
    let found = state.elements.find((el) => el.name === name)
    if (found) return false
    return (await this.$axios.post(`/api/v1/element/isUniqueName/${name}`)).data
  },
}
