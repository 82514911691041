export const state = () => ({
  guides: {
    general: [
      {
        name: 'Elan interface tour',
        id: 'QO8gTHMlxK19iO2VfTGc4VhHAOo',
        path: '/',
        sandbox: false,
      },
      {
        name: 'Elan interface tour',
        id: 'cAEMYtAMxpi5FcNVPbnZ32ZQJqg',
        path: '/',
        sandbox: true,
      },
    ],
    configurationHub: [
      {
        name: 'Configuration Hub tour',
        id: 'KRYk1h26iDIiaLcTsWlQPH278jo',
        path: '/configurationHub',
        roles: ['standard'],
      },
      {
        name: 'Configuration Hub tour',
        id: 'RdQRc1ibNfxOjOnbjc2wZEFEMqg',
        path: '/configurationHub',
        roles: ['admin', 'org_owner', 'elan_staff'],
      },
    ],
    clientData: [],
  },
})

export const getters = {
  getGuides(state, getters, rootState, rootGetters) {
    const isSandbox = _.get($nuxt, 'context.$config.sandbox')
    let userRoles = rootGetters[`auxiliary/user/getUserRoles`]($nuxt.$auth.user.sub)
    if (Array.isArray(userRoles)) userRoles = userRoles.map((el) => el.name)
    else userRoles = []

    const filteredGuides = {}
    for (const key in state.guides) {
      filteredGuides[key] = state.guides[key].filter((item) => {
        // Filter out guides that the user shouldn't be able to view
        if (isSandbox && item.sandbox === false) return false
        else if (!isSandbox && item.sandbox === true) return false
        else if (item.roles && !item.roles.some((el) => userRoles.includes(el))) return false
        return true
      })
    }
    return filteredGuides
  },
  getFlatGuides(state) {
    return _.flatMap(Object.values(state.guides), (el) => el)
  },
}

export const mutations = {}

export const actions = {
  startGuide({ getters }, { guideId, navigate }) {
    if (!guideId) return
    const guide = getters['getFlatGuides'].find((el) => el.id === guideId)
    if (!guide) return

    if (pendo) {
      // Navigate to guide URL (if necessary) and set pendo query
      if (navigate && guide.path) this.$router.push({ path: guide.path, query: { pendo: guide.id } })
      else this.$router.push({ query: { pendo: guide.id } })
    }
  },
}
