export default {
  head() {
    return {
      // Properties for nuxt.config.js head
      title: this.title,
      link: [{ hid: 'icon', rel: 'icon', type: 'image/svg', href: `/icons/Elan.svg` }],
    }
  },
  computed: {
    title() {
      const defaultTitle = 'Elan 2'

      const route = this.$route
      if (!route || route.path === '/') return defaultTitle

      const module = (this.$route?.name?.split('-') || [''])[0]
      if (!module) return defaultTitle

      const id = route?.params?.id
      let selectedName = null
      if (id) selectedName = this.$store.getters[`modules/${module}/getLabel`](id)
      else if (module === 'entityGroups') selectedName = this.$store.getters[`modules/${module}/getSelectedGroupLabel`]

      let text = this.$store.getters[`application/appData/getNameFromValue`](module)
      if (selectedName && selectedName !== 'Unnamed') text += ' - ' + selectedName
      // config hub submodule
      const append = this.$route.params?.settings ? this.$utils.capitaliseWords(this.$route.params.settings) : ''
      return defaultTitle + ' - ' + text + (append ? ' - ' + append : '')
    },
  },
}
