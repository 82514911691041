import Vue from 'vue'
import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('professionals', null, false, true)

export const state = () => ({
  ...crud.state,
  professionalEntityGroupMap: {},
  noSidebar: true,
})

export const getters = {
  ...crud.getters,
  getClientsWhoUse: (state) => (id) => {
    return state.professionalEntityGroupMap[id]
  },
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = typeof id === 'object' ? id : rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const professional = cachedObj ? _.cloneDeep(cachedObj) : getters['getAllItems'].find((el) => el.id === id) // If not found in cache, try find it
    if (!professional) return ''
    return professional.summary.organisationName
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const professional = cachedObj ? _.cloneDeep(cachedObj) : getters['getAllItems'].find((el) => el.id === id) // If not found in cache, try find it
    if (!professional) return ''
    const configServices = _.get(rootGetters[`modules/configurationHub/getConfig`]('professionals'), 'services.items', [])
    return professional.summary.servicesOffered
      .map((id) => configServices.find((service) => service.id === id)?.name)
      .filter((el) => !_.isNil(el))
      .join(', ')
  },
  getServicesOffered: (state, getters, rootState, rootGetters) => (id) => {
    const professional = state.allItems.find((el) => el.id === id)
    const services = _.get(professional, 'summary.servicesOffered', [])
    const configServices = _.get(rootGetters[`modules/configurationHub/getConfig`]('professionals'), 'services.items', [])
    return configServices.filter((el) => services.includes(el.id))
  },
  getRepresentativesAvailable: (state) => (id) => {
    const professional = state.allItems.find((el) => el.id === id)
    return _.get(professional, 'representatives.representatives', [])
  },
}

export const mutations = {
  ...crud.mutations,
  setClientsWhoUse(state, { id, data }) {
    if (!Array.isArray(data)) data = [data]
    Vue.set(state.professionalEntityGroupMap, id, data)
  },
}

export const actions = {
  ...crud.actions,
  async getAllWhoUse({ state, commit }, id) {
    commit('setClientsWhoUse', { id, data: await this.$axios.$get(`/api/v1/professionals/getAllClientsWhoUse/${id}`) })
  },
}
