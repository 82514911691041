
export default {
  data () {
    return {
      dialog: false,
      hasBeenBooted: false,
      time: 0
    }
  },
  computed: {
    user() {
      return _.get(this.$store.state, 'auth.user.email', '')
    }
  },
  mounted () {
    this.$bus.$on('forceLogoutModal', () => {
      if (this.hasBeenBooted) return

      this.dialog = true
      this.hasBeenBooted = true

      setInterval(() => {
        if (this.time < 4000) {
          this.time += 100
        }
      }, 100)

      setTimeout(async () => {
        await this.$store.dispatch('auxiliary/user/logout')
      }, 4000)
    })
  }
}
