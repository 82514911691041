import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('governmentBenefits', null, true)

export const state = () => ({
  ...crud.state,
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    const defaultName = 'Government Benefit'
    return defaultName // No need for labels in single page modules? idk
    const obj = getters.getObject(id)
    if (!obj) return defaultName

    return 'Government Benefit'
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const govBenefit = cachedObj ? cachedObj : _.get(getters['getItems'], 'tables.items', []).find((el) => el.id === id) // If not found in cache, try find it
    if (!govBenefit) return ''

    let displayText = []
    if (govBenefit.subType) displayText.push($nuxt.$utils.capitaliseWords(govBenefit.subType))
    if (govBenefit.ownership) displayText.push($nuxt.$utils.getDisplayName(govBenefit.ownership))
    else if (_.get(govBenefit, 'splitOwnership.items', []).length > 0) displayText.push($nuxt.$utils.getOwnershipString(govBenefit.splitOwnership))
    if (_.get(govBenefit, 'benefits', []).length > 0) displayText.push($nuxt.$utils.moneyDisplay(getters[`sumAnnualisedBenefits`](govBenefit.benefits)))
    return $nuxt.$utils.concatStrings(' — ', displayText)
  }
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
}
