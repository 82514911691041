
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.error) {
        const errorDetails = {
          name: 'Error',
          message: this.error.message || 'An unknown error occurred',
          path: this.$route.path,
          statusCode: this.error.statusCode,
        };

        this.$faro.api.pushError(new Error(JSON.stringify(errorDetails)), {
          skipDedupe: false,
          type: 'Error',
          context: {
            environment: process.env.NODE_ENV,
            application: 'Elan Frontend',
          },
        });
    }
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    }
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}
