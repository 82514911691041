import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _14bac116 = () => interopDefault(import('../pages/assets/index.vue' /* webpackChunkName: "pages/assets/index" */))
const _365f3eaa = () => interopDefault(import('../pages/clientPortal/index.vue' /* webpackChunkName: "pages/clientPortal/index" */))
const _bd485e9c = () => interopDefault(import('../pages/configurationHub/index.vue' /* webpackChunkName: "pages/configurationHub/index" */))
const _91b42018 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _1ac47cea = () => interopDefault(import('../pages/emails/index.vue' /* webpackChunkName: "pages/emails/index" */))
const _65d1663f = () => interopDefault(import('../pages/employment/index.vue' /* webpackChunkName: "pages/employment/index" */))
const _625631d4 = () => interopDefault(import('../pages/entities/index.vue' /* webpackChunkName: "pages/entities/index" */))
const _b808a3ac = () => interopDefault(import('../pages/entityGroups/index.vue' /* webpackChunkName: "pages/entityGroups/index" */))
const _bf862d98 = () => interopDefault(import('../pages/fileManager/index.vue' /* webpackChunkName: "pages/fileManager/index" */))
const _62731a48 = () => interopDefault(import('../pages/fileNotes/index.vue' /* webpackChunkName: "pages/fileNotes/index" */))
const _c5de259c = () => interopDefault(import('../pages/gantts/index.vue' /* webpackChunkName: "pages/gantts/index" */))
const _3b274dbf = () => interopDefault(import('../pages/goalsEventsMilestones/index.vue' /* webpackChunkName: "pages/goalsEventsMilestones/index" */))
const _7ab4fe40 = () => interopDefault(import('../pages/governmentBenefits/index.vue' /* webpackChunkName: "pages/governmentBenefits/index" */))
const _6f2de037 = () => interopDefault(import('../pages/incomeExpenses/index.vue' /* webpackChunkName: "pages/incomeExpenses/index" */))
const _588972ec = () => interopDefault(import('../pages/insurances/index.vue' /* webpackChunkName: "pages/insurances/index" */))
const _074c22b9 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _cfc8f602 = () => interopDefault(import('../pages/kanbans/index.vue' /* webpackChunkName: "pages/kanbans/index" */))
const _808976e4 = () => interopDefault(import('../pages/liabilities/index.vue' /* webpackChunkName: "pages/liabilities/index" */))
const _cf337916 = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _26126a0f = () => interopDefault(import('../pages/professionals/index.vue' /* webpackChunkName: "pages/professionals/index" */))
const _bc3d3bd2 = () => interopDefault(import('../pages/requests/index.vue' /* webpackChunkName: "pages/requests/index" */))
const _e4b958fa = () => interopDefault(import('../pages/sharedFolders/index.vue' /* webpackChunkName: "pages/sharedFolders/index" */))
const _772f8d30 = () => interopDefault(import('../pages/superannuations/index.vue' /* webpackChunkName: "pages/superannuations/index" */))
const _6b7e73f1 = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _7c7c6577 = () => interopDefault(import('../pages/workflows/index.vue' /* webpackChunkName: "pages/workflows/index" */))
const _2df5685e = () => interopDefault(import('../pages/emails/templates.vue' /* webpackChunkName: "pages/emails/templates" */))
const _534a0a2f = () => interopDefault(import('../pages/fileNotes/templates.vue' /* webpackChunkName: "pages/fileNotes/templates" */))
const _d8df4fce = () => interopDefault(import('../pages/gantts/templates.vue' /* webpackChunkName: "pages/gantts/templates" */))
const _044de0a3 = () => interopDefault(import('../pages/invoices/templates/index.vue' /* webpackChunkName: "pages/invoices/templates/index" */))
const _54c04166 = () => interopDefault(import('../pages/kanbans/templates.vue' /* webpackChunkName: "pages/kanbans/templates" */))
const _41abea9e = () => interopDefault(import('../pages/management/account.vue' /* webpackChunkName: "pages/management/account" */))
const _30f8f362 = () => interopDefault(import('../pages/management/settings.vue' /* webpackChunkName: "pages/management/settings" */))
const _4a1e4ef9 = () => interopDefault(import('../pages/requests/sections.vue' /* webpackChunkName: "pages/requests/sections" */))
const _1fa1d27e = () => interopDefault(import('../pages/requests/templates.vue' /* webpackChunkName: "pages/requests/templates" */))
const _63633150 = () => interopDefault(import('../pages/tasks/templates.vue' /* webpackChunkName: "pages/tasks/templates" */))
const _6a5e15d9 = () => interopDefault(import('../pages/workflows/nodes/index.vue' /* webpackChunkName: "pages/workflows/nodes/index" */))
const _35504de1 = () => interopDefault(import('../pages/workflows/templates/index.vue' /* webpackChunkName: "pages/workflows/templates/index" */))
const _32aa5e8d = () => interopDefault(import('../pages/management/auth/signIn.vue' /* webpackChunkName: "pages/management/auth/signIn" */))
const _6fb27b96 = () => interopDefault(import('../pages/management/auth/signOut.vue' /* webpackChunkName: "pages/management/auth/signOut" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1211afcb = () => interopDefault(import('../pages/invoices/templates/_id.vue' /* webpackChunkName: "pages/invoices/templates/_id" */))
const _06aa5489 = () => interopDefault(import('../pages/workflows/templates/_id.vue' /* webpackChunkName: "pages/workflows/templates/_id" */))
const _3ddbde04 = () => interopDefault(import('../pages/assets/_id.vue' /* webpackChunkName: "pages/assets/_id" */))
const _d6334d3c = () => interopDefault(import('../pages/configurationHub/_settings.vue' /* webpackChunkName: "pages/configurationHub/_settings" */))
const _2eeece65 = () => interopDefault(import('../pages/configurationHub/_settings/index.vue' /* webpackChunkName: "pages/configurationHub/_settings/index" */))
const _323651b3 = () => interopDefault(import('../pages/configurationHub/_settings/_suboption.vue' /* webpackChunkName: "pages/configurationHub/_settings/_suboption" */))
const _0f7e9c5c = () => interopDefault(import('../pages/emails/_id.vue' /* webpackChunkName: "pages/emails/_id" */))
const _1ab1ec67 = () => interopDefault(import('../pages/employment/_id.vue' /* webpackChunkName: "pages/employment/_id" */))
const _63ae993c = () => interopDefault(import('../pages/entities/_id.vue' /* webpackChunkName: "pages/entities/_id" */))
const _2c133312 = () => interopDefault(import('../pages/entityGroups/_id.vue' /* webpackChunkName: "pages/entityGroups/_id" */))
const _a9d9e2a0 = () => interopDefault(import('../pages/fileNotes/_id.vue' /* webpackChunkName: "pages/fileNotes/_id" */))
const _0c06d7cc = () => interopDefault(import('../pages/gantts/_id.vue' /* webpackChunkName: "pages/gantts/_id" */))
const _735bb3e7 = () => interopDefault(import('../pages/goalsEventsMilestones/_id.vue' /* webpackChunkName: "pages/goalsEventsMilestones/_id" */))
const _715e3b48 = () => interopDefault(import('../pages/governmentBenefits/_id.vue' /* webpackChunkName: "pages/governmentBenefits/_id" */))
const _476f4054 = () => interopDefault(import('../pages/insurances/_id.vue' /* webpackChunkName: "pages/insurances/_id" */))
const _72c57f61 = () => interopDefault(import('../pages/invoices/_id.vue' /* webpackChunkName: "pages/invoices/_id" */))
const _3274bb27 = () => interopDefault(import('../pages/kanbans/_id.vue' /* webpackChunkName: "pages/kanbans/_id" */))
const _0ecd1276 = () => interopDefault(import('../pages/liabilities/_id.vue' /* webpackChunkName: "pages/liabilities/_id" */))
const _1ffcb792 = () => interopDefault(import('../pages/professionals/_id.vue' /* webpackChunkName: "pages/professionals/_id" */))
const _34820382 = () => interopDefault(import('../pages/requests/_id.vue' /* webpackChunkName: "pages/requests/_id" */))
const _729f1b98 = () => interopDefault(import('../pages/superannuations/_id.vue' /* webpackChunkName: "pages/superannuations/_id" */))
const _4003599f = () => interopDefault(import('../pages/workflows/_id.vue' /* webpackChunkName: "pages/workflows/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/assets",
    component: _14bac116,
    name: "assets"
  }, {
    path: "/clientPortal",
    component: _365f3eaa,
    name: "clientPortal"
  }, {
    path: "/configurationHub",
    component: _bd485e9c,
    name: "configurationHub"
  }, {
    path: "/dashboard",
    component: _91b42018,
    name: "dashboard"
  }, {
    path: "/emails",
    component: _1ac47cea,
    name: "emails"
  }, {
    path: "/employment",
    component: _65d1663f,
    name: "employment"
  }, {
    path: "/entities",
    component: _625631d4,
    name: "entities"
  }, {
    path: "/entityGroups",
    component: _b808a3ac,
    name: "entityGroups"
  }, {
    path: "/fileManager",
    component: _bf862d98,
    name: "fileManager"
  }, {
    path: "/fileNotes",
    component: _62731a48,
    name: "fileNotes"
  }, {
    path: "/gantts",
    component: _c5de259c,
    name: "gantts"
  }, {
    path: "/goalsEventsMilestones",
    component: _3b274dbf,
    name: "goalsEventsMilestones"
  }, {
    path: "/governmentBenefits",
    component: _7ab4fe40,
    name: "governmentBenefits"
  }, {
    path: "/incomeExpenses",
    component: _6f2de037,
    name: "incomeExpenses"
  }, {
    path: "/insurances",
    component: _588972ec,
    name: "insurances"
  }, {
    path: "/invoices",
    component: _074c22b9,
    name: "invoices"
  }, {
    path: "/kanbans",
    component: _cfc8f602,
    name: "kanbans"
  }, {
    path: "/liabilities",
    component: _808976e4,
    name: "liabilities"
  }, {
    path: "/mobile",
    component: _cf337916,
    name: "mobile"
  }, {
    path: "/professionals",
    component: _26126a0f,
    name: "professionals"
  }, {
    path: "/requests",
    component: _bc3d3bd2,
    name: "requests"
  }, {
    path: "/sharedFolders",
    component: _e4b958fa,
    name: "sharedFolders"
  }, {
    path: "/superannuations",
    component: _772f8d30,
    name: "superannuations"
  }, {
    path: "/tasks",
    component: _6b7e73f1,
    name: "tasks"
  }, {
    path: "/workflows",
    component: _7c7c6577,
    name: "workflows"
  }, {
    path: "/emails/templates",
    component: _2df5685e,
    name: "emails-templates"
  }, {
    path: "/fileNotes/templates",
    component: _534a0a2f,
    name: "fileNotes-templates"
  }, {
    path: "/gantts/templates",
    component: _d8df4fce,
    name: "gantts-templates"
  }, {
    path: "/invoices/templates",
    component: _044de0a3,
    name: "invoices-templates"
  }, {
    path: "/kanbans/templates",
    component: _54c04166,
    name: "kanbans-templates"
  }, {
    path: "/management/account",
    component: _41abea9e,
    name: "management-account"
  }, {
    path: "/management/settings",
    component: _30f8f362,
    name: "management-settings"
  }, {
    path: "/requests/sections",
    component: _4a1e4ef9,
    name: "requests-sections"
  }, {
    path: "/requests/templates",
    component: _1fa1d27e,
    name: "requests-templates"
  }, {
    path: "/tasks/templates",
    component: _63633150,
    name: "tasks-templates"
  }, {
    path: "/workflows/nodes",
    component: _6a5e15d9,
    name: "workflows-nodes"
  }, {
    path: "/workflows/templates",
    component: _35504de1,
    name: "workflows-templates"
  }, {
    path: "/management/auth/signIn",
    component: _32aa5e8d,
    name: "management-auth-signIn"
  }, {
    path: "/management/auth/signOut",
    component: _6fb27b96,
    name: "management-auth-signOut"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/invoices/templates/:id",
    component: _1211afcb,
    name: "invoices-templates-id"
  }, {
    path: "/workflows/templates/:id",
    component: _06aa5489,
    name: "workflows-templates-id"
  }, {
    path: "/assets/:id",
    component: _3ddbde04,
    name: "assets-id"
  }, {
    path: "/configurationHub/:settings",
    component: _d6334d3c,
    children: [{
      path: "",
      component: _2eeece65,
      name: "configurationHub-settings"
    }, {
      path: ":suboption",
      component: _323651b3,
      name: "configurationHub-settings-suboption"
    }]
  }, {
    path: "/emails/:id",
    component: _0f7e9c5c,
    name: "emails-id"
  }, {
    path: "/employment/:id",
    component: _1ab1ec67,
    name: "employment-id"
  }, {
    path: "/entities/:id",
    component: _63ae993c,
    name: "entities-id"
  }, {
    path: "/entityGroups/:id",
    component: _2c133312,
    name: "entityGroups-id"
  }, {
    path: "/fileNotes/:id",
    component: _a9d9e2a0,
    name: "fileNotes-id"
  }, {
    path: "/gantts/:id",
    component: _0c06d7cc,
    name: "gantts-id"
  }, {
    path: "/goalsEventsMilestones/:id",
    component: _735bb3e7,
    name: "goalsEventsMilestones-id"
  }, {
    path: "/governmentBenefits/:id",
    component: _715e3b48,
    name: "governmentBenefits-id"
  }, {
    path: "/insurances/:id",
    component: _476f4054,
    name: "insurances-id"
  }, {
    path: "/invoices/:id",
    component: _72c57f61,
    name: "invoices-id"
  }, {
    path: "/kanbans/:id",
    component: _3274bb27,
    name: "kanbans-id"
  }, {
    path: "/liabilities/:id",
    component: _0ecd1276,
    name: "liabilities-id"
  }, {
    path: "/professionals/:id",
    component: _1ffcb792,
    name: "professionals-id"
  }, {
    path: "/requests/:id",
    component: _34820382,
    name: "requests-id"
  }, {
    path: "/superannuations/:id",
    component: _729f1b98,
    name: "superannuations-id"
  }, {
    path: "/workflows/:id",
    component: _4003599f,
    name: "workflows-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
