export const state = () => ({
  customer: null,
  available_subscription_plans: {},
  receipts: null,
  setup_intent: {},
  checkout_session: null,
  loading_checkout_session: false,
  loading_extension: false,
  upgradeDialog: false,
})

export const mutations = {
  setCheckoutSession: (state, data) => {
    state.checkout_session = data
  },
  updateCustomer: (state, data) => {
    state.customer = data
  },
  updateReceipts: (state, data) => {
    if (Array.isArray(data)) state.receipts = data
    else state.receipts = []
  },
  setUpgradeDialog: (state, payload) => {
    state.upgradeDialog = payload
  },
  setLoading: (state, { key, value }) => {
    state[key] = value
  },
}

export const getters = {
  getCustomer: (state) => {
    return state.customer
  },
  getPaymentMethod: (state) => {
    return state.customer?.payment_method
  },
}

export const actions = {
  async getPortalSessionUrl({ state, commit, dispatch }) {
    return await this.$axios.get('/api/v1/stripe/customer_portal_session').then((res) => {
      return res.data
    })
  },
  async getProducts({ state, commit, dispatch }) {
    let products = await this.$axios.$get('/api/v1/stripe/products')
    return _.chain(products)
      .filter((item) => _.some(item.value, { lookupKey: 'practice_plan_monthly' }))
      .map((item) => ({
        id: item.id,
        value: _.filter(item.value, { lookupKey: 'practice_plan_monthly' }),
      }))
      .value()
  },
  async getCustomer({ state, commit, dispatch }) {
    await this.$axios.get('/api/v1/stripe/customer').then((res) => {
      if (res.data) {
        commit('updateCustomer', res.data)
      }
    })
  },
  async updateSubscriptionQuantity({ state, commit, dispatch }, data) {
    return (await this.$axios.put('/api/v1/stripe/subscription/quantity', data)).data
  },
  async calcUpcomingInvoice({ state, commit, dispatch }, data) {
    return (await this.$axios.get(`/api/v1/stripe/subscription/calc/upcomingInvoice?quantity=${data.quantity}&timestamp=${data.timestamp}`)).data
  },
  async getSubscriptionDetails({ state, commit, dispatch }) {
    await this.$axios.get('/api/v1/stripe/subscription_details').then((res) => {
      if (res.data) {
        commit('updateReceipts', res.data.receipts)
        commit('updateCustomer', res.data.customer)
      }
    })
  },
  async upgradeTrialDialog({ commit, dispatch, rootState }) {
    const usersInDemoOrg = _.cloneDeep(rootState?.auxiliary?.user?.org?.users || [])

    if (usersInDemoOrg.length === 0) return

    commit('setUpgradeDialog', true)
  },
  async createCheckoutSession({ commit }, { userIds, priceId }) {
    if (!Array.isArray(userIds)) return null
    commit('setLoading', { value: true, key: 'loading_checkout_session' })
    const message = await new Promise((resolve, reject) => {
      this.$axios.get('/api/v1/stripe/upgrade_checkout_session', { params: { userIds: userIds.join(','), priceId } }).then((res) => {
        if (res.status === 201) commit('setCheckoutSession', res.data)
        resolve(res.data)
      })
    })

    commit('setLoading', { value: false, key: 'loading_checkout_session' })

    if (!message?.startsWith('https')) {
      commit('application/snack/set', { type: 'error', message: message || 'An error occured when creating your checkout session. Try again soon' }, { root: true })
      return
    }

    const a = document.createElement('a')
    a.href = message
    a.target = '_blank'

    a.click()
    a.remove()
    // Close the "Select Users to Carry Over" dialog
    commit('setUpgradeDialog', false)
  },
  async requestTrialExtension({ state, commit }, payload) {
    if (state.loading_extension) return

    commit('setLoading', { value: true, key: 'loading_extension' })

    await this.$axios
      .post('/api/v1/stripe/extend_trial', payload)
      .then((result) => {
        commit('application/snack/set', { type: result.status === 200 || result.status === 201 ? 'success' : result.status === 204 ? 'info' : 'error', message: result.data }, { root: true })
      })
      .catch(({ response: result }) => {
        commit('application/snack/set', { type: result.status === 204 ? 'info' : 'error', message: result.data }, { root: true })
      })
      .finally(() => {
        commit('setLoading', { value: false, key: 'loading_extension' })
      })
  },
  async updateBillingDetails({ state, commit }, payload) {
    return await this.$axios.patch('/api/v1/stripe/customer/billing_details', payload).then((res) => {
      if (res.data) {
        commit('updateCustomer', res.data)
      }
      return res.status
    })
  },
  async getReceipts({ state, commit, dispatch }) {
    await this.$axios.get('/api/v1/stripe/customer/receipts').then((res) => {
      if (res.data) {
        commit('updateReceipts', res.data)
      }
    })
  },
}
