import FilterStore from './common/filterStore'
import _ from 'lodash'

const crud = new FilterStore('workflows', null, false, true)

export const state = () => ({
  ...crud.state,
  noSidebar: true,
  allNodeItems: [],
  // Linked nodeItem -> object (task/request etc) status
  nodeItemStatus: [],
  topBarButtons: [
    { name: 'Workflows', link: '/workflows' },
    { name: 'Template Workflows', link: '/workflows/templates' },
    { name: 'Template Nodes', link: '/workflows/nodes' },
  ],
  savingNodeIds: [],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    return obj?.name
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id, truncateOptions = { length: Number.MAX_VALUE }) => {
    const obj = getters.getObject(id)
    if (!obj) return ''

    const truncOpts = { length: Number.MAX_VALUE, omission: '...', separator: undefined }
    Object.assign(truncOpts, typeof truncateOptions == 'number' ? { length: truncateOptions } : truncateOptions)

    const clientGroup = $nuxt.$utils.getDisplayName(obj.clientGroupId)
    const entitiesString = !obj?.entities?.length ? undefined : obj.entities
      .map((el) => $nuxt.$utils.getDisplayName(el))
      .filter((el) => el != 'Unnamed')
      .join(', ')
    return _.truncate([clientGroup, entitiesString].filter(el => el != undefined).join(' - '), truncOpts)
  },
  getAllNodeItems: (state) => {
    return state.allNodeItems.filter((el) => !el.deleted || el.deleted !== 'DELETED')
  },
  isNodeSaving: (state) => (id) => {
    return state.savingNodeIds.includes(id)
  },
  getNodeItemStatus: (state) => (id) => {
    // Only show status after object is created
    if (typeof id === 'object' && !_.get(id, 'lookupId')) return null

    // Statuses not tied to object for file notes because they just have active/inactive
    if (['fileNotes'].includes(id?.type)) {
      return id?.isComplete ? 'COMPLETED' : 'IN_PROGRESS'
    }
    if (typeof id === 'object' && _.has(id, 'lookupId') && !_.isNil(id.lookupId)) id = id?.lookupId
    else if (typeof id === 'object' && _.has(id, 'id')) id = id?.id
    return state.nodeItemStatus.find((f) => f.id === id)?.status ?? 'NOT_STARTED'
  },
  getNodeItemStatuses: (state) => {
    return state.nodeItemStatus
  },
}
export const mutations = {
  ...crud.mutations,
  updateOpenNodes: (state, nodeId) => {
    state.openNode = nodeId
  },
  toggleSavingNodeId: (state, nodeId) => {
    state.savingNodeIds = _.xor(state.savingNodeIds, [nodeId])
  },
  updateNodeItemStatus: (state, item) => {
    const index = state.nodeItemStatus.findIndex((f) => f.id === item.id)
    if (index < 0) {
      state.nodeItemStatus.push(item)
      return
    }
    state.nodeItemStatus.splice(index, 1, item)
  },
  setNodeItemStatus: (state, items) => {
    state.nodeItemStatus = items
  },
  updateNode(state, { workflowId, node }) {
    let found = state.allItems.find((el) => el.id === workflowId)
    if (!found || found?.deleted) return
    const nodeIndex = found.nodeItems.findIndex((el) => el.id === node.id)
    if (nodeIndex >= 0) {
      found.nodeItems.splice(nodeIndex, 1, node)
    } else {
      found.nodeItems.push(node)
    }
  },
}
export const actions = {
  ...crud.actions,
  async getAllNodes({ state, commit, rootGetters, getters }, forceUpdate = false) {
    // Only fetch if data is stale
    commit('application/loading/setLoading', true, { root: true })
    commit('application/storeUtils/setItem', { localState: state, item: 'allNodeItems', value: await this.$axios.$get(`/api/v1/workflowNodes`) }, { root: true })
    commit('application/loading/setLoading', false, { root: true })
    return getters.getAllNodeItems
  },
  async refreshWorkflow({ state, commit }, workflowId) {
    let workflow = (await this.$axios.get(`/api/v1/workflows/${workflowId}`)).data
    if (workflow === '') return null
    else commit('application/storeUtils/modifyCollection', { collection: state.activeWorkflows, item: workflow, action: 'update' }, { root: true })
    return _.cloneDeep(workflow)
  },
  async createNewNode({ state, commit, dispatch }, node) {
    let result = await this.$axios.post(`/api/v1/workflowNodes`, node)
    commit('application/storeUtils/modifyCollection', { collection: state.allNodeItems, item: result.data, action: 'add' }, { root: true })
    return result.data
  },
  async deleteNode({ state, commit, dispatch }, nodeId) {
    commit('application/storeUtils/modifyCollection', { collection: state.allNodeItems, item: [nodeId], action: 'delete' }, { root: true })
    return await this.$axios.delete(`/api/v1/workflowNodes/${nodeId}`)
  },
  async saveNode({ state, commit, dispatch }, node) {
    commit('application/storeUtils/modifyCollection', { collection: state.allNodeItems, item: node, action: 'update' }, { root: true })
    return await this.$axios.$put(`/api/v1/workflowNodes/` + node.id, node)
  },
  setNodeItemStatus({ state, commit }, items) {
    commit('setNodeItemStatus', items)
  },
  updateNodeItemStatus({ state, commit }, item) {
    commit('updateNodeItemStatus', item)
  },
  debounceSaveNode: _.debounce(async function ({ state, dispatch, commit, getters }, node) {
    commit('toggleSavingNodeId', node.id)
    await dispatch('saveNode', _.cloneDeep(node))
    commit('toggleSavingNodeId', node.id)
  }, 1000),
  async onStompUpdate({ getters, commit, dispatch }, stompProps) {
    const data = _.cloneDeep(stompProps?.data)
    if (!data) return
    commit('updateObject', data)
    this.$bus.$emit('workflow-stomp-update', data)
  },
  onStompAdd({}) {
    // Do nothing on stomp add event
  },
}
