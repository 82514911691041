
import rulesMixin from '@/mixins/rules.js'

export default {
  mixins: [rulesMixin],
  data() {
    return {
      groupName: '',
      loading: false,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.$store.state.modules.entityGroups.createDialog?.show
      },
      set(value) {
        this.$store.commit(`modules/entityGroups/toggleCreateDialog`, { show: value, openTab: false })
      },
    },
  },
  methods: {
    async createGroup() {
      this.loading = true
      if (this.$refs.groupNameRef && !this.$refs.groupNameRef.validate()) {
        this.$forms.errorSnack(this.$refs.groupNameRef)
        this.loading = false
        return
      }
      const groupId = await this.$store.dispatch('modules/entityGroups/createEntityGroup', this.groupName)
      this.$bus.$emit('addRecentSearch', groupId)
      this.loading = false
      this.groupName = ''
      this.showDialog = false
    },
  },
}
