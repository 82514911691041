const middleware = {}

middleware['adminAccessManager'] = require('../middleware/adminAccessManager.js')
middleware['adminAccessManager'] = middleware['adminAccessManager'].default || middleware['adminAccessManager']

middleware['hasActiveSubscriptionManager'] = require('../middleware/hasActiveSubscriptionManager.js')
middleware['hasActiveSubscriptionManager'] = middleware['hasActiveSubscriptionManager'].default || middleware['hasActiveSubscriptionManager']

middleware['routeManager'] = require('../middleware/routeManager.js')
middleware['routeManager'] = middleware['routeManager'].default || middleware['routeManager']

middleware['selectionManager'] = require('../middleware/selectionManager.js')
middleware['selectionManager'] = middleware['selectionManager'].default || middleware['selectionManager']

middleware['theme'] = require('../middleware/theme.js')
middleware['theme'] = middleware['theme'].default || middleware['theme']

middleware['twoWayFieldLink'] = require('../middleware/twoWayFieldLink.js')
middleware['twoWayFieldLink'] = middleware['twoWayFieldLink'].default || middleware['twoWayFieldLink']

export default middleware
