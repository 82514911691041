export default {
  data() {
    return {
      requiredField: [(v) => (v && ((Array.isArray(v) && v.length > 0) || (typeof v === 'string' && v.trim().length !== 0) || (!Array.isArray(v) && typeof v === 'object' && !!v))) || 'Required field.'],
      emailRules: [
        //(v) => !!v || 'E-mail is required',
        (v) => this.regex.email.test(v) || this.isBlank(v) || 'E-mail must be in format email@domain.com',
      ],
      digitRules: [
        //(v) => !!v || 'Mobile number is required',
        (v) => this.validDigit(v) || 'Input must be digits',
      ],
      positiveRules: [
        (value) => {
          // Clear previous error messages
          const parsedValue = parseFloat(value)
          if (parsedValue < 0) {
            return 'Please enter a positive number'
          }
          return true
        },
      ],
      abnRules: [(v) => this.validAbn(v) || 'Input must be digits'],
      bsbRules: [(v) => this.validBsb(v) || 'Input must be digits'],
      tfnRules: [(v) => this.validTfn(v) || 'Input must be digits'],
      acnRules: [(v) => this.validAcn(v) || 'Input must be digits'],
      dateRules: [
        // Matches empty string (optional field) or correct date format
        (v) => this.isBlank(v) || this.$utils.validDate(v) || 'Must be in DD/MM/YYYY format',
      ],
      monthRules: [
        // Matches empty string (optional field) or correct date format
        (v) => this.isBlank(v) || this.$utils.validDate(v, true) || 'Must be in MM/YYYY format',
      ],
      yearRules: [(v) => (v?.trim().length === 4 && this.regex.digit.test(v)) || this.isBlank(v) || 'Invalid year'],
      decimalRules: [(v) => this.validDecimal(v) || 'Input must be numeric'],
      phoneRules: [(v) => this.isBlank(v) || this.$utils.isPhoneValid(v).valid || (this.$utils.isPhoneValid(v).example ? `Invalid, example: ${this.$utils.isPhoneValid(v).example}` : 'Invalid phone number.')],

      hexColourRules: [(v) => /^#([0-9A-F]{3}){1,2}$/i.test(v) || this.isBlank(v) || 'Invalid hexadecimal colour'],

      regex: {
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        digit: /^-?[\d,]*$/,
        decimal: /^-?[\d,]*\.?\d*$/,
        phone: /^(?:\+?(\d{2,3}))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
        s3Folder: /^(?!\.)(?!\/)[a-zA-Z0-9!*+._ -]*$/,
        // date: /^\d{1,2}\/\d{1,2}\/\d{4}?$/,
        // month: /^\d{1,2}\/\d{4}?$/,
      },
      s3Rules: [(v) => this.regex.s3Folder.test(typeof v === String ? v?.trim() : String(v)) || 'This is not a valid folder name'],
      duplicate(items, key) {
        return [(v) => this.checkDuplicate(v, items, key) || 'Please ensure all options are unique.']
      },
    }
  },
  methods: {
    // Allow blank data
    isBlank(input) {
      if (!input || String(input).trim().length === 0) return true
      return false
    },
    checkDuplicate(input, items, key) {
      return items.filter((f) => this._.isEqual(f[key], input)).length > 1 ? false : true
    },
    validBsb(input) {
      input = this.$utils.stripNumber(input)
      if (!this.validDigit(input)) return false

      return true // Don't both with input length validation
      // return input.length === 6 || this.isBlank(input)
    },
    validAcn(input) {
      input = this.$utils.stripNumber(input)
      if (!this.validDigit(input)) return false

      return true // Don't both with input length validation
      // return input.length === 9 || this.isBlank(input)
    },
    validTfn(input) {
      input = this.$utils.stripNumber(input).replace(/\s/g, '')
      if (!this.validDigit(input)) return false

      return true // Don't both with input length validation
      // return input.length === 9 || input.length === 8 || this.isBlank(input)
    },
    validAbn(input) {
      input = this.$utils.stripNumber(input).replace(/\s/g, '')
      if (!this.validDigit(input)) return false

      return true // Don't both with input length validation
      // return input.length === 11 || this.isBlank(input)
    },
    validDigit(input) {
      input = this.$utils.stripNumber(input)
      return this.regex.digit.test(typeof input === 'string' ? input?.trim() : String(input)) || this.isBlank(input)
    },
    validDecimal(input) {
      input = this.$utils.stripNumber(input)
      return this.regex.decimal.test(typeof input === String ? input?.trim() : String(input)) || this.isBlank(input)
    },
  },
  computed: {
    groupNameRule() {
      let groupNames = this._.get(this.$store.state, 'modules.entityGroups.allItems', [])
        .filter((el) => !_.has(el, 'deleted'))
        .map((el) => el?.summary?.name)
      return [(v) => (v && v.trim() !== '') || 'Required field.', (v) => (v && !groupNames.includes(v.trim())) || 'Name must be unique.']
    },
  },
}
