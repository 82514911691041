
export default {
  props: {
    title: {
      type: [String, Number],
      default: '404',
    },
    subtitle: {
      type: String,
      default: 'Page not found!',
    },
    description: {
      type: String,
      default: "Sorry, the page you're looking for doesn't exist or has been moved.",
    },
    homeUrl: {
      type: String,
      default: '/',
    },
  },
  methods: {
    navigateHome() {
      window.location.href = '/';
    },
  },
}
