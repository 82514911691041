
export default {
  props: {
    /**
     * Value to display as:
     * String to display or array of strings
     * UUID to get a name for or an array of UUIDs
     * Ownership object (combined with ownership prop)
     */
    value: {
      type: [String, Array, Object],
      default: '',
    },
    /**
     * Label to display
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Display as multiple chips
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * Display as object name combined with percentage
     */
    ownership: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    // If the value is a UUID get the object and the name for that UUID when rendered
    if (this.hasUUID) {
      this.$store.dispatch(`application/globalSearch/fetchIds`, this.fetchIds)
    }
  },
  computed: {
    hasUUID() {
      // If value is a UUID or it is an array with UUID's then try and fetch the objects from the IDs
      return this.$utils.isUUID(this.value) || this.ownership || (Array.isArray(this.value) && this.value.find((el) => this.$utils.isUUID(el)))
    },
    fetchIds() {
      if (!this.hasUUID) return null

      let lookupVal = this.value
      if (this.ownership) lookupVal = _.get(this.value, 'items', []).map((el) => el.name)
      else if (!Array.isArray(this.value)) lookupVal = [this.value]
      return lookupVal
    },
    displayValue() {
      let fetched = null
      if (this.hasUUID) {
        // Get objects from IDs
        fetched = this.$store.getters['application/globalSearch/getObjectsFromIds'](this.fetchIds)
      }
      if (this.ownership) {
        let namedObjs = _.get(this.value, 'items').map((el) => {
          // Map ownership IDs to names if needed
          let name = el.name
          const found = Array.isArray(fetched) && this.$utils.isUUID(el.name) ? fetched.find((obj) => _.get(obj, 'id') === el.name) : ''
          if (found) name = this.$utils.getDisplayName(found)
          return { name: name, percent: el.percentage }
        })
        return namedObjs.filter((el) => !this.$utils.isUUID(el.name))
      }
      // If there are objects that were fetched, map them, otherwise return the value as is
      return fetched ? fetched.map((el) => this.$utils.getDisplayName(el)) : this.value
    },
  },
}
