export const state = () => ({
  foldersPending: false,
  objectsPending: false,
  objects: {},
  folders: [],
})

export const mutations = {
  setPending(state, { key, payload }) {
    state[key] = payload
  },
  setObjects: (state, data) => {
    state.objects = data
  },
  setFolders: (state, data) => {
    state.folders = data
  },
}

export const actions = {
  async generateUploadUrl({}, { path }) {
    if (!path) return
    try {
      return await this.$axios.$get('/api/v1/s3/upload', { params: { path } })
    } catch (e) {
      return null
    }
  },
  async generateDownloadUrl({}, { path }) {
    if (!path) return
    try {
      return await this.$axios.$get('/api/v1/s3/download', { params: { path } })
    } catch (e) {
      return null
    }
  },
  async getObjects({ state, commit }, payload) {
    commit('setPending', { key: 'objectsPending', payload: true })
    await this.$axios.get('/api/v1/s3', { params: { path: payload || '' } })
      .then((res) => {
        if (res.data) {
          commit('setObjects', res.data)
          commit('setPending', { key: 'objectsPending', payload: false })
        }
      })
      .catch((err) => {
        commit('setPending', { key: 'objectsPending', payload: false })
      })
  },  
  async getFolderStructure({ commit }) {
    try {
      const { data } = await this.$axios.get('/api/v1/s3/structure')
      commit('setFolders', data)
    } catch (e) {}
  },
  async createFolder({ commit, dispatch }, payload) {
    commit('setPending', { key: 'foldersPending', payload: true })

    return new Promise((resolve, reject) => {
      this.$axios.post('/api/v1/s3/folder', payload)
        .then(async (res) => {
          commit('setPending', { key: 'foldersPending', payload: false })
          resolve(res)
        })
        .catch((err) => {
          commit('setPending', { key: 'foldersPending', payload: false })
          resolve(err.response)
        })
    })
  },
  async delete({ commit }, paths) {
    if (!Array.isArray(paths)) paths = [paths]
    commit('setPending', { key: 'deletePending', payload: true })

    return new Promise((resolve, reject) => {
      this.$axios
        .delete('/api/v1/s3', { data: { paths } })
        .then((res) => {
          commit('setPending', { key: 'deletePending', payload: false })
          resolve(res)
        })
        .catch((err) => {
          commit('setPending', { key: 'deletePending', payload: false })
          reject()
        })
    })
  },
  async deleteIfEmpty({ state, commit, dispatch, rootState }, path) {
    return new Promise((resolve, reject) => {
      this.$axios.delete('/api/v1/s3/folder', { params: { path: path ?? '' } })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async forceDelete({ state, commit, dispatch, rootState }, path) {
    return new Promise((resolve, reject) => {
      this.$axios.delete('/api/v1/s3/folder/force', { params: { path: path ?? '' } })
        .then(async (res) => {
          commit('setPending', { key: 'deletePending', payload: false })
          await dispatch('auxiliary/s3/getFolderStructure', null, { root: true })
          resolve(res)
        })
        .catch((err) => {
          commit('setPending', { key: 'deletePending', payload: false })
          reject(err)
        })
    })
  },

}