
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    stompMessage() {
      return this.$store.state.application.webStomp.stompMessage
    },
    eventName() {
      if (!this.stompMessage) return null
      return this.stompMessage.eventName.toLowerCase()
    },
    isUpdateEvent() {
      if (!this.eventName) return false
      return this.eventName.includes('update') || this.eventName.includes('patch')
    },
    isDeleteEvent() {
      if (!this.eventName) return false
      return this.eventName.includes('remove') || this.eventName.includes('archive')
    },
    selectedGroupIsDeleted() {
      return this.eventName.includes('removeentitygroup') && this.stompMessage.ids.includes(this.$store.getters[`modules/entityGroups/getSelectedEntityGroup`]?.id)
    },
    instigatorName() {
      const users = Array.isArray(this.$store.getters[`auxiliary/user/getMappedUsersInOrg`]) ? this.$store.getters[`auxiliary/user/getMappedUsersInOrg`] : []
      return users.find((el) => el.id === this.stompMessage?.instigator)?.name
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit(`application/webStomp/setStompMessage`, null)
    },
    async confirm() {
      const selectedModule = this.$route.name.split('-')[0]
      if (!selectedModule) return

      if (this.isUpdateEvent) {
        // Refresh data
        this.loading = true
        this.$store.commit(`modules/${selectedModule}/setEdit`, false)
        if (this.stompMessage?.data) {
          this.$store.commit(`modules/${selectedModule}/updateObject`, this.stompMessage.data) // Update with stomp message data if it exists
        } else {
          await this.$store.dispatch(`modules/${selectedModule}/fetchIds`, this.stompMessage.ids) // Fetch data if no stomp message data
        }
        this.loading = false
      } else if (this.isDeleteEvent) {
        // Navigate away
        if (this.selectedGroupIsDeleted) {
          this.$router.push({ path: '/' })
        } else {
          this.$store.commit(`modules/${selectedModule}/setEdit`, false)
          this.$router.push({ path: `/${selectedModule}` })
        }
      }
      // Remove edit and close
      this.closeDialog()
    },
  },
}
