import _ from 'lodash'
import Vue from 'vue'

export const state = () => ({
  pending: false,
  documents: [],
  documentsPending: null,
  objectsPending: null,
  toDownload: null,
  deletePending: null,
  // should be empty string or the entityGroup to show in the document viewer
  rootPrefix: String(sessionStorage.getItem('selectedEntityGroup')).startsWith('entityGroups-') ? sessionStorage.getItem('selectedEntityGroup') : '',
  // The path prefix used for sorting into subfolders
  prefix: null,
  objectsPendingForUpload: [],
})

export const getters = {
  getDocuments(state) {
    return state.documents.filter(el => !el.deleted)
  },
  getRootPrefix(state) {
    return state.rootPrefix
  },
  getById: (state) => (id) => {
    if (!Array.isArray(state.documents)) return null

    const found = state.documents
      .filter(el => !('deleted' in el))
      .concat(state.objectsPendingForUpload)
      .find((val) => val.id === id)

    return found ? { ...found, name: found.fileName } : null
  },
  getByPath: (state) => (path) => {
    if (!Array.isArray(state.documents)) return null
    const found = state.documents
      .concat(state.objectsPendingForUpload)
      .find((val) => val.path === path)
    return found ? found : null
  },
  getMultipleByIds: (state) => (ids) => {
    if (!Array.isArray(state.documents)) return []

    if (!Array.isArray(ids)) ids = [ids]

    const uniq = _.uniqBy(state.documents.concat(state.objectsPendingForUpload), 'id')

    return uniq.filter((val) => !('deleted' in val) && ids.includes(val.id)).map((val) => ({ ...val, name: val.fileName }))
  },
}

export const mutations = {
  stageUploads(state, data) {
    state.objectsPendingForUpload = data
  },
  clearStagedUploads(state) {
    state.objectsPendingForUpload.splice(0, state.objectsPendingForUpload.length)
  },
  setPrefix(state, data) {
    state.prefix = data
  },
  setRootPrefix(state, data) {
    state.rootPrefix = data
  },
  setSystemPrefix(state, data) {
    state.prefix = '.System/' + data
  },
  setDocuments: (state, data) => {
    state.documents = data
  },
  putDocuments: (state, data) => {
    if (typeof data !== 'object' || !('id' in data)) return

    const idx = state.documents.findIndex((doc) => doc.id === data.id)

    if (idx == -1) {
      // Not found - add
      state.documents.push(data)
    } else {
      state.documents.splice(idx, 1, data)
    }
  },
  setToDownload: (state, data) => {
    state.toDownload = data
  },
  setPending(state, { key, payload }) {
    state[key] = payload
  },
  switchEntityGroup(state) {
    this.commit('modules/fileManager/refreshDocumentMap', null, { root: true })
    this.commit('modules/fileManager/setSearchTerms', '', { root: true })
    this.commit('modules/fileManager/setCurrentPath', '', { root: true })

    this.commit('auxiliary/s3/setObjects', {}, { root: true })
    this.commit('auxiliary/s3/setFolders', {}, { root: true })
    this.commit('auxiliary/documents/setDocuments', [], { root: true })
  },
}

export const actions = {
  async getDocumentsByEntityGroup({ state, commit, dispatch, rootState }) {
    if (state.documentsPending !== true) commit('setPending', { key: 'documentsPending', payload: true })
    commit('setDocuments', await this.$axios.$get('/api/v1/document'))
    if (state.documentsPending) commit('setPending', { key: 'documentsPending', payload: false })
  },
  async getDocumentById({ state }, id) {
    return await this.$axios.get('/api/v1/document/' + id).then((res) => {
      return res
    })
  },
  async fetchDocumentSaveToCache({ state, commit }, payload) {
    const documents = await this.$axios.$get(`/api/v1/document?ids=${payload.join(',')}`)
    documents.forEach(document => {
      commit('putDocuments', document)
    })
  },
  async transfer({ commit }, { id, payload }) {
    commit('setPending', { key: 'pending', payload: true })
    let data
    try {
      const { data: _data } = await this.$axios.put(`/api/v1/document/transfer/${id}`, { ...payload })
      data = _data
    } catch (e) {
      commit('application/snack/set', { type: 'error', message: e.response.data }, { root: true })
      return null
    }

    commit('setPending', { key: 'pending', payload: false })
    commit('putDocuments', data)
    return data
  },
  async update({ commit, rootState }, { id, payload }) {
    payload.path = payload.url
    payload = _.pick(payload, ['fileName', 'tags', 'entities', 'path'])
    
    return new Promise((resolve, reject) => {
      commit('setPending', { key: 'pending', payload: true })
      this.$axios
        .put(`/api/v1/document/update/${id ?? ''}`, payload)
        .then((res) => {
          if (!res) {
            commit('setPending', { key: 'pending', payload: false })
            return resolve(res.data)
          }

          commit('putDocuments', res.data)
          commit('setPending', { key: 'pending', payload: false })
          resolve(res.data)
        })
        .catch((err) => {
          commit('application/snack/set', { type: 'error', message: _.get(err, 'response.data', 'There was a problem modifying that document.') }, { root: true })
          commit('setPending', { key: 'pending', payload: false })
          resolve(null)
        })
    })
  },
  async getDocumentData({ state, commit, dispatch }, { path, absolute }) {
    return (await this.$axios.get('/api/v1/document/download', { params: { path, absolute }, responseType: 'blob' }))?.data
  },
  // Given an ID from document library or the S3 document convert it into base64
  async getDocumentBase64({ dispatch }, documentId) {
    let document = documentId
    if (typeof documentId === 'string') {
      document = (await dispatch('getDocumentById', documentId)).data
    }
    const documentBlob = await dispatch('getDocumentData', { path: document.path, absolute: false })
    const documentData = await new Response(documentBlob).arrayBuffer()
    return btoa(new Uint8Array(documentData).reduce((data, byte) => data + String.fromCharCode(byte), ''))
  },
}