import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('goalsEventsMilestones', null, true)

export const state = () => ({
  ...crud.state,
  blankGoal: null,
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    const defaultName = 'Goal'
    return defaultName // No need for labels in single page modules? idk
    const obj = getters.getObject(id)
    if (!obj) return defaultName

    return 'Goal'
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const goal = cachedObj ? cachedObj : _.get(getters['getItems'], 'tables.items', []).find((el) => el.id === id) // If not found in cache, try find it
    if (!goal) return ''

    const goalsConfig = rootGetters[`modules/configurationHub/getConfig`]('goals') // Get config options to match IDs to

    // Generate text for fields that should display
    let displayText = []
    displayText.push(goal.goalGroup)
    if (goal.priority) displayText.push(_.get(goalsConfig, 'priorities.items', []).find((el) => el.id === goal.priority)?.name)
    if (goal.timeframe) displayText.push(_.get(goalsConfig, 'timeframes.items', []).find((el) => el.id === goal.timeframe)?.name)
    if (goal.entities) displayText.push($nuxt.$utils.concatStrings(', ', $nuxt.$utils.getMultipleDisplayNames(goal.entities)))
    displayText = displayText.filter((el) => el !== null && el !== undefined)
    return $nuxt.$utils.concatStrings(' — ', displayText)
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
  async getBlankGoal({ state, dispatch }) {
    await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankGoal', endpoint: '/api/v1/goalsEventsMilestones/new/goal' }, { root: true })
  },
}
