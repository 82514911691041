import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('providers', null)

export const state = () => ({
  ...crud.state,
  debouncePatch: _.debounce((dispatch, userId) => {
    dispatch('patchFavourites', userId)
  }, 1000),
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const provider = cachedObj ? cachedObj : getters['getItems'].find((el) => el.id === id) // If not found in cache, try find it
    if (!provider) return ''
    return provider.name
  },
  getProductLabel: (state, getters, rootState, rootGetters) => (providerId, id) => {
    if (!id) return ''
    const cachedProduct = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    if (cachedProduct) return cachedProduct.name

    // If not found in cache, find the provider to get the product from
    const cachedProvider = rootGetters[`application/globalSearch/getCacheObject`](providerId) // Check cache for ID
    const provider = cachedProvider ? cachedProvider : getters['getItems'].find((el) => el.id === providerId) // If not found in cache, try find it
    if (!provider) return ''

    const product = provider.products.find((el) => el.id === id)
    if (!product) return ''
    return product.name
  },
  getFavourites: (state, getters, rootState, rootGetters) => (userId) => {
    let userSettings = rootGetters['modules/configurationHub/getUserSettings'](userId)
    if (userSettings) {
      return userSettings.favouriteProviders
    }
    return []
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
  async update({ state, commit }, { payload, callback, data }) {
    let items = _.get(payload, 'items')
    if (Array.isArray(state.items)) {
      let rollbackRef = _.cloneDeep(state.items) // Set rollback data to use if an error occurs

      // Determine which objects were added and removed
      let prevIds = state.items.map((el) => el.id)
      let currentIds = items.map((el) => el.id)
      let removed = _.difference(prevIds, currentIds)
      let added = _.difference(currentIds, prevIds)

      // Clone the data and remove the 'editable' key for comparison because it exists on one but not the other
      let payloadComparisonClone = _.cloneDeep(items).filter((el) => !added.includes(el.id) && !removed.includes(el.id))
      payloadComparisonClone.forEach((item) => {
        delete item.editable
      })
      let currentComparisonClone = _.cloneDeep(state.items).filter((el) => !added.includes(el.id) && !removed.includes(el.id))
      currentComparisonClone.forEach((item) => {
        delete item.editable
      })
      let updatedObjs = _.differenceWith(payloadComparisonClone, currentComparisonClone, _.isEqual)
      // Update the store items
      commit('application/storeUtils/setItem', { localState: state, item: 'items', value: items }, { root: true })
      let promises = []

      // New objects were added
      if (added.length > 0) {
        let addedObjs = items.filter((el) => added.includes(el.id)) // Get objects to save as new
        items = items.filter((el) => !added.includes(el.id)) // Remove new objects from objects to update because they do not exist for the update
        // Post multiple
        promises.push(
          new Promise((resolve, reject) =>
            this.$axios
              .post(`/api/v1/providers/multiple`, addedObjs)
              .then((res) => resolve(res))
              .catch(reject)
          )
        )
      }

      // Send delete request with the IDs for objects that were removed from the table
      if (removed.length > 0) promises.push(new Promise((resolve, reject) => this.$axios.post(`/api/v1/providers/deletes`, removed).then(resolve).catch(reject)))

      // Update any existing objects
      promises.push(new Promise((resolve, reject) => this.$axios.post(`/api/v1/${state.endpoint}/update`, updatedObjs).then(resolve).catch(reject)))

      // Send requests
      await Promise.all(promises)
        .then((res) => {
          if (Array.isArray(res)) {
            // If the result is for adding new objects we need to replace the old IDs with new IDs
            let addResult = _.get(res, '[0].config.url') === '/api/v1/providers/multiple' ? _.get(res, '[0].data') : ''
            if (Array.isArray(addResult)) {
              addResult.forEach((idChange) => {
                // Update IDs
                commit('application/storeUtils/modifyCollection', { collection: state.items, item: { old: idChange.oldId, new: idChange.newId }, action: 'idReplace' }, { root: true })
              })
            }
            let status = 200
            res.forEach((el) => {
              // Check if each result succeeded, if not then change the status flag
              if (status === 200 && el.status !== 200) status = el.status
            })
            // Callback for save checkmark
            if (callback) data !== null ? callback(status, data) : callback(status)
          }
        })
        .catch((err) => {
          // Revert store items if error occurred
          if (rollbackRef) commit('application/storeUtils/setItem', { localState: state, item: 'items', value: rollbackRef }, { root: true })
          if (callback) callback(null)
          console.warn(err)
        })
    }
  },
  updateFavourites({ state, commit, dispatch }, { userId, favourites }) {
    // Commit changes to config manager user settings, patch the changes after a debounce
    commit(`modules/configurationHub/updateUserSettingKey`, { userId: userId, key: 'favouriteProviders', val: favourites }, { root: true })
    state.debouncePatch(dispatch, userId)
  },
  patchFavourites({ getters }, userId) {
    this.$axios.patch(`/api/v1/configurations/user`, { favouriteProviders: getters['getFavourites'](userId) })
  },
}
