// Provides access to the console's error logs

console.errors = []

console._error = console.error.bind(console)
console.error = (message, ...optionalParams) => {
  console._error.apply(console, [message, ...optionalParams])
  console.errors = [{ message, optionalParams }, ...console.errors]
}

export default (context, inject) => {
  // Do something
}