if (process.env.NODE_ENV === 'development') Error.stackTraceLimit = 100 // raise limit because 10 aint doin it

import markerSDK from '@marker.io/browser'
// Initialization
// Pls Mr computer, run this code as fast as you can 🚀
export const actions = {
  async nuxtClientInit({ dispatch, commit, rootGetters, rootState }, context) {
    try {
      // Prevent navigation if an edit is in progress
      window.addEventListener('beforeunload', context.$navigation.preventEditNavigation)

      // Setup
      const transactionId = context.$uuid()
      commit(`application/webStomp/setTransactionId`, transactionId)
      context.store.dispatch('application/webStomp/unsubAllApps')

      if (context.$auth.loggedIn) {
        const user = context.$auth?.$state?.user
        // NEED TO KNOW USER'S APP_METADATA!
        dispatch('auxiliary/user/getUserAppMetadata')

        // This needs to be awaited
        try {
          await Promise.allSettled([dispatch('auxiliary/user/getCompanyDetails'), dispatch('modules/entityGroups/getAll'), dispatch('modules/configurationHub/getInitialConfigs'), dispatch('auxiliary/user/fetchRoles')])
        } catch (error) {
          // Handle the error if any of the promises fail
          console.error(error)
        }

        dispatch('auxiliary/filters/getUserFilters')

        window.onNuxtReady(async () => {
          if (context.store.getters[`application/devContent/isModuleActive`]('emails')) {
            dispatch(`integrations/google/init`) // Setup google API client
            dispatch(`integrations/microsoft/init`) // Setup microsoft API client
          }
          dispatch('modules/configurationHub/setupSession') // Setup session settings and fetch entity group data

          dispatch('application/appData/fetchServerMessage')
          dispatch('application/appData/fetchEnums')
          dispatch(`modules/professionals/getAll`)
          // dispatch(`modules/providers/getAll`)

          dispatch('application/notifications/fetchBlankNotification') // Fetch blank here to avoid problems later when notificaitons aren't async
          dispatch('application/notifications/fetchUserNotifications')

          if (context.$config.marker) {
            const fullName = `${user?.given_name ? user?.given_name + ' ' : ''}${user?.family_name ? user?.family_name : ''}`
            window.markerWidget = await markerSDK.loadWidget({
              project: '650a5ea01deca3ef2630e153',
              keyboardShortcuts: false,
              reporter: context.$auth.loggedIn ? { email: context.$auth?.user?.email, fullName } : undefined,
              customData: { userSub: context.$auth?.user?.sub },
            })
            // hide the widget - capture dialog will be shown programatically
            window.markerWidget.hide()
          }

          // Start loading screend
          if (process.browser) {
            // window.$nuxt.$root.$loading.start()
            window.$nuxt.$root.$loading.finish()
          }

          // Stomp subscribe
          context.store.commit('application/webStomp/updateSubscriptions', { subscription: `serverMessage`, action: `application/appData/fetchServerMessage` }) // Server message updates
          context.store.commit('application/webStomp/updateSubscriptions', { subscription: `notifications`, action: `application/notifications/eventUpdate` }) // Notifications
          context.store.commit('application/webStomp/updateSubscriptions', { subscription: `configurations`, action: `modules/configurationHub/eventUpdate` }) // Config hub
          context.store.commit('application/webStomp/updateSubscriptions', { subscription: `integrationEmails`, action: `integrations/integrationEmails/eventUpdate` }) // Integration emails ( Gmail / Outlook )
          context.store.commit('application/webStomp/updateSubscriptions', { subscription: `accountUpgrade`, action: `auxiliary/user/performUpgrade` }) // Trial upgrade

          // Request status patch
          context.store.commit('application/webStomp/updateSubscriptions', { subscription: `status`, action: `modules/requests/statusEvent` })

          // Stomp subscrube to all modules if they have an eventUpdate action
          const modules = context.store.getters[`application/appData/getModuleValues`]
          modules.forEach((module) => {
            let hasStomp = Object.keys(context.store._actions).findIndex((key) => key === `modules/${module}/eventUpdate`) !== -1
            if (hasStomp) context.store.commit('application/webStomp/updateSubscriptions', { subscription: module, action: `modules/${module}/eventUpdate` })
          })

          // initSocket subscribes webstomp client to all subscriptions in the store so we have to do it last
          context.$webStomp.initSocket()
        })
      }
    } catch (e) {
      console.error(e)
      if (e?.message === 'Request failed with status code 569') {
        return //Do nothing if error was from being logged out by the gulag (569)
      }
      window.onNuxtReady(() => {
        // Build the error message
        let err = null
        if (e?.message === 'Network Error') {
          err = { statusCode: 500, message: 'Unable to connect to Elan server' }
        } else {
          err = { statusCode: e?.status || e.name, message: e?.message || 'An error occurred' }
        }

        if (err) {
          // Redirect to error page
          window.$nuxt.error(err)
          context.$sentry.captureEvent({ message: err.message })
        }
      })
    }
  },
}
