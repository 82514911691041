
import permissionMixin from '@/mixins/configPermissionMixin.js'
export default {
  mixins: [permissionMixin],
  // async fetch() {
  //   await this.$store.dispatch('auxiliary/user/getBillingDetails')
  // },
  data() {
    return {
      forceDeactivateDialog: false,
      loading: false,
    }
  },
  computed: {
    occupiedSeats() {
      return this._.cloneDeep(this.$store.getters[`auxiliary/user/getUsersInOrg`]).filter((el) => !el.blocked).length
    },
    maxSeats() {
      return this._.get(this.$store.state.auxiliary.stripe, 'customer.subscriptions.items.data.0.quantity', null)
    },
  },
  methods: {
    navigateToBilling() {
      this.loading = true
      this.$router.push({ path: `/configurationHub/organisation/billingAndUsers` })
      this.loading = false
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(v) {
        this.$nextTick(() => {
          if (this.maxSeats !== null && v.path !== '/configurationHub/organisation/billingAndUsers' && this.maxSeats < this.occupiedSeats) {
            this.forceDeactivateDialog = true
          } else {
            this.forceDeactivateDialog = false
          }
        })
      },
    },
    forceDeactivateDialog(v) {
      this.$nextTick(() => {
        if (v && this.$route.path === '/configurationHub/organisation/billingAndUsers') {
          this.forceDeactivateDialog = false
        } else if (this.$route.path !== '/configurationHub/organisation/billingAndUsers' && this.maxSeats < this.occupiedSeats) {
          this.forceDeactivateDialog = true
        }
      })
    },
    occupiedSeats(v) {
      this.$nextTick(() => {
        if (this.maxSeats !== null && v > this.maxSeats) {
          this.forceDeactivateDialog = true
        } else {
          this.forceDeactivateDialog = false
        }
      })
    },
    maxSeats(v) {
      this.$nextTick(() => {
        if (v !== null && v < this.occupiedSeats) {
          this.forceDeactivateDialog = true
        } else {
          this.forceDeactivateDialog = false
        }
      })
    },
  },
}
