let localContext
export const navigation = {
  preventEditNavigation(event) {
    if (localContext.store.getters[`application/appData/isEditMode`]) {
      event.preventDefault()
      event.returnValue = ''
    }
  },
}

export default (context, inject) => {
  localContext = context
  inject('navigation', navigation)
}
