
import filterMixin from '@/mixins/filterMixin.js'
import configMixin from '~/mixins/configMixin.js'
export default {
  mixins: [filterMixin, configMixin],
  async fetch() {
    if (this.dialog) {
      // Reset selected before fetching
      this.selectedMulti = []
      this.selectedSingle = null

      // Default filters to show only active
      this.setDefaultFilters()

      await this.fetchData()

      if (this.preSelected) {
        // Select objects from the cache from the preselect IDs
        this.preSelectObjects()
      }
    }
  },
  data() {
    return {
      blacklist: ['Configuration Hub'],
      data: {},

      search: '',
      pendingFirstPage: false,
      fetchingPage: false,
      fetchingData: false,
      debounceDataFetch: _.debounce(() => {
        this.fetchData()
      }, 800),

      filterOpen: false,
      selectedId: null,

      selectedModule: null,

      multiMenuActive: false,
      selectedMulti: [],
      selectedSingle: null,
      urlPattern: /^(https?:\/\/)?(www\.)?[a-z0-9.-]+\.[a-z]{2,}(\/.*)?$/i,
    }
  },
  computed: {
    dialog() {
      return this.$store.getters[`application/globalSearch/isShowing`]
    },
    preSelected() {
      return _.get(this.$store.state, 'application.globalSearch.props.selected')
    },
    singleSelect() {
      return _.get(this.$store.state, 'application.globalSearch.props.select')
    },
    multiSelect() {
      return _.get(this.$store.state, 'application.globalSearch.props.multiple')
    },
    groupId() {
      return _.get(this.$store.state, 'application.globalSearch.props.group')
    },
    returnObject() {
      return _.get(this.$store.state, 'application.globalSearch.props.returnObject')
    },
    queryTypes() {
      return _.get(this.$store.state, 'application.globalSearch.props.types')
    },
    queryFilters() {
      return _.get(this.$store.state, 'application.globalSearch.props.filters')
    },
    createUrls() {
      return _.get(this.$store.state, 'application.globalSearch.props.createUrls')
    },
    customData() {
      return _.get(this.$store.state, 'application.globalSearch.props.data')
    },
    selectedObject() {
      if (this.selectedId) {
        // Find the object from the ID so we can get the type as well
        for (const [key, value] of Object.entries(this.data)) {
          if (value) {
            let found = _.get(value, 'content', []).find((el) => el.id === this.selectedId)
            if (found) return { type: key, object: found }
          }
        }
      }
      return null
    },
    filterItems() {
      if (this.selectedModule && this.selectedApp) {
        // Use query filters if given
        if (this.queryFilters) return this.queryFilters

        // Base filters
        let filters = [
          {
            text: 'Status',
            value: 'status',
            options: [
              {
                text: 'Show Active',
                value: 'ACTIVE',
              },
              {
                text: 'Show Inactive',
                value: 'INACTIVE',
              },
            ],
          },
        ]

        // Module type filters
        const typesFilter = {
          text: this.$store.getters['application/appData/getModuleNameFromValue'](this.selectedModule),
          value: this.selectedModule,
          max: 6,
          options: this.selectedModuleTypes,
        }

        // Only add the module type filters if there is more than one type
        if (Array.isArray(typesFilter.options) && typesFilter.options.length > 1) {
          filters.unshift(typesFilter)
        }

        return filters
      }
      return []
    },
    dataSiv() {
      let displayFields = []
      if (this.selectedObject) {
        let obj = _.get(this.selectedObject, 'object', {})
        let module = _.get(this.selectedObject, 'type')
        let keys = Object.keys(obj)

        // Get fields to display in the object information panel
        if (keys.includes('name')) displayFields.push(this.generateLabel('Name', obj.name))
        if (keys.includes('subType')) displayFields.push(this.generateLabel('Type', this.$utils.capitaliseWords(obj.subType)))
        else if (keys.includes('type')) {
          let typeLabel = ''
          if (module === 'fileNotes') {
            typeLabel = this.$store.getters[`modules/fileNotes/getFileNoteType`](obj.type)
          } else {
            typeLabel = this.$utils.capitaliseWords(obj.type)
          }
          displayFields.push(this.generateLabel('Type', typeLabel))
        }

        // Entity Group fields
        if (module === 'entityGroups') {
          displayFields.push(this.generateLabel('Name', obj.summary?.name))
          displayFields.push(this.generateLabel('Segment', this.getConfigItems('clientGroup.segments').find(({ id }) => id === obj.summary?.segment)?.name))
          displayFields.push(
            this.generateLabel(
              'Team(s)',
              this.getConfigItems('organisation.teams').filter(({ id }) => obj.adviceTeam.teams.includes(id)),
              { multiple: true }
            )
          )
        }

        // Module summary fields
        if (['entities', 'employments', 'liabilities', 'professionals', 'insurances', 'superannuations'].includes(module)) {
          this.processObjectFields(obj, 'summary', displayFields)
        }

        // Phone / Emails
        if (obj.contactInformation) {
          this.processContactInformation(obj.contactInformation, displayFields)
        }

        // Client Group
        if (obj.entityGroupId || obj.clientGroupId) {
          const group = this.$store.getters[`modules/entityGroups/getObject`](obj.entityGroupId || obj.clientGroupId)
          displayFields.push(this.generateLabel('Client Group', group?.summary?.name))
        }

        return displayFields
      }
    },
    selectedItems: {
      // Prop to control selecting multiple or single objects
      get() {
        if (this.singleSelect) return this.selectedSingle
        else if (this.multiSelect) return this.selectedMulti
        return null
      },
      set(val) {
        if (this.singleSelect) this.selectedSingle = val
        else if (this.multiSelect) this.selectedMulti = val === null ? [] : val
      },
    },
    isFetching() {
      if (this.fetchingData) return true
      if (this.preSelected) return this.$store.getters['application/globalSearch/isFetching'](this.preSelected)
      return false
    },
    selectedApp() {
      return this.$store.getters['application/appData/getAppValueFromModule'](this.selectedModule)
    },
    selectedModuleTypes() {
      // Available types for creating a new object
      if (this.selectedModule) {
        let types = _.get(this.$store.state, `modules.${this.selectedApp}.${this.selectedModule}.typeSelections`)
        if (Array.isArray(types)) {
          // Reduce types to only valid ones
          return types
            .map((el) => {
              const limitedTypes = _.get(this.queryTypes, `[${this.selectedModule}]`)
              if (limitedTypes) {
                if (limitedTypes.includes(el.value)) return { ...el, text: el.name }
              } else {
                return { ...el, text: el.name }
              }
            })
            .filter((el) => el !== undefined)
        }
      }
      return []
    },
    singleObjectName() {
      // Name displayed on the selected component if using single select mode
      if (this.singleSelect && this.selectedSingle) {
        return this.$utils.getDisplayName(this.selectedSingle)
      }
      return '--'
    },
    displayItems() {
      if (this.data) {
        // Selected group items
        if (this.selectedModule) {
          return { [this.selectedModule]: _.get(this.data, `[${this.selectedModule}]`, []) }
        }
        return this.data
      }
      return {}
    },
    numDisplayed() {
      let num = Object.values(this.displayItems)
        .flatMap((el) => el.totalElements)
        .filter((el) => !_.isNil(el))
        ?.reduce((a, b) => a + b, 0)
      return !num ? 0 : num
    },
    showSidebar() {
      return this.filterOpen || this.selectedId !== null ? true : false
    },
    apps() {
      let apps = _.cloneDeep(this.$store.state.application.appData.apps)
      apps.Organisation.push({ name: 'Users', value: 'users' })
      // Blacklisted apps which shouldn't be searchable
      this.blacklist.forEach((app) => {
        delete apps[app]
      })
      return apps
    },
    shownApps() {
      let modules = _.get(this.$store.state, 'application.globalSearch.props.modules')
      if (Array.isArray(modules)) modules = modules.filter((el) => this.$store.getters[`application/devContent/isModuleActive`](el))
      if (this.customData) modules = _.uniq(this.customData.map((el) => el.module))

      // Filter apps to only include apps where the module is used in the available data
      return Object.entries(this.apps).filter((app) => app[1].find((el) => Object.keys(this.data).includes(el.value) || el?.menuItems?.find((m) => modules?.includes(m.value)) !== undefined))
    },
  },
  methods: {
    processObjectFields(obj, objectKey, displayFields) {
      Object.entries(_.get(obj, objectKey, {})).forEach(([k, v]) => {
        if (!_.isNil(v)) {
          const label = k
            .split('_')
            .map((word) => this.$utils.capitaliseWords(word))
            .join(' ')
          if (k == 'lifeInsured' || k == 'policyOwners' || k == 'owner') {
            if (!Array.isArray(v)) v = [v]
            displayFields.push(this.generateLabel(label, v.map((el) => this.$utils.getDisplayName(el)).join(', ')))
          } else if (Array.isArray(v)) {
            displayFields.push(this.generateLabel(label, v.map((el) => _.startCase(typeof el === 'string' ? el.toLowerCase() : ''))?.join(', ')))
          } else if (this.urlPattern.test(v)) {
            displayFields.push(this.generateLabel(label, v))
          } else if (k == 'riskProfile') {
            displayFields.push(this.generateLabel(label, this.riskProfileList.find(({ id }) => id === v)?.name))
          } else {
            // todo determine different data-label props, eg date, month

            // Convert to ISO then check if it is valid with moment otherwise we get a warning
            const isoDate = this.$utils.dateToIso(v)
            const isDate = this.$utils.isIso(isoDate) && this.$moment(isoDate).isValid() ? true : false

            // Check if value is an enum and capitalise if it is
            const enumRegex = /^[A-Z]+(?:_[A-Z]+)*$/
            const isEnum = enumRegex.test(v)
            if (isEnum) v = this.$utils.capitaliseWords(v)

            displayFields.push(this.generateLabel(label, v, { date: isDate }))
          }
        }
      })
    },
    processContactInformation(contactInfo, displayFields) {
      if (!contactInfo) return
      displayFields.push(
        this.generateLabel(
          'Phone Number(s)',
          contactInfo.numbers.map((el) => el.value),
          { multiple: true }
        )
      )
      displayFields.push(
        this.generateLabel(
          'Email(s)',
          contactInfo.emails.map((el) => el.value),
          { multiple: true }
        )
      )
    },
    getSelectableModules(appModules) {
      if (!Array.isArray(appModules)) return []
      let flatModules = appModules.flatMap((obj) => (obj.menuItems ? obj.menuItems : obj))
      // Return modules which are included in our fetched data
      return flatModules.filter((el) => Object.keys(this.data).includes(el.value) && el.value !== 'comingSoon' && this.$store.getters[`application/devContent/isModuleActive`](el.value))
    },
    preSelectObjects() {
      let selectedObjs = _.cloneDeep(Array.isArray(this.preSelected) ? this.preSelected : [this.preSelected])
      const isString = typeof _.get(selectedObjs, '[0]') === 'string'
      // If the elements are strings, try and find them in the ID cache
      if (isString) selectedObjs = this.$utils.getObjectsFromIds(selectedObjs)
      // Select single or multiple
      if (this.singleSelect) this.selectedSingle = _.get(selectedObjs, '[0]')
      else if (this.multiSelect) this.selectedMulti = selectedObjs
    },
    getModuleValue(module) {
      let moduleValue = module.value
      let menuItem = module?.menuItems?.find((m) => Object.keys(this.data).includes(m.value))

      if (menuItem !== undefined) {
        moduleValue = menuItem.value
      }

      return moduleValue
    },
    getContactLabels(contact, numbers, emails, addresses) {
      let labels = []
      if (contact) {
        if (numbers && contact.numbers) {
          contact.numbers.forEach((number) => {
            labels.push(this.generateLabel(`${this.$utils.capitaliseWords(number.type)} Phone Number`.trim(), this.$utils.formatPhone(number.value)))
          })
        }
        if (emails && contact.emails) {
          contact.emails.forEach((email) => {
            labels.push(this.generateLabel(`${this.$utils.capitaliseWords(email.type)} Email`.trim(), email.value))
          })
        }
        if (addresses && contact.addresses) {
          contact.addresses.forEach((address) => {
            labels.push(this.generateLabel(`${this.$utils.concatStrings(', ', ...this.$utils.capitaliseWords(address.type))} Address`.trim(), this.$utils.formatPlace(address, true)))
          })
        }
      }
      return labels
    },
    setupData() {
      // If given a custom array of data, use that
      if (Array.isArray(this.customData)) {
        let data = {}
        this.customData.forEach((el) => {
          data[el.module] = {
            content: el.content,
            totalElements: el.content.length,
          }
        })
        this.data = data
        return
      }

      // Get the module names by flatmapping the apps array of objects
      // If nested array 'menuItems' exists, flatmap that too
      // Grab the values from the flatmapped arrays and ensure they are unique values
      let moduleNames = _.uniq(
        Object.values(this.apps)
          .flatMap((arr) => arr.flatMap((obj) => (obj.menuItems ? [...obj.menuItems] : obj)))
          .map((el) => el.value)
      )

      // Create a data object with keys as each app so that when we change the module data (using the key) it will be reactive
      // If we didn't prefill the module keys it wouldn't be reactive
      let data = {}
      let setModules = _.get(this.$store.state, 'application.globalSearch.props.modules') // Selected modules to be used
      moduleNames.forEach((module) => {
        if ((setModules && setModules.includes(module)) || !setModules) data[module] = {}
      })
      this.data = data
    },
    generateLabel(label, value, props) {
      // Creates an object used in a v-for of data-label components
      return { label: label, value: value, id: this.$uuid(), multiple: _.get(props, 'multiple'), ownership: _.get(props, 'ownership'), $attrs: { ...props } }
    },
    closeDialog() {
      this.$store.commit(`application/globalSearch/closeDialog`)
      this.selectedItems = null
      this.selectedModule = null
      this.selectedId = null
      this.search = ''
    },
    closeSidebar() {
      this.filterOpen = false
      this.selectedId = null
    },
    applySelection() {
      let callback = _.get(this.$store.state, 'application.globalSearch.props.callback')
      if (typeof callback === 'function') {
        // Send data back via callback function
        if (this.multiSelect)
          callback(
            this.selectedMulti.map((el) => {
              return this.returnObject ? el : el.id
            })
          )
        else if (this.singleSelect) callback(this.returnObject ? this.selectedSingle : this.selectedSingle?.id)
        else callback(null)
      }
      this.closeDialog()
    },
    selectModule(module) {
      // Look through menuitems incase its a sub type
      module = this.getModuleValue(module)

      // Reset sidebar & filters
      this.closeSidebar()
      this.setDefaultFilters()

      // Reset previously selected module data so that it shows the correct number of entries in the side bar
      if (this.selectedModule) this.getNextPage(this.selectedModule, true)
      // Fetch newly selected module data starting at page 0
      this.getNextPage(module, true)

      // Select or deselect module
      if (this.selectedModule === module) {
        this.selectedModule = null
      } else {
        this.selectedModule = module
      }
    },
    selectObject(id) {
      // Select or deselect object
      if (this.selectedId === id) this.closeSidebar()
      else this.selectedId = id
    },
    removeSelection(id) {
      if (id) {
        // Remove ID from multi select array
        let foundIndex = this.selectedMulti.findIndex((el) => _.get(el, 'id') === id)
        if (foundIndex >= 0) this.selectedMulti.splice(foundIndex, 1)
        if (this.selectedMulti.length === 0) this.multiMenuActive = false // Close menu if nothing else selected
      }
    },
    navigateToUrl(url) {
      if (!url) return

      // URL navigation for custom create new URLs
      let routeData = this.$router.resolve({ path: url })
      window.open(routeData.href, '_blank')
    },
    setupNewObject(type) {
      if (!this.selectedApp || !this.selectedModule) return

      // Open a new tab with the 'create' query included
      let routeData = this.$router.resolve({ path: `/${this.selectedApp}/${this.selectedModule}`, query: { create: type } })
      window.open(routeData.href, '_blank')
    },
    infiniteScrolling(entries, observer, module) {
      if (this.customData) return
      // Called when reaching the bottom of a list
      if (!this.$fetchState.pending && !this.fetchingPage && !_.get(this.data, `[${module}].last`)) {
        this.getNextPage(module)
      }
    },
    getNextPage(module, resetData = false) {
      if (!this.dialog || this.customData) return // Do nothing if dialog is closed

      let currentData = null
      if (resetData) this.data[module] = {}
      // Delete selected modules data to fetch from page 0
      // if (resetData) delete this.data[module] // Delete selected modules data to fetch from page 0
      else currentData = _.get(this.data, `[${module}]`)

      if (currentData) {
        // Grab the next page if not currently fetching it
        this.fetchingPage = true
        this.$store
          .dispatch(`application/globalSearch/fetchData`, { search: this.search, collections: [module], page: Number(currentData.number) + 1, groupId: this.groupId, filters: this.filters })
          .then((res) => {
            let resData = _.get(res, `[${module}]`)
            if (resData && _.get(currentData, 'content')) {
              currentData.content = currentData.content.concat(resData.content)
              currentData.number = resData.number
              currentData.last = resData.last
            }
          })
          .finally(() => {
            this.fetchingPage = false
          })
      } else {
        // Grab first page
        this.fetchingPage = true
        this.pendingFirstPage = true
        this.$store
          .dispatch(`application/globalSearch/fetchData`, { search: this.search, collections: [module], page: 0, groupId: this.groupId, filters: this.filters })
          .then((res) => {
            let resData = _.get(res, `[${module}]`)
            if (resData) {
              _.set(this.data, module, resData)
            }
          })
          .finally(() => {
            this.fetchingPage = false
            this.pendingFirstPage = false
            this.$forceUpdate()
          })
      }
    },
    async fetchData() {
      this.setupData() // Setup blank data object
      if (this.customData) return
      this.fetchingData = true
      let res = await this.$store.dispatch(`application/globalSearch/fetchData`, { search: this.search, groupId: this.groupId, filters: this.filters })
      this.fetchingData = false
      if (res) {
        // Set modules in data to the returned data (key = module, value = module data)
        for (const [key, value] of Object.entries(res)) {
          this.data[key] = value
        }
      }
    },
    setDefaultFilters() {
      if (this.queryFilters) return
      this.filters = []
    },
  },
  mounted() {
    if (this.customData) return
    // Refresh data event for when a webstomp 'add' event is triggered in a crudStore
    this.$bus.$off('refresh-search-data')
    this.$bus.$on('refresh-search-data', (endpoint) => {
      // Refresh data only if there is a new addition in the selected module which means it was probably added in another tab opened by this dialog
      if (this.dialog && endpoint && endpoint === this.selectedModule) this.getNextPage(this.selectedModule, true)
    })
  },
  watch: {
    search() {
      //This will trigger a fresh search and reset paging
      if (!this.dialog) return // Prevent the fetch from happening if the dialog is closed
      this.debounceDataFetch()
    },
    dialog(val) {
      this.multiMenuActive = false // Reset selected menu visibility
      // Re-fetch when opening dialog
      if (val === true) {
        this.$fetch()

        // If there is one app with one module, automatically select it
        if (this.shownApps.length === 1 && this.getSelectableModules(_.get(this.shownApps, `[0][1]`)).length === 1) {
          this.selectedModule = _.get(this.getSelectableModules(_.get(this.shownApps, `[0][1]`)), '[0].value')
        }
      }
    },
    isFetching(newVal, oldVal) {
      // When fetching is finished
      if (this.preSelected && newVal === false && oldVal === true) {
        // Select objects from the cache from the preselect IDs
        this.preSelectObjects()
      }
    },
  },
}
