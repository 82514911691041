
export default {
  props: {
    /**
     * Notification object to display.
     */
    notification: {
      type: Object,
      default: () => {},
    },
    /**
     * Boolean to watch for updating the time passed display.
     */
    timeWatcher: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timePassed: null,
    }
  },
  methods: {
    updateTimePassed() {
      if (this.notification.creationDate) {
        // Use moment to get the time passed
        this.timePassed = this.$moment(this.notification.creationDate).fromNow()
      }
    },
  },
  computed: {
    clickable() {
      if (!this.notification) return false
      return this.notification.path || this.notification.documentId ? true : false
    },
    isDownload() {
      return this.notification?.documentId ? true : false
    },
  },
  mounted() {
    this.updateTimePassed()
  },
  watch: {
    timeWatcher(newVal) {
      if (newVal === true) this.updateTimePassed()
    },
  },
}
