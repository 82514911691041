
export default {
  props: {
    /**
     * Selected object
     */
    selected: {
      type: Object,
      default: null,
    },
    /**
     * The module name used to retrieve the object name
     */
    module: {
      type: String,
      default: null,
    },
  },
  computed: {
    objectName() {
      return this.$utils.getDisplayName(this.selected)
    },
    objectDescription() {
      return this.$utils.getSubtitle(this.selected)
    },
  },
  methods: {
    removeSelection() {
      this.$emit('remove', _.get(this.selected, 'id'))
    },
  },
}
