
export default {
  data() {
    return {
      appSelectIndex: 0,
      bugFeatureToggle: null,
      showIssueDialog: false,
      issueData: { title: '', message: '', token: null },
      isBug: 0, // 0 bug, 1 feature
    }
  },
  watch: {
    showIssueDialog(newValue) {
      if (newValue) this.issueData = { title: '', message: '', token: null }
    },
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'F8') this.openIssueDialog(false)
    })
  },
  computed: {
    serverMessage() {
      return this.$store.getters[`application/appData/getServerMessage`]
    },
    trialInfo() {
      const app_metadata = _.get(this.$store.state, 'auxiliary.user.appMetadata')
      const expires = app_metadata?.trialEnd || null
      if (!expires) return { trialing: false, expires: null, realDate: null, color: '#152F4F' }

      const days = this.$moment.unix(expires).diff(this.$moment(), 'days')
      const hours = this.$moment(expires).diff(this.$moment(), 'hours')
      const isExpired = this.$moment().isAfter(this.$moment.unix(expires))

      return {
        trialing: expires ? true : false,
        expires: days >= 1 ? `${days + 1} day${days === 1 ? '' : 's'} left` : hours > 0 ? hours + ' hours left' : isExpired ? 'Expired!' : 'Expiring soon!',
        realDate: this.$moment.unix(expires).format('Do MMM, h:mm A'),
        color: days >= 5 ? '#152F4F' : '#8F2626',
        isOwner: this.$store.getters['auxiliary/user/isOrgOwner'](this.$auth.user.sub),
      }
    },
    disableSendingReport() {
      return this.issueData.message === '' || this.issueData.title === ''
    },
    checkoutLoading() {
      return _.get(this.$store.state, 'auxiliary.stripe.loading_checkout_session', false)
    },
  },
  methods: {
    upgradeTrialDialog() {
      if (!this.trialInfo?.isOwner) return
      this.$store.dispatch('auxiliary/stripe/upgradeTrialDialog')
    },
    openIssueDialog(isBug) {
      this.isBug = isBug ? 0 : 1
      this.showIssueDialog = true
      setTimeout(() => window?.markerWidget?.capture(), 100)
    },
  },
}
