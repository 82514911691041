export default async function ({ app, store }) {
  // Remove the existing beforeResolve hooks before adding our new one
  if (app.router.resolveHooks.length) {
    app.router.resolveHooks = []
  }

  // Route selection check
  app.router.beforeResolve((to, from, next) => {
    const toModule = to.name?.split('-')[0]
    const fromModule = from.name?.split('-')[0]
    const fromId = _.get(from, 'params.id') || _.get(from, 'params.suboption') // ID or suboption if no ID (e.g. config hub)
    const toId = _.get(to, 'params.id') || _.get(to, 'params.suboption')

    if (toModule !== fromModule && !to?.query?.pendo) {
      // If the newly selected module is different to the current one, check if there is a path for the module in session storage
      if (fromModule && fromId) {
        sessionStorage.setItem(`${fromModule}_return_path`, from.path)
      }

      const cachedPath = sessionStorage.getItem(`${toModule}_return_path`)
      // If the 'to' url doesn't have an ID (not from a link click) and we have a cached path, redirect to it
      if (!toId && cachedPath) {
        sessionStorage.removeItem(`${toModule}_return_path`)
        next({ path: cachedPath })
        return true
      }
    }
    next()
  })
}
