
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    altKey() {
      return this.isWindows || this.isLinux ? 'Alt' : 'option'
    },
    isWindows() {
      const platform = window.navigator.platform || window.navigator.userAgent
      if (platform.toLowerCase().includes('win')) return true
      else return false
    },
    isMac() {
      const platform = window.navigator.platform || window.navigator.userAgent
      if (platform.toLowerCase().includes('mac')) return true
      else return false
    },
    isLinux() {
      const platform = window.navigator.platform || window.navigator.userAgent
      if (platform.toLowerCase().includes('linux')) return true
      else return false
    }
  }
}
