import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore(
  'employment',
  [
    { name: 'Employee', value: 'EMPLOYEE' },
    { name: 'Self-Employed', value: 'SELF_EMPLOYED' },
    { name: 'Non-Working', value: 'NON_WORKING' },
  ]
)

export const state = () => ({
  ...crud.state,
  selectedExtra: [],
  filters: [
    { name: 'Employments', value: 'showEmployments' },
    { name: 'Non-Working', value: 'showNonWorking' },
    { name: 'Self-Employed', value: 'showSelfEmployed' },
  ],
  selectedFilters: ['showEmployments'],
  extraOptions: [{ name: 'Detailed Information', value: 'DETAILED_INFORMATION' }],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return 'Unnamed'

    if (obj?.type === 'NON_WORKING') return `${$nuxt.$utils.capitaliseWords(obj?.summary?.type || obj.type)}${obj?.summary?.description ? ' - '+obj?.summary?.description  : ''}`
    if (obj?.type === 'SELF_EMPLOYED') return `${obj?.summary?.jobTitle || $nuxt.$utils.capitaliseWords(obj?.summary?.type || obj?.type)}${obj?.summary?.description ? ' - '+obj?.summary?.description  : ''}`

    let name = []
    if (obj?.summary?.jobTitle) name.push(obj?.summary?.jobTitle)
    if (obj?.summary?.jobTitle && obj?.summary?.employer) name.push('at')
    if (obj?.summary?.employer) name.push($nuxt.$utils.getDisplayName(obj?.summary?.employer))
    if (obj?.summary.description) name.push((name.length ? ' - ' : '') + obj?.summary?.description)

    return name?.length ? name.join(' ') : $nuxt.$utils.capitaliseWords(obj.type)
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    const employment = getters.getObject(id)
    if (!employment) return ''

    const ownershipLabel = $nuxt.$utils.getDisplayName(employment?.summary?.owner)

    // Return the ownership label as the subtitle
    return ownershipLabel === 'Unnamed' ? '' : ownershipLabel
  },
}

export const mutations = {
  ...crud.mutations,
  // Setting extra options - must be named setSelectedExtra to allow expansionTable to call if when changing panels
  setSelectedExtra(state, type) {
    if (!type) state.selectedExtra = []
    else {
      if (typeof type !== 'string' && _.get(type, 'type')) type = type.type // Type was whole object
      if (type === 'EMPLOYMENT' || type === 'SELF_EMPLOYED') state.selectedExtra = [state.extraOptions[0]]
      else state.selectedExtra = []
    }
  },
}

export const actions = {
  ...crud.actions,
}
