export const state = () => ({
  countries: [
    'Australia', 'New Zealand', 'United States of America', 'United Kingdom',
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 
    'Argentina', 'Armenia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 
    'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 
    'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 
    'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 
    'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 
    'Congo', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 
    'Democratic Republic of the Congo', 'Denmark', 'Djibouti', 'Dominica', 
    'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 
    'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 
    'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 
    'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 
    'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 
    'Iraq', 'Ireland', 'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 
    'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 
    'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 
    'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 
    'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 
    'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 
    'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 
    'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia', 'Norway', 
    'Oman', 'Pakistan', 'Palau', 'Palestine', 'Panama', 'Papua New Guinea', 
    'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 
    'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 
    'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 
    'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 
    'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 
    'Somalia', 'South Africa', 'South Korea', 'South Sudan', 'Spain', 
    'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 
    'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Tonga', 
    'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 
    'Uganda', 'Ukraine', 'United Arab Emirates', 'Uruguay', 'Uzbekistan', 
    'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
],
  schools: [],
  languages: [],
  bsbList: [],

  fetchedCountries: true,
  pendingCountries: false,

  fetchedSchools: false,
  pendingSchools: false,

  fetchedLanguages: false,
  pendingLanguages: false,

  fetchedBsbList: false,
  pendingBsbList: false
})

export const getters = {
  getBSB: (state) => (bsb) => {
    if (state.pendingBsbList || !state.fetchedBsbList || (Array.isArray(state.bsbList) && state.bsbList.length === 0)) return null

    return state.bsbList.find(el => String(el.bsb).trim().replace('-', '') === String(bsb).trim().replace('-', ''))
  },
  getBSBBranch: (state, getters) => (bsb) => {
    bsb = getters.getBSB(bsb)
    if (bsb) return bsb.financialInstitution + ' ' + bsb.branch
  },
}

export const mutations = {
  setCountries(state, data) {
    state.countries = state.countries.concat(data)
    state.fetchedCountries = true
  },
  setSchools(state, data) {
    state.schools = data
    state.fetchedSchools = true
  },
  setLanguages(state, data) {
    state.languages = data
    state.fetchedLanguages = true
  },
  setBsbList(state, data) {
    state.bsbList = data
    state.fetchedBsbList = true
  },
  setPending(state, { key, pending }) {
    state[key] = pending
  },
}

export const actions = {
  fetchCountries({ state, commit }) {
    if (!state.fetchedCountries && !state.pendingCountries) {
      commit('setPending', { key: 'pendingCountries', pending: true })
      this.$axios
        .get(`/api/v1/countries`)
        .then((res) => {
          if (res) {
            let names = []
            const skipNames = ['Australia', 'New Zealand', 'United States of America', 'United States Minor Outlying Islands', 'United Kingdom of Great Britain and Northern Ireland']
            if (res.data) {
              res.data.forEach((name) => {
                // Skip the names to prepend (add them after)
                if (!skipNames.includes(name)) {
                  names.push(name)
                }
              })
            }
            commit('setCountries', names)
          }
        })
        .finally(() => {
          commit('setPending', { key: 'pendingCountries', pending: false })
        })
    }
  },
  fetchSchools({ state, commit }) {
    if (!state.fetchedSchools && !state.pendingSchools) {
      commit('setPending', { key: 'pendingSchools', pending: true })
      this.$axios
        .get(`/api/v1/schools`)
        .then((res) => {
          commit('setSchools', res.data)
        })
        .finally(() => {
          commit('setPending', { key: 'pendingSchools', pending: false })
        })
    }
  },
  fetchLanguages({ state, commit }) {
    if (!state.fetchedLanguages && !state.pendingLanguages) {
      commit('setPending', { key: 'pendingLanguages', pending: true })
      this.$axios
        .get(`/api/v1/languages`)
        .then((res) => {
          // Add English to the top
          let languages = [{name:'English', code:'en'}]
          if (res.data) languages = languages.concat(res.data.filter((el) => el !== 'English'))
          commit('setLanguages', languages.map(el => el.name))
        })
        .finally(() => {
          commit('setPending', { key: 'pendingLanguages', pending: false })
        })
    }
  },
  fetchBsbList({ state, commit }) {
    if (state.fetchedBsbList || state.pendingBsbList) return
    commit('setPending', { key: 'pendingBsbList', pending: true })

    this.$axios
      .get(`/api/v1/bsb`)
      .then((res) => {
        commit('setBsbList', res.data)
      })
      .finally(() => {
        commit('setPending', { key: 'pendingBsbList', pending: false })
      })
  }
}
