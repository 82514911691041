export const state = () => ({
  CLIENT_ID: '368240386184-bb7kmglh50b7t7ls3lqqqsf2nqsocj1d.apps.googleusercontent.com',
  API_KEY: 'AIzaSyAp3abpI0RbaBQCcjg-Y5g0tPWt8PtMPT8',
  DISCOVERY_DOC: 'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest',
  SCOPES: 'https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/userinfo.email profile',
  // SCOPES: 'https://www.googleapis.com/auth/gmail.addons.current.action.compose https://www.googleapis.com/auth/gmail.addons.current.message.action https://www.googleapis.com/auth/userinfo.email profile',

  drafts: [],

  tokenClient: null,
  inited: false,
})

export const getters = {
  getTokenClient(state) {
    return _.cloneDeep(state.tokenClient)
  },
  getDrafts(state) {
    return state.drafts
  },
}

export const mutations = {
  setTokenClient(state, tokenClient) {
    state.tokenClient = tokenClient
    state.inited = true
  },
}

export const actions = {
  async init({ state, commit }) {
    if (state.inited) return // Already setup client
    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: state.CLIENT_ID,
      scope: state.SCOPES,
      callback: '', // defined later
      error_callback: '', // defined later
    })
    commit('setTokenClient', tokenClient)
  },
  async signIn({ getters, commit }, { signInCallback, errorCallback }) {
    let tokenClient = getters[`getTokenClient`]
    if (!tokenClient) return
    // Set callback for after login
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        console.error(resp.error)
        return
      }
      // Save token in backend
      const linkedEmail = await this.$axios.$post(`/api/v1/google/auth`, resp).then((res) => {
        // Add integration email to store
        if (res) {
          commit('integrations/integrationEmails/addLinkedEmail', res, { root: true })
          return res
        }
      })

      if (typeof signInCallback === 'function') {
        await signInCallback(linkedEmail?._id)
      }
    }

    if (typeof errorCallback === 'function') {
      tokenClient.error_callback = errorCallback
    }

    tokenClient.requestAccessToken({ prompt: 'consent' })
  },
  signOut({}) {
    this.$axios.$get(`/api/v1/google/auth`).then((token) => {
      if (token !== null) {
        google.accounts.oauth2.revoke(token)
        this.$axios.post(`/api/v1/google/auth/clear`)
      }
    })
  },
  async removeLinkedEmail({ dispatch, commit }, email) {
    this.$axios.delete(`/api/v1/integrations/emails/delete/gmail/${email}`)
    const isEmailLoggedIn = await dispatch(`isEmailLoggedIn`, email)
    if (isEmailLoggedIn) dispatch('signOut')
    commit('integrations/integrationEmails/removeLinkedEmail', { email: email, type: 'GMAIL' }, { root: true })
  },
  async createDraft({ dispatch }, { to, cc, bcc, subject, htmlContent }) {
    const profile = await dispatch('getProfile')
    if (!profile) return
    const signature = profile?.signature
    const str = [`To: ${to}`, `From: ${profile?.emailAddress}`, `Cc: ${cc ?? ''}`, `Bcc: ${bcc ?? ''}`, `Subject: ${subject}`, `Content-Type: text/html; charset=utf-8`, ``, `${htmlContent} ${signature ? signature : ''}`].join('\n')
    const utf8Bytes = new TextEncoder().encode(str)
    let base64String = btoa(new Uint8Array(utf8Bytes).reduce((data, byte) => data + String.fromCharCode(byte), ''))
    let draft = {
      message: {
        raw: base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''),
      },
    }
    return (await this.$axios.post(`/api/v1/google/gmail/drafts`, draft))?.data
  },
  async createDraftEMLString({ dispatch }, emlString) {
    const utf8Bytes = new TextEncoder().encode(emlString)
    let base64String = btoa(new Uint8Array(utf8Bytes).reduce((data, byte) => data + String.fromCharCode(byte), ''))
    let draft = {
      message: {
        raw: base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''),
      },
    }

    return await this.$axios.$post(`/api/v1/google/gmail/drafts/emlString`, draft)
  },
  async sendEmailEMLString({ dispatch }, emlString) {
    const utf8Bytes = new TextEncoder().encode(emlString)
    let base64String = btoa(new Uint8Array(utf8Bytes).reduce((data, byte) => data + String.fromCharCode(byte), ''))
    let draft = {
      raw: base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''),
    }

    return await this.$axios.$post(`/api/v1/google/gmail/messages/send`, draft)
  },
  async listDrafts({ state, commit }) {
    const res = await this.$axios.$get(`/api/v1/google/gmail/drafts`)
    if (Array.isArray(res?.drafts)) {
      commit('application/storeUtils/setItem', { localState: state, item: 'drafts', value: res.drafts }, { root: true })
    }
  },
  async sendDraft({ state }, draftId) {
    const found = state.drafts.find((el) => el.id === draftId)
    await this.$axios.post(`/api/v1/google/gmail/drafts/send`, found)
  },
  async getProfile({}) {
    return await this.$axios.$get(`/api/v1/google/gmail/profile`)
  },
  promptLogin({ dispatch }, webstompMsg) {
    // Prompt to sign in if webstomp message is received (token timed out in backend or does not exist)
    dispatch('signIn')
  },
  async isEmailLoggedIn({}, email) {
    return await this.$axios.$get(`/api/v1/google/auth/isLoggedIn/${encodeURIComponent(email)}`)
  },
}
