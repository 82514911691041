
import downloadMixin from '@/mixins/download'

export default {
  mixins: [downloadMixin],
  data() {
    return {
      menu: false,
      priorityMenu: false,
    }
  },
  methods: {
    clearAllNotifications() {
      this.$store.dispatch(`application/notifications/removeAllNotifications`)
      this.menu = false
    },
    openNotification(notification) {
      if (notification?.path) {
        // Open URL in new tab
        if (!notification?.read) this.$store.dispatch(`application/notifications/markAsRead`, notification.id)
        const currentModule = this.$route.name.split('-')[0]
        // If already on tasks open it via an event
        if (notification.path.startsWith('/tasks?id') && currentModule == 'tasks') {
          const urlParams = new URLSearchParams(notification.path.split('?')[1]) // Get the part after '?'
          const pathId = urlParams.get('id') // Extract the value of 'id' parameter
          this.$bus.$emit('taskManagement/openTask', pathId)
        } else {
          this.$router.push({ path: notification.path })
        }
      } else if (notification?.documentId) {
        // Download file
        this.$store.dispatch(`application/notifications/markAsRead`, notification.id)
        this.downloadAttachment(notification.documentId) // Document ID from the document library
      }
    },
    removeNotification(notification) {
      this.$store.dispatch(`application/notifications/removeNotification`, notification.id)
    },
    showPriority() {
      this.priorityMenu = true
    },
  },
  computed: {
    notifications() {
      let notifications = this.$store.getters[`application/notifications/getNotifications`]
      if (Array.isArray(notifications)) {
        if (notifications.find((el) => el.priority && !el.read)) this.showPriority()
        return notifications
      }
      return []
    },
    numUnreadNotifications() {
      if (this.muted) return 0
      if (Array.isArray(this.notifications)) {
        return this.notifications.filter((el) => !el.read).length
      }
      return 0
    },
    muted() {
      return _.get(this.$store.getters['modules/configurationHub/getCurrentUserSettings'], 'muteNotifications', false)
    },
  },
  watch: {
    menu(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        // On close
        this.$store.dispatch(`application/notifications/markAsRead`)
      } else if (newVal === true && oldVal === false) {
        // On open
        this.priorityMenu = false
      }
    },
  },
  mounted() {
    if (this.notifications.find((el) => el.priority && !el.read)) this.showPriority()

    this.$bus.$on('ApplicationNotificationMenu', () => (this.menu = true))
  },
}
