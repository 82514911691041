import CrudStore from './common/crudStore'

const crud = new CrudStore('clientPortal', null, true, false)

export const state = () => ({
  ...crud.state,
  users: [],
})

export const getters = {
  ...crud.getters,
  getPortalUsers(state) {
    return state.users
  },
}

export const mutations = {
  ...crud.mutations,
}
export const actions = {
  ...crud.actions,
  async getPortalUsers({ state, commit }, groupId) {
    if (!groupId) return
    return await this.$axios.get(`/api/v1/clientPortal/group/${groupId}`).then((res) => {
      if (res.data) {
        commit('application/storeUtils/setItem', { localState: state, item: 'users', value: res.data }, { root: true })
      }
      return res.data
    })
  },
  async createNewUser({ commit }, user) {
    await this.$axios.post('/api/v1/clientPortal', user).catch((err) => {
      commit('application/snack/set', { type: 'error', message: err }, { root: true })
    })
  },
  async updateUser({ commit }, user) {
    await this.$axios.put(`/api/v1/clientPortal/${user?.id}`, user).catch((err) => {
      commit('application/snack/set', { type: 'error', message: err }, { root: true })
    })
  },
  async removeUser({ commit }, userId) {
    await this.$axios.delete(`/api/v1/clientPortal/${userId}`).catch((err) => {
      commit('application/snack/set', { type: 'error', message: err }, { root: true })
    })
  },
}
