
import storeMixin from '@/mixins/storeMixin'
export default {
  mixins: [storeMixin],
  methods: {
    openMenu() {
      if (this.disableNav) {
        this.$bus.$emit('global/navigationBlocked')
        return
      }
      this.$store.commit('application/appData/setToggleContextMenu', true)
    },
  },
  computed: {
    canOpenMenu() {
      // Not in edit mode and user is logged in with subscription
      return this.$auth.loggedIn && this.$store.getters[`auxiliary/user/hasActiveSubscription`]
    },
    contextMenuOpen() {
      return this.$store.state.application.appData.showContextMenu
    },
    menuOpen: {
      get: function () {
        return this.$store.state.application.appData.showContextMenu
      },
      set: function (isOpen) {
        this.$store.commit('application/appData/setMenuOverlay', isOpen)
      },
    },
    disableNav() {
      if (!this.canOpenMenu) return true
      const isEdit = _.get(this.$store.state, `modules.${this.selectedModule}.editMode`, false)
      const autosave = _.get(this.$store.getters['modules/configurationHub/getCurrentUserSettings'], 'autoSave', false)
      if (autosave) return false
      return isEdit
    },
  },
}
