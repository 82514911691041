import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('entityGroups', null, true)

export const state = () => ({
  ...crud.state,
  selectedEntityGroup: null,
  createDialog: { show: false }, // Object with { show (bool), openTab (bool) }
  blankAddressManagerEntry: undefined,
})

export const getters = {
  ...crud.getters,
  getItems(state, getters, rootState) {
    let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
    return _.sortBy(items, (el) => $nuxt.$utils.getDisplayName(el?.id)?.toLowerCase()) // Sort the items by default
  },
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    const defaultName = 'Unnamed'
    if (!id) return defaultName
    const group = typeof id === 'object' ? id : getters['getAllItems'].find((el) => el.id === id) // If not found in cache, try find it
    if (!group) return defaultName
    return group?.summary?.name ? group.summary.name : defaultName
  },
  getGroupName: (state) => (id, noValue = '--') => {
    let foundGroup = state.allItems.find((group) => group.id === id)
    return foundGroup ? foundGroup?.summary?.name : noValue
  },
  getSelectedEntityGroup(state) {
    return state.selectedEntityGroup
  },
  getSelectedGroupLabel(state, getters) {
    if (!state.selectedEntityGroup) return null
    return getters['getLabel'](state.selectedEntityGroup.id)
  },
  // **************************** ADDRESS STUFF
  getAddresses(state, getters) {
    return _.get(state.selectedEntityGroup, 'addressManager.addresses', [])
      .filter((a) => a.address)
      .map((addressEntry) => _.get(addressEntry, 'address', null))
  },
  getAllAddresses(state, getters) {
    const allGroups = getters['getItems']
    if (!Array.isArray(allGroups)) return []
    return allGroups
      .flatMap((el) => _.get(el, 'addressManager.addresses', []))
      .map((el) => _.get(el, 'address'))
      .filter((el) => !_.isNil(el))
  },
  getAddressesFromGroup: (state, getters) => (groupId) => {
    const group = getters['getObject'](groupId)
    return _.get(group, 'addressManager.addresses', [])
      .filter((a) => a.address)
      .map((addressEntry) => _.get(addressEntry, 'address', null))
  },
  getAddressById: (state, getters) => (id) => {
    const allAddresses = getters['getAllAddresses']
    return _.find(allAddresses, ['placeId', id])
  },
  getAddressEntryByAddressId: (state, getters) => (placeId) => {
    return _.get(state.selectedEntityGroup, 'addressManager.addresses', []).find((addressEntry) => {
      return _.get(addressEntry, 'address.placeId', '') === placeId
    })
  },
  getAddressEntriesByInstanceId: (state, getters) => (instanceId) => {
    return _.get(state.selectedEntityGroup, 'addressManager.addresses', []).filter((addressEntry) => {
      if (addressEntry?.status === 'INACTIVE') return false
      return _.findIndex(addressEntry.instances, ['id', instanceId]) !== -1
    })
  },
  /**
   * Used for asset labels
   */
  getFirstAddressNameByInstanceId: (state, getters) => (instanceId) => {
    const entry = _.get(getters.getAddressEntriesByInstanceId(instanceId), '0', { address: {} })
    return $nuxt.$utils.formatPlace(entry.address)
  },
  /**
   * Returns address types available to the object, based on the type/subtype
   */
  getAddressTypes: () => (object) => {
    const idPrefix = _.get(object, 'id', '').split('-')[0]
    let result = []

    switch (idPrefix) {
      case 'entities':
        // get subtype via item.id
        if (object.type === 'INDIVIDUAL') result = ['RESIDENTIAL', 'POSTAL', 'OTHER']
        else if (object.type === 'COMPANY') result = ['BUSINESS', 'POSTAL', 'REGISTERED']
        else if (object.type === 'PARTNERSHIP' || object.type === 'SMSF' || object.type === 'TRUST') result = ['BUSINESS', 'POSTAL', 'MEETING']
        break
      case 'assets':
        result = ['MAIN_RESIDENCE', 'INVESTMENT', 'LAND']
        break
      default:
        result = ['RESIDENTIAL', 'POSTAL', 'BUSINESS', 'MEETING', 'MAIN_RESIDENCE', 'OTHER']
        break
    }
    return result.map((type) => ({ value: type, name: $nuxt.$utils.capitaliseWords(type) }))
  },
}

export const mutations = {
  ...crud.mutations,
  addAddressToCache(state, object) {
    state.newAddressesCache.push(object)
  },
  updateObject(state, object) {
    const index = state.allItems.findIndex((el) => el.id === object?.id)
    if (index < 0) return
    state.allItems.splice(index, 1, object) // Replace the object
    if (state?.selectedEntityGroup?.id === object.id) state.selectedEntityGroup = object // Update selected group
  },
  toggleCreateDialog(state, dialogObj) {
    state.createDialog = dialogObj
  },
  selectEntityGroup(state, group) {
    if (typeof group === 'string') {
      const found = state.allItems.find((el) => el.id === group)
      if (!found) return
      state.selectedEntityGroup = found
      return
    }

    //Check its not already this one
    if (state.selectedEntityGroup === group) return

    // Deselect object when changing group
    this.commit('application/storeUtils/select', { localState: state, data: null }, { root: true })
    state.selectedEntityGroup = group

    // Reset sessionStorage return paths whenever the group is changed
    // Maybe don't reset org wide return paths coz group doesn't matter on them?
    let returnKeys = Object.keys(sessionStorage)?.filter((el) => el?.includes('_return_path'))
    if (Array.isArray(returnKeys)) {
      returnKeys.forEach((key) => {
        sessionStorage.removeItem(key)
      })
    }

    sessionStorage.setItem('selectedEntityGroup', _.get(group, 'id', 'null'))

    if (group) this.$bus.$emit('selectEntityGroup', group)
    this.commit('auxiliary/documents/switchEntityGroup', null, { root: true })
    this.commit('auxiliary/documents/setRootPrefix', _.get(group, 'id', ''), { root: true })
  },
}

export const actions = {
  ...crud.actions,
  //Mostly used for updating the status of an address
  async updateAddress({ getters, dispatch, state }, { payload }) {
    const groupInfo = _.cloneDeep(_.get(state, 'selectedEntityGroup', {}))
    let addressIdx = _.findIndex(groupInfo.addressManager.addresses, { id: payload.id })

    if (!addressIdx !== -1) {
      groupInfo.addressManager.addresses.splice(addressIdx, 1, payload)
      dispatch('update', { payload: groupInfo })
    }
  },
  async fetchBlankAddressManagerEntry({ state, commit, dispatch }, forceFetch = false) {
    if (state.blankAddressManagerEntry === undefined || forceFetch) {
      commit('application/storeUtils/setItem', { localState: state, item: 'blankAddressManagerEntry', value: null }, { root: true }) // Set null so it doesn't re-fetch from multiple calls
      const blankAddressManagerEntry = await dispatch('application/storeUtils/getBackendClass', 'AddressManagerEntry', { root: true })
      commit('application/storeUtils/setItem', { localState: state, item: 'blankAddressManagerEntry', value: blankAddressManagerEntry }, { root: true })
    }
  },
  /**
   * `payload` is an array of objects - `instanceId`, `types[]` array and an `address` object
   *  -If the address exists attempt to add the instanceId to the address manager.
   *  -If the address doesn't exist add a new address manager entry
   */
  async updateAddressManager({ getters, dispatch, state }, { payload, instanceId, removed }) {
    if (!Array.isArray(payload)) payload = [payload]

    await dispatch('fetchBlankAddressManagerEntry', state.blankAddressManagerEntry === null ? true : false) // Force fetch if fetching was in progress because we need to wait on it
    let groupInfo = _.cloneDeep(_.get(state, 'selectedEntityGroup', {}))

    if (removed && !Array.isArray(removed)) removed = [removed] // Convert single object to array

    if (removed.length) {
      // Remove instanceId from addresses
      const addressIds = removed.map((addressEntry) => _.get(addressEntry, 'address.placeId', ''))
      addressIds.forEach((addressId) => {
        const addressEntry = getters.getAddressEntryByAddressId(addressId)
        if (!addressEntry) return

        const indexOfEntry = _.findIndex(groupInfo.addressManager.addresses, ['id', addressEntry.id])
        if (indexOfEntry === -1) return

        // Update instances
        const newInstances = addressEntry.instances.filter((instance) => instance.id !== instanceId)
        _.set(groupInfo, `addressManager.addresses.${indexOfEntry}.instances`, newInstances)
      })
    }

    payload.forEach(async (data) => {
      let type, address, favourite
      if ('placeId' in data) {
        // single address
        address = data
        type = []
      } else {
        // multi address
        type = data.type
        favourite = data.favourite
        address = data.address
      }
      if (!address) return
      // empty address given, return
      if (Object.values(address).filter((el) => !!el)?.length === 0) return

      // Find the Address Manager table item for the given payload address
      const existing = _.cloneDeep(getters.getAddressEntryByAddressId(address.placeId))

      if (existing) {
        // Existing address - update instances
        const indexOfExistingAddress = _.findIndex(groupInfo.addressManager.addresses, existing)
        const indexOfInstanceId = _.findIndex(existing.instances, ['id', instanceId])

        if (indexOfInstanceId === -1) {
          // Add new instance
          existing.instances.push({ id: instanceId, types: type || [], favourite: favourite })
        } else {
          // Update existing instance's types
          _.set(existing, `instances.${indexOfInstanceId}.types`, type || [])
          _.set(existing, `instances.${indexOfInstanceId}.favourite`, favourite)
        }

        // Update the address object if necessary
        if (!_.isEqual(existing.address, address)) {
          // Address object was editted
          existing.address = address
        }

        // After modifying the existing address entry, set it in groupInformation
        _.set(groupInfo, `addressManager.addresses.${indexOfExistingAddress}`, existing)
      } else {
        // Create new address
        groupInfo.addressManager.addresses.push({ ..._.cloneDeep(state.blankAddressManagerEntry), status: 'ACTIVE', address, id: this.$uuid(), instances: [{ id: instanceId, types: type || [], favourite: favourite }] })
      }
    })

    dispatch('update', { payload: groupInfo })
  },
  // Remove the instance for all the given address ids
  async removeInstanceFromAddressEntry({ getters, dispatch, state }, { instanceId, addressIds }) {
    const groupInfo = _.cloneDeep(_.get(state, 'selectedEntityGroup', {}))

    addressIds.forEach((addressId) => {
      const addressEntry = getters.getAddressEntryByAddressId(addressId)
      if (!addressEntry) return

      const indexOfEntry = _.findIndex(groupInfo.addressManager.addresses, ['id', addressEntry.id])
      if (indexOfEntry === -1) return

      _.set(
        groupInfo,
        `addressManager.addresses.${indexOfEntry}.instances`,
        addressEntry.instances.filter((instance) => instance.id !== instanceId)
      )
    })

    dispatch('update', { payload: groupInfo })
  },

  patchStatus({ state, commit }, { status }) {
    if (!status || !state.selectedEntityGroup?.id) return
    this.$axios.patch(`/api/v1/${state.endpoint}/status/${state.selectedEntityGroup.id}`, null, {
      params: {
        status: status,
      },
    })
    commit('updateField', { id: state.selectedEntityGroup.id, field: 'status', value: status })
  },
  async delete({ dispatch }, id) {
    await this.$axios.delete(`/api/v1/entityGroups/${id}`)
    this.$bus.$emit('removeRecentSearch', id)
    await dispatch('getAll', true)
    dispatch('selectEntityGroup', { id: null })
  },
  async createEntityGroup({ commit, dispatch }, name) {
    let groupData = await this.$axios.$post('/api/v1/entityGroups/create/' + name)
    await dispatch('fetchSinglePageData', groupData.id)
    commit('addObject', groupData)
    commit('selectEntityGroup', groupData)
    return groupData.id
  },
  selectEntityGroup({ commit, dispatch, state, rootGetters }, { id, resetRoute }) {
    if (id) {
      let group = state.allItems.find((group) => group.id === id)
      if (group && resetRoute) this.$router.push({ path: '/' })
      commit('selectEntityGroup', group)
    } else {
      const splitPath = this.$router.currentRoute.fullPath.split('/')
      // Navigate back from /id if it was group specific data and the group was deselected
      if (this.$router.currentRoute?.params?.id && splitPath.length > 0 && !rootGetters[`modules/${splitPath[1]}/isOrganisationWide`]) this.$router.push({ path: `/${splitPath[1]}` })
      commit('selectEntityGroup', null)
    }
  },
  fetchSinglePageData({ getters, dispatch, rootGetters }, groupId) {
    const exclude = ['entityGroups', 'fileManager', 'clientPortal']
    const singlePageStores = rootGetters['application/appData/getSinglePageStores'].filter((el) => !exclude.includes(el))
    singlePageStores.forEach((store) => {
      dispatch(`modules/${store}/getById`, groupId, { root: true })
    })
  },
}
