export const state = () => ({
  allItems: [],
  cancelTokenSourceRange: null,
})

export const getters = {
  getItems(state) {
    return state.allItems
  },
}

export const mutations = {
  updateCancelToken(state, token) {
    state.cancelTokenSourceRange = token
  },
}

export const actions = {
  async getAll({ state, commit, rootGetters, getters }) {
    return 'DEPRECATED'
    // const result = await this.$axios.$get(`/api/v1/eventLog`)
    // commit('application/storeUtils/setItem', { localState: state, item: 'allItems', value: result }, { root: true })
  },
  async getRecent({ state }, { groupId, collection }) {
    return await this.$axios.$get(`/api/v1/eventLog/recent`, {
      params: {
        groupId: groupId,
        collection: collection,
      },
    })
  },
  async getAllObjectChanges({ dispatch }, objectId) {
    const changes = await this.$axios.$get(`/api/v1/eventLog/diff/${objectId}`)
    if (Array.isArray(changes?.lookupIds)) {
      dispatch('dynamicLookup', changes.lookupIds)
    }
    return changes?.differences
  },
  async dynamicLookup({ dispatch }, lookupIds) {
    if (typeof lookupIds === 'string') lookupIds = [lookupIds]
    if (!Array.isArray(lookupIds)) return

    let promises = []
    const groupedStores = _.groupBy(lookupIds, this.$utils.storeFromId)
    Object.entries(groupedStores).forEach(([store, ids]) => {
      if (store) {
        ids.forEach((id) => {
          promises.push(dispatch(`modules/${store}/getById`, id, { root: true }))
        })
      }
    })
    return await Promise.allSettled(promises)
  },
  async findRange({ state, commit }, body) {
    // Cancel the previous request if it's still pending
    if (state.cancelTokenSourceRange) {
      state.cancelTokenSourceRange.cancel('Request canceled due to a new request.')
    }

    // Create a new cancel token
    commit('updateCancelToken', this.$axios.CancelToken.source())
    try {
      // Pass the cancel token in the Axios request
      return await this.$axios.$post('/api/v1/eventLog/findRange', body, {
        cancelToken: state.cancelTokenSourceRange.token,
      })
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        console.log('Request canceled:', error.message)
      } else {
        throw error // Rethrow other errors
      }
    }
  },
  async moduleEventCount({}) {
    return await this.$axios.$get('/api/v1/eventLog/collections/count')
  },
  async dayEventsCount({}) {
    return await this.$axios.$get('/api/v1/eventLog/days/count')
  },
  async weekEventsCount({}, { collections, groupId }) {
    return await this.$axios.$post(`/api/v1/eventLog/collections/week/count`, { collections, groupId })
  },
}
