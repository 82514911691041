export default async function ({ app, route, store, $auth, redirect }) {
  if ($auth.loggedIn && !store.getters[`auxiliary/user/hasActiveSubscription`] && route.path !== '/configurationHub/organisation/billingAndUsers') {
    // Redirect each time route changes
    redirect('/configurationHub/organisation/billingAndUsers')
  }

  app.router.beforeEach((to, from, next) => {
    // Prevent route changes if no subscription
    if ($auth.loggedIn && !store.getters[`auxiliary/user/hasActiveSubscription`]) {
      if (route.path !== '/configurationHub/organisation/billingAndUsers') redirect('/configurationHub/organisation/billingAndUsers')
      return false
    }
    next()
  })
}
