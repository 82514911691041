export const state = () => ({
  items: [],
})

export const getters = {
  getItems(state) {
    return state.items
  },
  getUserItems: (state) => (userId) => {
    return state.items.filter((el) => el.userId === userId)
  },
  getUserGmail: (state, getters) => (userId) => {
    return getters['getUserItems'](userId).filter((el) => el._id.type === 'GMAIL')
  },
  getUserOutlook: (state, getters) => (userId) => {
    return getters['getUserItems'](userId).filter((el) => el._id.type === 'OUTLOOK')
  },
}

export const mutations = {
  addLinkedEmail(state, emailObj) {
    if (!state.items.find((el) => _.isEqual(el._id, emailObj?._id))) {
      state.items.push(emailObj)
    }
  },
  removeLinkedEmail(state, id) {
    const index = state.items.findIndex((el) => _.isEqual(el._id, id))
    if (index >= 0) state.items.splice(index, 1)
  },
}

export const actions = {
  async fetchAll({ state, commit }) {
    const emails = await this.$axios.$get(`/api/v1/integrations/emails/all`)
    if (Array.isArray(emails)) commit('application/storeUtils/setItem', { localState: state, item: 'items', value: emails }, { root: true })
  },
  eventUpdate({ dispatch, rootState }, stompMessage) {
    // Re-pull config data on change
    if (stompMessage !== null) {
      let eventName = _.get(stompMessage, 'eventName', '').toLowerCase()
      // Message wasn't from the same tab (different transactionId)
      if (stompMessage.transactionId !== rootState.application.webStomp.transactionId) {
        if (eventName.includes('add')) {
          setTimeout(() => {
            dispatch('fetchAll')
          }, 2000)
        }
      }
    }
  },
}
