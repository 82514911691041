import CrudStore from './crudStore'

//Slight modification to abort any current ones if its called before a previous one hasn't finished
function newAbortSignal(commit, state, timeoutMs) {
  if (state.abortController) {
    state.abortController.abort()
  }
  commit('updateAbortController', new AbortController())
  setTimeout(() => state.abortController?.abort(), timeoutMs || 0)

  return state.abortController.signal
}

const defaultPageData = {
  content: [],
  empty: null,
  first: null,
  last: null,
  number: -1,
  numberOfElements: -1,
  pageable: {},
  size: -1,
  sort: { sorted: null, unsorted: null, empty: null },
  totalElements: -1,
  totalPages: -1,
}

export default class extends CrudStore {
  constructor(endpoint, typeSelections, singlePage, organisation) {
    super(endpoint, typeSelections, singlePage, organisation)

    /**
     * @type {import('../../../types/store').FilterState & import('../../../types/store').CrudState}
     */
    this.state = {
      ...this.state,
      segmentedTableData: [],
      tableDisplayIds: new Set(), // IDs for objects to be displayed in the table
      pageData: defaultPageData,
      paginationLoading: false,
      abortController: null,
      latestFilter: null, // Latest filter JSON, used to check if new objects match the filter criteria and can be shown or not
    }

    /**
     * @type { import('../../../types/store').Getters<import('../../../types/store').FilterState & import('../../../types/store').CrudState> }
     */
    this.getters = {
      ...this.getters,
      getSegmentedTableItems(state) {
        return state.segmentedTableData
      },
      getTableItems(state) {
        let tableItems = state.allItems.filter((el) => state.tableDisplayIds.has(el?.id) && (!el.deleted || el.deleted === 'ARCHIVED') && !el.isTemplate)
        if (!Array.isArray(tableItems)) return []
        if (_.isNil(state.typeSelections)) return tableItems
        return tableItems.toSorted((a, b) => {
          const typeA = state.typeSelections.find((type) => type.value === a.type)
          const typeB = state.typeSelections.find((type) => type.value === b.type)

          const indexA = state.typeSelections.indexOf(typeA)
          const indexB = state.typeSelections.indexOf(typeB)

          // Compare the indices to determine the sorting order
          return indexA - indexB
        })
      },
    }

    /**
     * @type { import('../../../types/store').Mutations<import('../../../types/store').FilterState & import('../../../types/store').CrudState> }
     */
    this.mutations = {
      ...this.mutations,
      mergeSegmentedTableData(state, { data, freshSearch }) {
        if (freshSearch) {
          state.segmentedTableData = []
        }
        data.forEach(({ dateRange, statuses }) => {
          let dateRangeIndex = _.findIndex(state.segmentedTableData, { dateRange })
          if (dateRangeIndex != -1) {
            statuses.forEach(({ status, tasks }) => {
              let statusIndex = _.findIndex(state.segmentedTableData[dateRangeIndex].statuses, { status })

              if (statusIndex != -1) {
                state.segmentedTableData[dateRangeIndex].statuses[statusIndex].tasks.push(...tasks)
              } else if (state.segmentedTableData[dateRangeIndex].statuses.length != 0) {
                state.segmentedTableData[dateRangeIndex].statuses.push({ status, tasks })
              } else {
                state.segmentedTableData[dateRangeIndex].statuses = [{ status, tasks }]
              }
            })
          } else if (state.segmentedTableData.length != 0) {
            state.segmentedTableData.push({ dateRange, statuses })
          } else {
            state.segmentedTableData = [{ dateRange, statuses }]
          }
        })
      },
      setPageData(state, pageData) {
        state.pageData = pageData
      },
      setPaginationLoading(state, loading) {
        state.paginationLoading = loading
      },
      updateAbortController(state, controller) {
        state.abortController = controller
      },
      resetPageData(state) {
        state.pageData = _.cloneDeep(defaultPageData)
      },
      addDisplayIds(state, ids) {
        if (!Array.isArray(ids)) ids = [ids]
        ids = ids.filter((el) => el) // Remove null/undefined
        ids.forEach((id) => state.tableDisplayIds.add(id))
      },
      removeDisplayIds(state, ids) {
        if (!Array.isArray(ids)) ids = [ids]
        ids = ids.filter((el) => el) // Remove null/undefined
        ids.forEach((id) => state.tableDisplayIds.delete(id))
      },
      setLatestFilter(state, filterJson) {
        state.latestFilter = filterJson
      },
      updateObjectInCurrentContent(state, item) {
        // Find and update the task while ensuring reactivity
        state.pageData.content.forEach((dateGroup) => {
          dateGroup.statuses.forEach((statusGroup) => {
            const taskIndex = statusGroup.tasks.findIndex((task) => task.id === item.id)
            if (taskIndex !== -1) {
              statusGroup.tasks.splice(taskIndex, 1, { ...statusGroup.tasks[taskIndex], ...item })
            }
          })
        })
      },
    }

    /**
     * @type { import('../../../types/store').Actions<import('../../../types/store').FilterState & import('../../../types/store').CrudState> }
     */
    this.actions = {
      ...this.actions,
      // Fetch by ID with filters applied to see if it's an object that should be shown or not
      async getOneFiltered({ state, commit }, id) {
        const obj = await this.$axios.$post(`/api/v1/${state.endpoint}/filter/${id}`, state.latestFilter)
        commit(`addDisplayIds`, obj.id)
        return !!obj
      },
      async getAll({ state, commit, getters }, payload) {
        if (payload === undefined) {
          // If its undefined assume its just trying to do a normal get all for hyperlink labels or something
          try {
            commit('application/loading/setLoading', true, { root: true })
            await this.$axios
              .$get(`/api/v1/${state.endpoint}`)
              .then((result) => {
                commit('mergeItems', result)
                commit('application/storeUtils/setItem', { localState: state, item: 'tableDisplayIds', value: new Set(result.map((el) => el.id)) }, { root: true })
                commit('application/storeUtils/setItem', { localState: state, item: 'staleData', value: false }, { root: true })
              })
              .catch((err) => {
                throw err
              })
              .finally(() => {
                commit('application/loading/setLoading', false, { root: true })
              })
          } catch (e) {
            console.warn(state.endpoint + ' invalid getAll usage')
          }
          return getters.getAllItems
        }
        const { searchJson = {}, freshSearch = false } = payload
        if (freshSearch) commit('resetPageData') // fresh query - ensure pageData uses defaults

        const body = _.assign({ page: freshSearch ? 0 : state.pageData.number + 1 }, searchJson)

        if (state.pageData.size !== -1) {
          body.size = state.pageData.size
        }
        commit('setPaginationLoading', true)
        const data = await this.$axios.$post(`/api/v1/${state.endpoint}/${state.endpoint == 'tasks' ? 'doubleStuff' : 'filter'}`, body, { signal: newAbortSignal(commit, state, 10000) }).catch(() => {})

        /* User canceled by loading a new filter while waiting for this one */
        commit('updateAbortController', null)
        commit('setPaginationLoading', false)
        if (data) {
          commit('setPageData', data)
          let mappedData = data.content
          if (state.endpoint == 'tasks') {
            commit('mergeSegmentedTableData', { data: data.content, freshSearch })
            // Flatten and extract tasks
            const flattenedTasks = _.reduce(
              data.content,
              (acc, el) => {
                const flatTasks = el.statuses.flatMap(({ tasks }) => tasks)
                return acc.concat(flatTasks)
              },
              []
            )

            mappedData = flattenedTasks.map((el) => el.id)
          } else{
            commit('mergeItems', data.content)
          }

          freshSearch ? commit('application/storeUtils/setItem', { localState: state, item: 'tableDisplayIds', value: new Set(data.content.map((el) => el.id)) }, { root: true }) : commit('addDisplayIds',  data.content.map((el) => el.id))

          if (state.staleData) {
            commit('application/storeUtils/setItem', { localState: state, item: 'staleData', value: false }, { root: true })
          }

          return data.content
        }
      },
    }
  }
}
